import React from 'react';
import { useHistory } from 'react-router-dom';
import Caroussel from 'components/Caroussel';
import InfoCard from 'components/InfoCard';
import Container from 'components/Container';
import withAuthToken from 'components/withAuthToken';

import { ReactComponent as OnboardingFirst } from 'assets/icons/onboarding-1.svg';
import { ReactComponent as OnboardingSecond } from 'assets/icons/onboarding-2.svg';
import { ReactComponent as OnboardingThird } from 'assets/icons/onboarding-3.svg';
import { CustomCard } from '../../components/Caroussel/Caroussel.styles';

const OnboardingCaroussel = () => {
  const history = useHistory();

  const slides = [
    {
      title: 'Transferências grátis e instantâneas',
      text:
        'Com o Pix você faz e recebe transferências 24 horas por dia, inclusive fins de semana e feriados. É mais uma alternativa para você fazer suas vendas de um jeito rápido e sem complicação.',
      icon: <OnboardingFirst />,
    },
    {
      title: 'É grátis pra quem faz e pra quem recebe',
      text:
        'Pix não tem custo. Você pode fazer quantas vezes quiser, quando quiser. Com toda a segurança que a SumUp e o Banco Central oferecem para sua tranquilidade.',
      icon: <OnboardingSecond />,
    },
    {
      title: 'Acesso fácil e rápido',
      text:
        'É sem complicação mesmo! Para pagar ou receber você só precisa usar as chaves Pix. Um jeito mais simples de identificar sua conta bancária. ',
      icon: <OnboardingThird />,
    },
  ];

  const buttonData = [
    {
      label: 'Próximo',
    },
    {
      label: 'Comece agora',
    },
  ];

  const skipAction = () => history.push('/keys-management');

  const mountCards = (items) =>
    items.map(({ icon, title, text }) => (
      <CustomCard
        icon={icon}
        key={title}
        title={title}
        text={text}
      />
    ));

  return (
    <Container data-testid="onboarding">
      <Caroussel
        slides={() => mountCards(slides)}
        slideComponent={InfoCard}
        buttonData={buttonData}
        skipAction={skipAction}
      />
    </Container>
  );
};

export default withAuthToken(OnboardingCaroussel);

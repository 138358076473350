import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import KeysSelectionList from 'containers/KeysSelectionList';
import { Container } from 'containers/Checkout';
import { appSteps } from 'modules/appSteps';
import { useAppStep } from 'hooks';
import NoKeys from '../../containers/KeysManagement/NoKeys/NoKeys';

const KeySelection = () => {
  useAppStep(appSteps.keySelection.step);

  const history = useHistory();

  const { addressKeys } = useSelector((state) => state.addressKey);
  const createdKeys = (addressKeys.list || []).filter(
    (addressKey) => addressKey.currentStatus === 'CREATED'
  );
  const hasCreatedKeys = createdKeys.length > 0;

  const onSelectedKeyHandle = (key) => {
    history.push('/checkout/new', { checkoutKey: key });
  };
  const onClickHandler = () => {
    history.push('/keys-management');
  };

  return (
    <Container>
      {hasCreatedKeys ? (
        <KeysSelectionList
          keysToShow={createdKeys}
          onClick={onSelectedKeyHandle}
        />
      ) : (
        <NoKeys onNewKeyClick={onClickHandler} />
      )}
    </Container>
  );
};

export default KeySelection;

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Button } from '@sumup/circuit-ui';
import InfoCard from '../InfoCard';

export const Container = styled('div')(
  () => css`
    background-color: #fff;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  `
);

export const CustomCard = styled(InfoCard)(
  () => css`
    padding-bottom: 300px;
  `
);

export const StepsControl = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 0 ${theme.spacings.giga};
  `
);

export const Step = styled('div')(
  ({ theme, active }) => css`
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 50%;
    margin: 0 ${theme.spacings.byte} 0 0;
    background-color: ${active ? theme.colors.p500 : theme.colors.p300};
  `
);

export const StyledButton = styled(Button)(
  () => css`
    width: 100%;
  `
);

export const SkipButton = styled(Button)(
  () => css`
    text-align: right;
    display: inline-block;
    padding: 0;
    width: 100%;

    &:focus {
      outline: 0;
      box-shadow: none;
      background-color: transparent;
    }
  `
);

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  appSetStep,
  clearClaimResponse } from 'actions';
import { appSteps } from 'modules/appSteps';
import OwnershipScreen from 'containers/Ownership';

const Ownership = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appSetStep({
      currentStep: appSteps.ownership.step,
      beforeGoBack: () => {
        dispatch(clearClaimResponse());
      },
    }));
  }, [dispatch]);

  return <OwnershipScreen />;
};

export default Ownership;
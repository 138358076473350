import React from 'react';
import PropTypes from 'prop-types';
import QRCodeReact from 'qrcode.react';

const QRCode = ({ value }) => (
  <QRCodeReact
    data-testid="qrCode"
    value={value}
    size={208}
    level="L"
    includeMargin={false}
    renderAs="svg"
  />
);


QRCode.propTypes = {
  value: PropTypes.string.isRequired,
};

export default QRCode;

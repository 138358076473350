import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Text, LoadingButton, Input } from '@sumup/circuit-ui';

export const KeyValidationWrapper = styled('div')(({ theme }) => css`
    padding: ${theme.spacings.mega};
    display: flex;
    height: 85vh;
    flex-direction: column;
  `);

export const StyledText = styled(Text)(({ theme }) => css`
    margin: ${theme.spacings.mega} 0;

    strong {
      font-weight: bold
    }
  `);

export const LoadingButtonStyled = styled(LoadingButton)(({ theme }) => css`
    margin-top: ${theme.spacings.mega} 0;
  `);

export const SideBySideButton = styled('div')(({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `);

export const DeleteButtonStyled = styled(LoadingButton)(({ theme }) => css`
    width: 50%;
    color: ${theme.colors.r500};
    margin-right: ${theme.spacings.mega};
  `);

export const StyledInput = styled(Input)(({ theme }) => css`
  box-shadow: none;
  padding: 0;
  font-size: 24px;
  
  &:hover, &:focus {
    outline: 0;
    box-shadow: none;
  }
`);

import format from 'string-format';
import { select } from 'redux-saga/effects';

import config from 'config';

import { keyMirror } from './helpers';
import { request } from './client';

export const resources = {
  addressGet: {
    method: 'GET',
    endpoint: '?query={zipcode}',
  }
};

export const endpoints = keyMirror(resources);

/**
 * Add required parameters
 * @param {string} endpoint
 * @returns {string}
 */
export function getURLWithParams(endpoint) {
  const join = endpoint.indexOf('?') >= 0 ? '&' : '?';

  return `${config.apiPlaces}/${endpoint}${join}region=br&language=pt-BR`;
}

export function* getRequestOptions() {
  const { authToken, sessionToken } = yield select((state) => state.app);

  return { authToken, sessionToken };
}

export async function client({ authToken, headers, method, payload, type }) {
  let finalHeaders = {
    'Content-Type': 'application/json',
    ...headers,
  };

  if (typeof authToken === 'string' && authToken.length > 0) {
    finalHeaders = {
      Authorization: `Bearer ${authToken}`,
      ...finalHeaders,
    };
  }

  if (config.isSumUpIo) {
    finalHeaders = {
      'X-App-Origin': 'SUMUP_IO',
      ...finalHeaders,
    };
  }

  return request({
    url: `${config.apiBFC}`,
    headers: finalHeaders,
    method,
    payload,
    type: type || 'JSON',
  });
}

export async function clientRequest(endpoint, { headers, method, payload }) {
  const resource = resources[endpoint];

  return request({
    url: resource.endpoint,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    method,
    payload,
    type: 'JSON',
  });
}

export async function clientRest(endpoint,
  { authToken, headers, method, payload, type, ...rest }) {
  const resource = resources[endpoint];

  return request({
    url: format(getURLWithParams(resource.endpoint), { ...rest }),
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    method: resource.method,
    payload,
    type: type || 'JSON',
  });
}

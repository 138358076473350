import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import KeyDetailScreen from 'containers/KeyDetail';
import { useDispatch, useSelector } from 'react-redux';
import { useBottomBar, useTreeChanges } from 'hooks';
import { Button } from '@sumup/circuit-ui';
import { BottomBar, DeleteButton } from 'containers/Layout';
import CoverScreen from 'containers/CoverScreen';
import {
  appSetStep,
  clearAddressKeyResponse,
  clearAddressKeyValues,
  addressKeyDelete,
  setClaimKey,
  listAddressKey,
  toggleConfirmModal,
} from 'actions';
import { appSteps } from 'modules/appSteps';
import { CREATION_STATUSES, STATUS } from 'constants';
import { createKeyResponseHandling } from 'modules/content';
import withAuthToken from 'components/withAuthToken';

import { contentHandler } from 'content/handlers';
import Modals from 'containers/Modals';

const KeyDetail = () => {
  const history = useHistory();
  const {
    addressKeys: { detailedKey },
    delete: deleteAction,
  } = useSelector((state) => state.addressKey);
  const [isLoading, setLoading] = useState(false);
  const [coverScreenContent, setCoverScreenContent] = useState();
  const [coverState, setCoverState] = useState(false);
  const { changedFrom: deleteStatusChangedFrom } = useTreeChanges(
    deleteAction.status
  );

  const [bottombarRef] = useBottomBar();
  const dispatch = useDispatch();

  const { ConfirmModal } = Modals;
  const confirmationModalText = 'Tem certeza que você quer excluir esta chave Pix?';

  useEffect(() => {
    dispatch(
      appSetStep({
        currentStep: appSteps.keyDetail.step,
        beforeGoBack: () => {
          dispatch(clearAddressKeyResponse());
          dispatch(clearAddressKeyValues());
        },
      })
    );
  }, [dispatch]);

  const canDelete = (status) => {
    return [
      CREATION_STATUSES.created,
      CREATION_STATUSES.initiated,
    ].indexOf(status) !== -1
  };

  const handleDelete = () => {
    const { key } = detailedKey;
    dispatch(toggleConfirmModal(false));
    dispatch(addressKeyDelete({ key }));
    setLoading(true);
  };

  const openDeleteKeyConfirmationModal = useCallback(() => {
    dispatch(toggleConfirmModal(true));
  }, [dispatch]);

  useEffect(() => {
    if (
      deleteStatusChangedFrom('delete.status', STATUS.RUNNING, STATUS.SUCCESS)
    ) {
      setLoading(false);
      const deleteSuccessContent = createKeyResponseHandling(
        'addressKeyDeleteSuccess'
      )(detailedKey);
      setCoverState(true);
      setCoverScreenContent(deleteSuccessContent);
      dispatch(listAddressKey());
    }
  }, [deleteStatusChangedFrom, detailedKey, dispatch]);

  useEffect(() => {
    if (
      deleteStatusChangedFrom('delete.status', STATUS.RUNNING, STATUS.ERROR)
    ) {
      setLoading(false);
      const deleteFailedContent = createKeyResponseHandling(
        'addressKeyDeleteFailed'
      )(detailedKey);
      setCoverState(true);
      setCoverScreenContent(deleteFailedContent);
    }
  }, [deleteStatusChangedFrom, detailedKey]);
  const onButtonClick = (destination) => {
    dispatch(setClaimKey(detailedKey));
    history.push(destination);
  };

  const { actions } = contentHandler(detailedKey);

  return (
    <>
      <CoverScreen
        content={coverScreenContent}
        active={coverState}
        modalBehavior={true}
        onClose={() => setCoverState(false)}
      />
      <KeyDetailScreen detailedKey={detailedKey} />
      {!canDelete(detailedKey.currentStatus) ? (
        <BottomBar ref={bottombarRef}>
          {actions.map((item) => (
            <Button
              onClick={() => onButtonClick(item.to)}
              key={item.label}
              stretch={true}
              variant={item.type}>
              {item.label}
            </Button>
          ))}
        </BottomBar>
      ) : (
        <BottomBar ref={bottombarRef}>
          <DeleteButton
            isLoading={isLoading}
            stretch={true}
            variant="secondary"
            onClick={openDeleteKeyConfirmationModal}
          >
            Excluir chave
          </DeleteButton>
        </BottomBar>
      )}
      <ConfirmModal
        handleConfirm={handleDelete}
        text={confirmationModalText}
      />
    </>
  );
};

export default withAuthToken(KeyDetail);

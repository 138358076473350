/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { listAddressKey, listClaim, setFinalKeyList } from 'actions';
import { splitAddressKeys } from './withKeysService';
import Loading from 'containers/Loading';
import GenericError from 'containers/GenericError';
import { STATUS } from 'constants';

const withKeys = (Component) => {
  const KeysHOC = (props) => {
    const { children } = props;
    const dispatch = useDispatch();
    const { addressKeys } = useSelector((state) => state.addressKey);
    const { claimList } = useSelector((state) => state.claim);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
      dispatch(listAddressKey());
      dispatch(listClaim());
    }, [dispatch]);

    useEffect(() => {
      const finalList = splitAddressKeys([
        ...addressKeys.list,
        ...claimList.payload,
      ]);

      dispatch(setFinalKeyList(finalList));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressKeys.list, claimList.payload]);

  useEffect(() => {
    if (addressKeys.status === STATUS.SUCCESS) {
      setLoading(false);
    }
    if (addressKeys.status === STATUS.ERROR) {
      setHasError(true);
    }
  }, [addressKeys]);

    return <> {hasError 
      ? <GenericError /> 
      : <>{isLoading 
        ? <Loading /> 
        : <Component {...props}>{children}</Component>}</>
      } </>;
  };

  return KeysHOC;
};

export default withKeys;

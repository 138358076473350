/**
 * @module Actions/Account
 * @desc Actions for Account
 */
import { createActions } from 'redux-actions';
import { AccountConstants } from 'constants';

export const {
  accountAddressSuggestionRequest: accountAddressSuggestionGet,
  accountCreateRequest: accountCreate,
  accountViewerRequest: accountViewerGet,
} = createActions({
  [AccountConstants.ACCOUNT_ADDRESS_SUGGESTION_REQUEST]: () => ({}),
  [AccountConstants.ACCOUNT_CREATE_REQUEST]: (input) => ({ input }),
  [AccountConstants.ACCOUNT_VIEWER_REQUEST]: () => ({}),
});

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Text, Button } from '@sumup/circuit-ui';
import { ChevronRight, Clock } from '@sumup/icons';
import { ReactComponent as CopyPasteIcon } from 'assets/icons/copy-paste-black.svg';

export const Wrapper = styled('div')(({ theme }) => css`
    padding: ${theme.spacings.mega};
    border-bottom: 1px solid ${theme.colors.n200};
    position: relative;
    background-color: white;
  `);

export const Value = styled('div')(({ theme }) => css`
    color: ${theme.colors.n800};
    font-size: 16px;
  `);

export const SelectableValue = styled('div')(({ theme }) => css`
    color: ${theme.colors.n800};
    font-size: 16px;
    -webkit-user-select: auto;
  `);

export const Label = styled('div')(({ theme }) => css`
    color: ${theme.colors.n700};
    font-size: 14px;
  `);

export const StatusIcon = styled(Clock)(({ theme }) => css`
    color: ${theme.colors.warning};
    width: 20px;
  `);

export const Title = styled(Text)(({ theme }) => css`
    background-color: ${theme.colors.n200};
    padding: ${theme.spacings.byte} ${theme.spacings.mega};
    text-transform: uppercase;
    font-weight: 700;
    color: ${theme.colors.n700};
    margin-bottom: 0;
  `);

export const DetailIcon = styled(ChevronRight)(({ theme }) => css`
    color: ${theme.colors.n700};
    position: absolute;
    right: ${theme.spacings.mega};
    top: 40%;
  `);

export const CopyButton = styled(Button)(({ theme }) => css`
    position: absolute;
    right: ${theme.spacings.mega};
    top: 25%;
    padding: 8px;
  `);

export const CopyPasteIconStyled = styled(CopyPasteIcon)(({ theme }) => css`
    color: ${theme.colors.n700};
    `);

export const InnerRow = styled('div')(({ theme, spacingTop }) => css`
    width: calc(100% - ${theme.spacings.giga});
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: ${theme.spacings[spacingTop] || 0};
  `);

export const StatusLabel = styled(Text)(({ theme, type }) => css`
    color: ${theme.colors[type]};
    margin-bottom: 0;
    text-transform: inherit;
    font-weight: 100;
  `);

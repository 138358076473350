import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  appSetStep,
  clearAddressKeyResponse,
  clearAddressKeyValues,
  checkoutKeyManagementFlowPop } from 'actions';
import { appSteps } from 'modules/appSteps';
import KeyValidation from 'containers/KeyValidation';

const KeyValidationScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appSetStep({
      currentStep: appSteps.keyValidation.step,
      beforeGoBack: () => {
        dispatch(clearAddressKeyResponse());
        dispatch(clearAddressKeyValues());
        dispatch(checkoutKeyManagementFlowPop());
        return { backTo: '/keys-management' };
      },
    }));
  }, [dispatch]);

  return <KeyValidation />;
};

export default KeyValidationScreen;

import React, { useEffect } from 'react';
import InfoCard, { Text, ImageWrapperSmall } from 'components/InfoCard';
import { PixScreen, BottomBar } from 'containers/Layout';
import Button from 'components/Button';
import { appSteps } from 'modules/appSteps';
import { useDispatch } from 'react-redux';
import { appSetStep } from 'actions';
import { useBottomBar } from 'hooks';
import withAuthToken from 'components/withAuthToken';

import { useLocation } from 'react-router-dom';
import IllustrationSource from 'assets/images/ana-checkmark@2x.png';

const BankAccountSuccess = () => {
  const {
    state: { redirectTo },
  } = useLocation();
  const [bottombarRef, bottombarHeight] = useBottomBar();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(appSetStep({ currentStep: appSteps.bankAccountSuccess.step }));
  }, [dispatch]);

  const cardIcon = (
    <ImageWrapperSmall>
      <img src={IllustrationSource} alt="" />
    </ImageWrapperSmall>
  );
  const cardTitle = 'Sua conta foi aberta com sucesso!';
  const cardTextComponent = (
    <>
      <Text>
        Agora, além do Pix, você pode aproveitar tudo que o SumUp Bank tem para
        somar com a sua vida. Mais transparência, mais facilidade e as melhores
        taxas do mercado.
      </Text>
    </>
  );

  const buttonText =
    redirectTo === '/checkout/new'
      ? 'Seguir para a venda com QR Code'
      : 'Seguir para o cadastro de chave';

  return (
    <PixScreen style={{ paddingBottom: `${bottombarHeight}px` }}>
      <InfoCard
        iconComponent={cardIcon}
        title={cardTitle}
        textComponent={cardTextComponent}
        showCardBox={true}
      />
      <BottomBar ref={bottombarRef}>
        <Button text={buttonText} linkTo={redirectTo} />
      </BottomBar>
    </PixScreen>
  );
};

export default withAuthToken(BankAccountSuccess);

import React from 'react';
import { Heading } from '@sumup/circuit-ui';
import { HeaderBar } from './Header.styles';

const Header = () => (
  <>
    <HeaderBar>
      <Heading size="kilo" noMargin>
        Cadastrar nova chave Pix
      </Heading>
    </HeaderBar>
  </>
);

export default Header;

import React from 'react';
import PropTypes from 'prop-types';
import { Title } from './TableList.styles';

const TableTitle = ({ children, ...props }) => (
  <Title data-testid="tableTitle" {...props}>{children}</Title>
);

TableTitle.propTypes = {
  children: PropTypes.any,
};

export default TableTitle;

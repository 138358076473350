import { isExecutingInBankApp } from 'modules/dom';

export const appSteps = {
  loading: {
    step: 'loading',
    title: 'Loading',
    showBack: false,
    showHelp: false,
  },
  newKey: {
    step: 'newKey',
    title: 'Cadastrar nova chave Pix',
    showBack: true,
    showHelp: false,
  },
  selectKeyType: {
    step: 'selectKeyType',
    title: 'Cadastrar nova chave Pix',
    showBack: true,
    showHelp: false,
  },
  keyValidation: {
    step: 'keyValidation',
    title: 'Cadastrar nova chave',
    showBack: true,
    showClose: false,
    showHelp: false,
  },
  ownership: {
    step: 'ownership',
    title: '',
    showBack: true,
    showClose: false,
    showHelp: false,
  },
  sharedDataInfo: {
    step: 'sharedDataInfo',
    title: 'Cadastrar nova chave Pix',
    showBack: true,
    showHelp: false,
  },
  bankAccount: {
    step: 'bankAccount',
    title: 'Conta para recebimento de Pix',
    showBack: true,
    showHelp: false,
  },
  bankAccountSuccess: {
    step: 'bankAccountSuccess',
    title: 'Conta para recebimento de Pix',
    showBack: true,
    showHelp: false,
  },
  keysManagement: {
    step: 'keysManagement',
    title: 'Minhas chaves Pix',
    showBack: isExecutingInBankApp,
    showHelp: false,
  },
  keyDetail: {
    step: 'keyDetail',
    title: 'Detalhes da chave',
    showBack: true,
    showHelp: false,
  },
  registration: {
    step: 'registration',
    title: 'Pix - Pagamentos instantâneos',
    showBack: false,
    showHelp: false,
  },
  portability: {
    step: 'portability',
    title: 'Gerenciar minhas chaves',
    showBack: true,
    showHelp: false,
  },
  faq: {
    step: 'faq',
    title: 'Sobre o Pix',
    showBack: true,
    showHelp: false,
  },
  cover: {
    step: 'cover',
    showBack: false,
    showHelp: false,
  },
  qrcodePayment: {
    step: 'qrcodePayment',
    title: 'Receber Pix',
    showBack: false,
    showClose: true,
    showHelp: false,
  },
  newCheckout: {
    step: 'newCheckout',
    title: 'Receber Pix',
    showBack: true,
    showClose: false,
    showHelp: false,
  },
  keySelection: {
    step: 'keySelection',
    title: 'Receber Pix',
    showBack: false,
    showClose: false,
    showHelp: false,
  },
  processing: {
    step: 'processing',
    title: '',
    showBack: false,
    showClose: true,
    showHelp: false,
  }
};

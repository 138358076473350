import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { appSetStep } from 'actions';

import { isEmpty } from 'modules/helpers';
import { appSteps } from 'modules/appSteps';

import Button from 'components/Button';

import {
  BottomBar,
  Card,
  Heading,
  ImageWrapper,
  List,
  ListContent,
  ListHeading,
  ListIcon,
  ListItem,
  ListText,
  Screen,
  Text,
} from 'containers/Layout';
import ScrollToTop from 'containers/ScrollToTop';

import IllustrationSource from 'assets/images/app-illustration@2x.png';
import { ReactComponent as HeartIcon } from 'assets/icons/icon-heart.svg';
import { ReactComponent as SmartphoneIcon } from 'assets/icons/icon-smartphone.svg';
import { ReactComponent as TransactionIcon } from 'assets/icons/icon-transaction.svg';
import { ReactComponent as WithdrawIcon } from 'assets/icons/icon-withdraw.svg';

const Welcome = () => {
  const [bottombarHeight, setBottombarHeight] = useState(0);
  const bottombarRef = useRef(null);

  const { data } = useSelector((state) => state.account);
  const personalProfile = data.merchantData?.personalProfile || {};
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appSetStep({
      currentStep: appSteps.welcome.step, 
    }));
  }, [dispatch]);

  useEffect(() => {
    const currentHeight = bottombarRef?.current?.offsetHeight || 0;

    if (currentHeight > 0) {
      setBottombarHeight(currentHeight + 16);
    }
  }, [bottombarHeight]);

  if (isEmpty(data))
    return <Redirect to="/" />;

  return (
    <Screen style={{ paddingBottom: `${bottombarHeight}px` }}>
      <ScrollToTop />

      <Card>
        <ImageWrapper>
          <img src={IllustrationSource} alt="" />
        </ImageWrapper>

        <Heading as="h2" size="peta">
          Chegou o SumUp Bank!
        </Heading>

        <Text>
          {`${personalProfile.firstName}, `}
          agora você pode ter uma conta digital que soma com você. No SumUp Bank você tem:
        </Text>

        <List>
          <ListItem>
            <ListIcon>
              <SmartphoneIcon />
            </ListIcon>

            <ListContent>
              <ListHeading>Aplicativo exclusivo</ListHeading>

              <ListText>
                Para acompanhar saldo, extrato, recebimento de vendas, pagamento de contas e transferências.
              </ListText>
            </ListContent>
          </ListItem>

          <ListItem>
            <ListIcon>
              <TransactionIcon />
            </ListIcon>

            <ListContent>
              <ListHeading>As melhores taxas do mercado</ListHeading>

              <ListText>
                Transferências da sua conta para terceiros por até R$1,50.
              </ListText>
            </ListContent>
          </ListItem>

          <ListItem>
            <ListIcon>
              <WithdrawIcon />
            </ListIcon>

            <ListContent>
              <ListHeading>Saque na rede Banco24Horas</ListHeading>

              <ListText>
                Saques a qualquer hora do dia ou da noite utilizando seu cartão pré-pago por R$5,90.
              </ListText>
            </ListContent>
          </ListItem>

          <ListItem>
            <ListIcon>
              <HeartIcon />
            </ListIcon>

            <ListContent>
              <ListHeading>Atendimento especial</ListHeading>

              <ListText>
                Atendimento humanizado e pronto para tirar todas as suas dúvidas na hora que você quiser.
              </ListText>
            </ListContent>
          </ListItem>
        </List>
      </Card>

      <BottomBar ref={bottombarRef}>
        <Button text="Quero conhecer" linkTo="/info" />
      </BottomBar>
    </Screen>
  );
};

export default Welcome;

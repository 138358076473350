import React from 'react';
import PropTypes from 'prop-types';
import { Selector, Item, StyledChevron } from './ListSelector.styles';

const ListSelector = ({ items, onItemClick }) => (
  <Selector data-testid="listSelector">
    {items.map((item, index) => (
      <Item
        key={index}
        onClick={() => onItemClick(item)}
      >
        <span>{item.name}</span>
        <StyledChevron />
      </Item>
    ))}
  </Selector>
);

ListSelector.propTypes = {
  items: PropTypes.array.isRequired,
  onItemClick: PropTypes.func,
};

export default ListSelector;

import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, LoadingButton } from '@sumup/circuit-ui';

import { useModal } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { toggleConfirmModal } from 'actions';

import Dialog from 'containers/Dialog';

import { SideBySideButton } from './ConfirmModal.styles';

const ConfirmModal = ({ handleConfirm, text }) => {
  const [isModalActive, setModalActive] = useModal();
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    if (app.confirmModal.isOpen) {
      setModalActive(true);
    }
    else {
      setModalActive(false);
    }
  }, [app.confirmModal.isOpen, setModalActive]);

  const handleCloseAlert = useCallback(() => {
    dispatch(toggleConfirmModal(false));
    setModalActive(false);
  }, [dispatch, setModalActive]);

  return (
    <Dialog isModalActive={isModalActive} handleClose={handleCloseAlert}>
      {text}
      <SideBySideButton data-testid="confirm-modal">
        <Button onClick={handleCloseAlert} variant="tertiary">
          CANCELAR
        </Button>
        <LoadingButton onClick={handleConfirm} variant="tertiary">
          CONFIRMAR
        </LoadingButton>
      </SideBySideButton>
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export default ConfirmModal;

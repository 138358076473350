import React, { useEffect } from 'react';
import config from 'config';
import { Button } from '@sumup/circuit-ui';
import {
  NoKeys,
  KeysManagementScreen,
  KeyManagementContainer,
} from 'containers/KeysManagement';
import { BottomBar } from 'containers/Layout';
import { ReactComponent as PixSellIcon } from 'assets/icons/icon-pix-sell.svg';

import { NewKeyAnchor } from 'containers/KeysManagement/KeysManagement.styles';
import { useDispatch, useSelector } from 'react-redux';
import { useBottomBar, useTreeChanges } from 'hooks';
import { appSteps } from 'modules/appSteps';
import { listAddressKey, appSetStep } from 'actions';
import { useHistory } from 'react-router-dom';
import { STATUS } from 'constants';

const { isPixEnabled } = config;

const KeysManagement = () => {
  const [bottombarRef] = useBottomBar();

  const dispatch = useDispatch();
  const history = useHistory();

  const { addressKeys } = useSelector((state) => state.addressKey);
  const { claimList } = useSelector((state) => state.claim);

  const {
    viewer,
    profile,
    flags: { userIsEligible, userHasAccount },
  } = useSelector((state) => state.account);

  const { isPixEnabled: isPixEnabledParam } = useSelector((state) => state.app);

  const { changedFrom: viewerStatusChangedFrom } = useTreeChanges(viewer.status);

  const showPayment = isPixEnabled || isPixEnabledParam;

  const {
    step: { currentStep },
  } = useSelector((state) => state.app);

  const hasKeys = addressKeys?.list?.length || claimList?.payload?.length;

  useEffect(() => {
    if (
      viewerStatusChangedFrom(
        'viewer.status', STATUS.RUNNING, STATUS.SUCCESS
      )
    ) {

      if (userHasAccount || userIsEligible) {
        dispatch(listAddressKey());
      }
    }
  }, [
    viewerStatusChangedFrom,
    profile,
    userHasAccount,
    userIsEligible,
    dispatch
  ]);

  useEffect(() => {
    if (currentStep !== appSteps.keysManagement.step) {
      dispatch(appSetStep({
        currentStep: appSteps.keysManagement.step,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickHandler = (redirectTo, origin) => {
    const pathname = userHasAccount ? redirectTo : `/bank-account/${origin}`;
    history.replace({ pathname, state: { redirectTo } });
  };

  return (
    <>
      {hasKeys ? (
        <KeyManagementContainer>
          <KeysManagementScreen />
          {showPayment ? (
            <NewKeyAnchor
              onClick={() => onClickHandler('/new-key/type', 'manage')}
            >
              Cadastrar nova chave Pix
            </NewKeyAnchor>
          ) : (
            <></>
          )}
        </KeyManagementContainer>
      ) : (
        <NoKeys
          onNewKeyClick={() => onClickHandler('/new-key/type', 'manage')}
        />
      )}

        <BottomBar ref={bottombarRef}>
          {showPayment && hasKeys ? (
            <Button
              variant="secondary"
              stretch={true}
              icon={PixSellIcon}
              onClick={() => onClickHandler('/checkout', 'sell')}
            >
              Vender usando QR Code
            </Button>
          ) : (
            <Button
              stretch={true}
              variant="primary"
              onClick={() => onClickHandler('/new-key/type', 'manage')}
            >
              Cadastrar nova chave
            </Button>
          )}
        </BottomBar>
    </>
  );
};

export default KeysManagement;

import { all, fork } from 'redux-saga/effects';

import account from './account';
import addressKey from './addressKey';
import claim from './claim';
import checkout from './checkout';

export default function* root() {
  yield all([fork(account), fork(addressKey), fork(claim), fork(checkout)]);
}

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Bank } from '@sumup/icons';
import { LoadingButton, Spinner } from '@sumup/circuit-ui';

export const BottomBar = styled('div')(({ theme }) => css`
    bottom: 0;
    left: 0;
    padding: ${theme.spacings.mega} ${theme.spacings.mega};
    position: fixed;
    overflow: hidden;
    width: 100%;

    @media (max-height: 370px) {
        display: none;
    }

    > {

        div {
            margin-bottom: ${theme.spacings.mega};
        }

        button {
            margin-bottom: ${theme.spacings.mega};
        }

        &:last-child {
            margin-bottom: 0px;
        }
    }
`);

export const PixScreen = styled('div')(({ theme }) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 90vh;
  padding: ${theme.spacings.mega};
  position: relative;
  width: 100vw;
  align-items: center;
`);

export const SecondaryIconContainer = styled('div')(({ theme }) => css`
  border: 2px solid ${theme.colors.n200};
  border-radius: 40px;
  background-color: ${theme.colors.n200};
  height: 80px;
  width: 80px;
  padding: ${theme.spacings.mega};
  display: inline-block;
`);

export const BankIcon = styled(Bank)(({ theme }) => css`
  color: ${theme.colors.p500};
  height: 40px;
  width: 40px;
`);

export const ButtonWrapper = styled('div')(({ theme }) => css`
    padding: ${theme.spacings.kilo} 0 0;
    width: 100%;
  `);

export const Card = styled('div')(({ theme }) => css`
    background-color: white;
    border-radius: ${theme.borderRadius.giga};
    color: ${theme.colors.n900};
    padding: ${theme.spacings.mega};
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;
  `);

export const ContainerWrapper = styled('div')(({ theme }) => css`
    margin: 0 auto;
    padding: 0 ${theme.spacings.tera};
    width: 100%;
  `);

export const ContainerWrapperCentered = styled('div')(({ theme }) => css`
    text-align: center;
    width: 100%;

    p {
      margin: ${theme.spacings.mega}
    }
  `);

export const Heading = styled('h2')(({ theme }) => css`
    font-size: 19px;
    line-height: 1.4em;
    font-weight: bold;
    margin: ${theme.spacings.mega} 0;
  `);

export const ImageWrapper = styled('div')(({ theme }) => css`
    align-items: center;
    display: flex;
    height: 225px;
    justify-content: center;
    margin: 0 auto ${theme.spacings.mega};
    width: 225px;

    & img,
    & svg {
      height: 100%;
    }
  `);

export const ImageWrapperSmall = styled('div')(({ theme }) => css`
    align-items: center;
    display: flex;
    height: 150px;
    justify-content: center;
    margin: 0 auto ${theme.spacings.mega};
    width: 150px;

    & img,
    & svg {
      height: 100%;
    }
  `);

export const Info = styled('p')(({ theme }) => css`
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4em;
    margin: ${theme.spacings.kilo} 0;

    & strong {
      font-weight: bold;
    }

    & a {
      color: ${theme.colors.p500};
      text-decoration: none;
    }
  `);

export const List = styled('ul')(({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: ${theme.spacings.mega} 0 0;
    width: 100%;
  `);

export const ListContent = styled('div')`
  width: calc(100% - 52px);
`;

export const ListHeading = styled('h3')(({ theme }) => css`
    font-size: 14px;
    font-weight: bold;
    line-height: 1.4em;
    margin: 0 0 ${theme.spacings.bit};
  `);

export const ListText = styled('p')`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4em;
  margin: 0;
`;

export const ListIcon = styled('div')(({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.p100};
    border-radius: 10px;
    display: flex;
    flex: 0 1 40px;
    height: 40px;
    justify-content: center;
    margin: 0 ${theme.spacings.kilo} 0 0;
    padding: ${theme.spacings.byte};
    width: 100%;
  `);

export const ListItem = styled('li')(({ theme }) => css`
    display: flex;
    margin: 0 0 ${theme.spacings.giga};
    padding: 0;
    width: 100%;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  `);

export const LoadingSpinner = styled(Spinner)(({ theme }) => css`
  -webkit-animation: spin 1s ease-in-out infinite;
    animation: spin 500ms ease-in-out infinite;
    border-radius: 50%;
    border: ${theme.spacings.bit} solid ${theme.colors.p300};
    border-top-color: ${theme.colors.p500};
    display: inline-block;
    height: 64px;
    width: 64px;
    margin: 0 0 ${theme.spacings.giga};

    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
`);

export const DeleteButton = styled(LoadingButton)(({ theme }) => css`
  color: ${theme.colors.r500};
`);

export const LoadingScreen = styled('div')(({ theme }) => css`
    background-color: ${theme.colors.n100};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
    overflow: hidden;
    padding: ${theme.spacings.mega};
    position: relative;
    width: 100vw;

    div {
      min-height: 50vh;
      text-align: center;
      color: ${theme.colors.n900};

      p {
        padding: 0 ${theme.spacings.giga};
      }
    }

    img {
      margin-top: ${theme.spacings.exa};
      width: 220px;
    }

    svg {
      width: 30px;
      height: 30px;
    }
  `);

export const Section = styled('section')(({ theme }) => css`
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: ${theme.spacings.mega} 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
  `);

export const SectionHeading = styled('h3')(({ theme }) => css`
    font-size: 17px;
    line-height: 1.4em;
    font-weight: bold;
    margin: 0 0 ${theme.spacings.mega};
  `);

export const Screen = styled('div')(({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: ${theme.spacings.mega};
    position: relative;
    width: 100vw;
  `);

export const WaitingListScreen = styled('div')(({ theme, isLoading }) => css`
    display: flex;
    align-items: ${isLoading ? 'center' : 'start'};
    justify-content: ${isLoading ? 'center' : 'flex-start'};
    flex-direction: column;
    min-height: 100vh;
    padding: ${theme.spacings.mega};
    position: relative;
    width: 100vw;
  `);

export const Text = styled('p')(({ theme }) => css`
    font-size: 16px;
    font-weight: normal;
    line-height: 1.4em;
    margin: ${theme.spacings.giga} 0;

    & strong {
      font-weight: bold;
    }

    & a {
      color: ${theme.colors.p500};
      text-decoration: none;
    }
  `);

export const Label = styled('p')(({ theme }) => css`
    font-size: 14px;
    font-weight: bold;
    line-height: 1.4em;
    margin: ${theme.spacings.giga} 0 ${theme.spacings.byte};
  `);

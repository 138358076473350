import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Header, Button } from '@sumup/circuit-ui';

export const ButtonBack = styled(Button)(
  ({ theme }) => css`
    background: transparent;
    border: 0;
    padding: 0;
    width: ${theme.spacings.tera};
    height: ${theme.spacings.tera};

    & > span {
      height: 100%;
    }
    :hover {
      background: ${theme.colors.p500};

      svg {
        color: #fff;
      }
    }
  `
);

export const HeaderBar = styled(Header)(
  ({ theme }) => css`
    backface-visibility: hidden;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    display: inline-table;
    min-height: 50px;
    justify-content: space-around;
    overflow: hidden;
    width: 100%;
  `
);

import React from 'react';
import { light } from '@sumup/design-tokens';
import { BaseStyles } from '@sumup/circuit-ui';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'emotion-theming';

import { FlagsProvider } from 'flagged';
import Routes from 'routes';

import { store } from 'store';

import Modals from 'containers/Modals';
import Toast from 'containers/Toast';
import Topbar from 'containers/Topbar';

import { AppStyled } from './App.styles';

const { AppWrapper } = AppStyled;
const { SuccessModal, ErrorModal } = Modals;

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={light}>
      <FlagsProvider features={{ isCreatedKeysEnable: false }}>
        <BaseStyles />
        <AppWrapper>
          <Router>
            <Topbar />
            <Routes />
          </Router>

          <SuccessModal />
          <ErrorModal />
          <Toast />
        </AppWrapper>
      </FlagsProvider>
    </ThemeProvider>
  </Provider>
);

export default App;

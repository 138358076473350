import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { appSteps } from 'modules/appSteps';
import { executeNativeBack } from 'modules/dom';
import { history } from 'modules/history';

import { TopbarStyled } from './Topbar.styles';

const {
  BackIcon,
  XIcon,
  Container,
  Navbar,
  PreviousHeader,
  HelpIcon,
} = TopbarStyled;

const Topbar = () => {
  const { step, isProcessing } = useSelector((state) => state.app);
  const routerHistory = useHistory();

  let closeWindow = () => {};

  const goBack = () => {
    if (step.beforeGoBack) {
      const e = step.beforeGoBack();
      if (e && e.backTo) {
        routerHistory.push(e.backTo);
        return;
      }
    }

    history.goBack();
  };

  const goBackFromFaq = () => {
    if (routerHistory.location.pathname === '/faq') {
      executeNativeBack();
      return closeWindow();
    } else {
      return goBack();
    }
  }

  const handleClickBack = () => {
    switch (step.currentStep) {
      case null:
      case appSteps.keySelection.step:
      case appSteps.keysManagement.step:
      case appSteps.loading.step:
        executeNativeBack();
        return closeWindow();

      case appSteps.faq.step:
        return goBackFromFaq();

      default:
        return goBack();
    }
  };

  // On merchant-android webview
  if (typeof window.Android !== 'undefined') {
    window.onBack = handleClickBack;
    closeWindow = () => window.Android.closeWindow();
  }

  if (!step || step.currentStep === appSteps.loading.step) {
    return null;
  }

  const { showBack, showClose, title, showHelp, topComponent } = appSteps[
    step.currentStep
  ];
  const showCloseIcon = !isProcessing && !showBack && showClose;
  const showBackIcon = !isProcessing && showBack;

  return (
    <Navbar data-testid="topbar">
      <Container>
        {topComponent || (
          <>
            {showBackIcon && <BackIcon onClick={handleClickBack} />}
            {showCloseIcon && <XIcon onClick={handleClickBack} />}

            <PreviousHeader>{title}</PreviousHeader>
            {showHelp && (
              <Link to="/faq">
                <HelpIcon />
              </Link>
            )}
          </>
        )}
      </Container>
    </Navbar>
  );
};

export default Topbar;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Accordion from 'containers/Faq';

import { appSetStep } from 'actions';

import { appSteps } from 'modules/appSteps';

const KeyDetail = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appSetStep({
      currentStep: appSteps.faq.step,
    }));
  }, [dispatch]);

  return (
    <>
      <Accordion />
    </>
  );
};

export default KeyDetail;

import React from 'react';
import { useHistory } from 'react-router-dom';
import Caroussel from 'components/Caroussel';
import InfoCard from 'components/InfoCard';
import Container from 'components/Container';
import withAuthToken from 'components/withAuthToken';

import { ReactComponent as OnboardingFirst } from 'assets/icons/onboarding-keys-1.svg';
import { ReactComponent as OnboardingSecond } from 'assets/icons/onboarding-keys-2-new.svg';
import { ReactComponent as OnboardingThird } from 'assets/icons/onboarding-keys-3.svg';
import { CustomCard } from '../../components/Caroussel/Caroussel.styles';

const OnboardingKeysCaroussel = () => {
  const history = useHistory();

  const slides = [
    {
      title: 'Chave Pix é a entrada para sua conta',
      text:
        'As chaves Pix podem ser seu CPF, número do telefone celular, email ou até um código aleatório. Cada chave Pix só pode ser vinculada a uma única conta.',
      icon: <OnboardingFirst />,
    },
    {
      title: 'Fáceis de escolher e de usar',
      text:
        'Cadastre até 5 chaves Pix como pessoa física para poder realizar pagamentos ou transferências. Com as chaves você também pode criar um QR code para receber suas vendas.',
      icon: <OnboardingSecond />,
    },
    {
      title: 'Simplicidade para sua vida financeira',
      text:
        'Agora você não precisa mais daquele monte de dados bancários para fazer transferências e pagamentos. Você só precisa das chaves Pix. No SumUp Bank você descomplica num Pix.',
      icon: <OnboardingThird />,
    },
  ];

  const buttonData = [
    {
      label: 'Próximo',
    },
    {
      label: 'Comece agora',
    },
  ];

  const skipAction = () => history.push('/new-key/type');

  const mountCards = (items) =>
    items.map(({ icon, title, text }) => (
      <CustomCard icon={icon} key={title} title={title} text={text} />
    ));

  return (
    <Container data-testid="onboarding-keys">
      <Caroussel
        slides={() => mountCards(slides)}
        slideComponent={InfoCard}
        buttonData={buttonData}
        skipAction={skipAction}
      />
    </Container>
  );
};

export default withAuthToken(OnboardingKeysCaroussel);

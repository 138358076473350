import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const StyledInput = styled('div')(({ theme }) => css`
    input {
      -webkit-appearance: none;
      background-color: #FFFFFF;
      border: none;
      outline: 0;
      border-radius: 8px;
      transition: box-shadow 120ms ease-in-out, padding 120ms ease-in-out;
      width: 100%;
      margin: 0;
      line-height: 24px;
      font-size: 22px;
    }
  `);

import React from 'react';
import Ink from 'react-ink';
import PropTypes from 'prop-types';

import { ButtonStyled } from './Button.styles';

const { PrimaryButton, SecondaryLink, PrimaryErrorButton, PrimaryLink, SecondaryButton } = ButtonStyled;

const Button = ({
  buttonType,
  hasError,
  isDisabled,
  linkTo,
  onClickHandler,
  text,
  ...rest
}) => {
  if (linkTo) {
    return buttonType === 'primary' ? (
      <PrimaryLink
        data-testid="button"
        to={linkTo}
        disabled={isDisabled}
        buttonType={buttonType}
      >
        {text}

        <Ink />
      </PrimaryLink>
    ) : (
      <SecondaryLink
        data-testid="button"
        to={linkTo}
        disabled={isDisabled}
        buttonType={buttonType}
      >
        {text}
        <Ink />
      </SecondaryLink>
    );
  }
  else if (hasError) {
    return (
      <PrimaryErrorButton
        data-testid="button"
        hasError={hasError}
        onClick={onClickHandler}
        role="button"
        type="button"
        buttonType={buttonType}
        {...rest}
      >
        {text}
        <Ink />
      </PrimaryErrorButton>
    );
  }

  return buttonType === 'primary' ? (
    <PrimaryButton
      data-testid="button"
      disabled={isDisabled}
      onClick={onClickHandler}
      role="button"
      type="button"
      {...rest}
    >
      {text}
      <Ink />
    </PrimaryButton>
  ) : (
    <SecondaryButton
      data-testid="button"
      disabled={isDisabled}
      onClick={onClickHandler}
      role="button"
      type="button"
      {...rest}
    >
      {text}
      <Ink />
    </SecondaryButton>
  );
};

Button.propTypes = {
  buttonType: PropTypes.string,
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  linkTo: PropTypes.string,
  onClickHandler: PropTypes.func,
  text: PropTypes.string.isRequired,
};

Button.defaultProps = {
  hasError: false,
  isDisabled: false,
  buttonType: 'primary',
  onClickHandler: () => { },
};

export default Button;

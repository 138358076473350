import React, { InputHTMLAttributes } from 'react';
import { StyledInput } from './MaskedMoney.styles';
import CurrencyInput from 'react-currency-input';

const numberFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
});

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  onChangeInput(value: any): void;
  currentValue?: string | number;
}

const MaskMoney: React.FC<InputProps> = ({
  currentValue,
  onChangeInput,
  ...rest
}: InputProps) => {
  const formatCurrencyByEnd = (value) => {
    if (!Number(value)) return '';

    const amount = numberFormatter.format(parseFloat(value) / 100);
    return `${amount}`;
  };

  const formatedCurrentValue =
    !currentValue || !Number(currentValue) ? '' : Number(currentValue) / 100;

  const onValueChange = (event, maskedvalue, floatvalue) => {
    const intValue = parseInt(Number(floatvalue * 100).toFixed(0), 10);
    onChangeInput(intValue);
  };

  return (
    <StyledInput>
      <CurrencyInput
        format={formatCurrencyByEnd}
        precision="2"
        decimalSeparator=","
        thousandSeparator="."
        prefix="R$"
        placeholder="R$ 0,00"
        value={formatedCurrentValue}
        onChangeEvent={onValueChange}
      />
    </StyledInput>
  );
};

export default MaskMoney;

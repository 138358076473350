import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

const ScrollToTop = (props) => {
  useEffect(() => {
    if (props.history.action === 'POP') {
      return;
    }

    const {hash} = props.location;

    if (hash) {
      const element = document.querySelector(hash);

      if (element) {
        element.scrollIntoView({block: 'start', behavior: 'smooth'});
      }
    }
    else {
      window.scrollTo(0, 0);
    }
  });

  return (
    <div data-testid="scroll-to-top" />
  );
};

ScrollToTop.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(ScrollToTop);

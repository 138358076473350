import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Button, Text, Input } from '@sumup/circuit-ui';

export const CardSpacing = styled('div')(
  ({ theme }) => css`
    padding: ${theme.spacings.mega} ${theme.spacings.mega} 0;
  `
);

export const StyledButton = styled(Button)(
  ({ theme, spacing }) => css`
    width: 100%;
  `
);

export const Title = styled(Text)(
  () => css`
    margin-bottom: 0;

    strong {
      font-weight: 700;
    }
  `
);

export const SubTitle = styled(Text)(
  () => css`
    font-size: 14px;
  `
);

export const StyledInput = styled(Input)(
  ({ theme }) => css`
    box-shadow: none;
    padding: 0;
    font-size: 24px;
    border: none;
    width: 100%;

    &:hover,
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  `
);

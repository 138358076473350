import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Content, Header, StyledCard, Title } from './Accordion.styles';

const Accordion = ({active, content }) => {
  const [contentActive, setContentActive] = useState(active);

  const handleClick = () => {
    setContentActive(!contentActive);
  };

  return (
    <StyledCard data-testid="accordion" key={content.id} onClick={handleClick}>
      <Header>
        <Icon active={contentActive} />
        <Title>{content.title}</Title>
      </Header>
      <Content active={contentActive}>{content.text}</Content>
    </StyledCard>
  );
};

Accordion.propTypes = {
  active: PropTypes.bool,
  content: PropTypes.object,
};

export default Accordion;

import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import Portal from 'components/Portal';

import { ModalStyled } from './Dialog.styles';

const {
  ModalContent,
  ModalOverlay,
  ModalWrapper,
} = ModalStyled;

const Dialog = ({
  children,
  handleClose,
  isModalActive,
}) => {
  const handleKeyUp = useCallback((e) => {
    /* istanbul ignore else */
    if (e.keyCode === 27) {
      handleClose();
    }
  },[handleClose]);

  useEffect(() => {
    if (isModalActive) {
      document.addEventListener('keyup', handleKeyUp);
    }

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleClose, handleKeyUp, isModalActive]);

  return (
    <React.Fragment>
      {isModalActive && (
        <Portal id="modal">
          <ModalWrapper
            data-testid="modal"
            role="dialog"
          >
            {children && (
              <ModalContent>
                {children}
              </ModalContent>
            )}
          </ModalWrapper>

          <ModalOverlay onClick={handleClose} />
        </Portal>
      )}
    </React.Fragment>
  );
};

Dialog.propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
  isModalActive: PropTypes.bool.isRequired,
};

Dialog.defaultProps = {
  children: '',
};

export default Dialog;

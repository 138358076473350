import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as KeyIcon } from 'assets/icons/icon-pictogram-key.svg';
import InfoCard from 'components/InfoCard';
import { Text } from '../KeysManagement.styles';
import { StyledInfoCard } from './NoKeys.styles';

const NoKeys = () => {
  const cardIcon = <KeyIcon fill="white" />;
  const cardTitle = 'Você ainda não cadastrou suas chaves Pix';
  return (
    <StyledInfoCard data-testid="no-keys">
      <InfoCard icon={cardIcon} title={cardTitle} showCardBox={true}>
        <Text>
          Com as chaves Pix você recebe pagamentos e transferências de um jeito muito mais simples.
        </Text>
        <Text>
          <strong>
            <Link to="/faq/4">O que é uma chave Pix?</Link>
          </strong>
        </Text>
      </InfoCard>
    </StyledInfoCard>
  );
};

export default NoKeys;

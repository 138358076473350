import { keyMirror } from 'modules/helpers';

export const AddressKeyConstants = keyMirror({
  ADDRESS_KEY_DETAIL_SHOW: undefined,
  KEY_TYPE: undefined,
  KEY_VALUE: undefined,
  SET_KEY_TYPE: undefined,
  SET_KEY_VALUE: undefined,
  SET_FINAL_KEY_LIST: undefined,
  ADDRESS_KEY_CREATE_REQUEST: undefined,
  ADDRESS_KEY_CREATE_SUCCESS: undefined,
  ADDRESS_KEY_CREATE_FAILURE: undefined,
  ADDRESS_KEY_LIST_REQUEST: undefined,
  ADDRESS_KEY_LIST_SUCCESS: undefined,
  ADDRESS_KEY_LIST_FAILURE: undefined,
  ADDRESS_KEY_PIN_VALIDATION_REQUEST: undefined,
  ADDRESS_KEY_PIN_VALIDATION_SUCCESS: undefined,
  ADDRESS_KEY_PIN_VALIDATION_FAILURE: undefined,
  CLEAR_ADDRESS_KEY_RESPONSE: undefined,
  CLEAR_ADDRESS_KEY_VALUES: undefined,
  SET_DETAIL_KEY: undefined,
  ADDRESS_KEY_DELETE_REQUEST: undefined,
  ADDRESS_KEY_DELETE_SUCCESS: undefined,
  ADDRESS_KEY_DELETE_FAILURE: undefined,
});

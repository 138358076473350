import React, { useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAuthToken } from 'actions';
import GenericError from 'containers/GenericError';

const withAuthToken = (Component) => {
  const AuthTokenHOC = (props) => {
    const { children } = props;
    const dispatch = useDispatch();
    const [hasError, setHasError] = useState(false);
    const { authToken } = useSelector((state) => state.app);
    
    const token = window.location.hash.substr(1);

    useEffect(() => {
      if (!authToken) {
        if (!token || /^\s*$/.test(token)) {
          setHasError(true);
        } else {
          dispatch(setAuthToken(token));
        }
      }
    }, [dispatch, authToken, token]);

    return <> {hasError 
      ? <GenericError /> 
      : <Component {...props}>{children}</Component>} </>;
  };

  return AuthTokenHOC;
};

export default withAuthToken;

/**
 * @module Actions/Checkout
 * @desc Actions for Checkout
 */
import { createActions } from 'redux-actions';
import { CheckoutConstants } from 'constants';

export const {
  checkoutGenerateQrRequest: checkoutGenerateQR,
  checkoutQrpaymentVerificationRequest: checkoutQRPaymentCheck,
  clearCheckoutGenerateQrResponse, 
  checkoutKeyManagementFlowPush,
  checkoutKeyManagementFlowPop,
} = createActions({
  [CheckoutConstants.CHECKOUT_GENERATE_QR_REQUEST]: (input) => ({ input }),
  [CheckoutConstants.CHECKOUT_QRPAYMENT_VERIFICATION_REQUEST]: (input) => ({ input }),
  [CheckoutConstants.CLEAR_CHECKOUT_GENERATE_QR_RESPONSE]: () => ({}),
  [CheckoutConstants.CHECKOUT_KEY_MANAGEMENT_FLOW_PUSH]: () => ({}),
  [CheckoutConstants.CHECKOUT_KEY_MANAGEMENT_FLOW_POP]: () => ({}),
});

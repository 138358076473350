import { Cross } from '@sumup/icons';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { CardHeader, Card } from '@sumup/circuit-ui';

export const Header = styled(CardHeader)(() => css`
    display: flex;
    flex-direction: row;
    margin: 0;
  `);

export const StyledCard = styled(Card)(({ theme }) => css`
    margin-bottom: ${theme.spacings.mega};
  `);

export const Icon = styled(Cross)(({ active }) => css`
    transform: ${active ? 'rotate(0deg)' : 'rotate(45deg)'};
  `);

export const Title = styled('div')(() => css`
    width: 90%;
    font-weight: 700;
  `);

export const Content = styled('div')(({ active, theme }) => css`
    display: ${active ? 'block' : 'none'};
    margin-top: ${theme.spacings.mega};

    strong {
      font-weight: 700;
    }
  `);

import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleSuccessModal } from 'actions';

import { useModal, useTreeChanges } from 'hooks';

import { appSteps } from 'modules/appSteps';

import Button from 'components/Button';

import Dialog from 'containers/Dialog';
import {
  ContainerWrapperCentered,
  Heading,
  ImageWrapperSmall,
  Text,
} from 'containers/Layout';

import IllustrationSource from 'assets/images/ana-checkmark@2x.png';

const SuccessModal = () => {
  const [isModalActive, setModalActive] = useModal();
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { changedFrom } = useTreeChanges(app);

  const handleCloseAlert = useCallback(() => {
    dispatch(toggleSuccessModal(false));
  }, [dispatch]);

  useEffect(() => {
    if (changedFrom(
      'step',
      appSteps.keysManagement.step,
      appSteps.registration.step
    )) {
      dispatch(toggleSuccessModal(true));
    }
  }, [changedFrom, dispatch, app.step]);

  useEffect(() => {
    if (app.successModal.isOpen) {
      setModalActive(true);
    }
    else {
      setModalActive(false);
    }
  }, [app.successModal.isOpen, setModalActive]);

  return (
    <Dialog
      isModalActive={isModalActive}
      handleClose={handleCloseAlert}
    >
      <ContainerWrapperCentered data-testid="success-modal">
        <ImageWrapperSmall>
          <img src={IllustrationSource} alt="" />
        </ImageWrapperSmall>

        <Heading as="h2" size="peta">
          Sua conta foi aberta com sucesso!
        </Heading>

        <Text>
          Agora você pode aproveitar tudo que o SumUp Bank tem para somar com a sua vida.
          Mais transparência, mais facilidade e as melhores taxas do mercado.
        </Text>

        <Button text="Finalizar" onClickHandler={handleCloseAlert} />
      </ContainerWrapperCentered>
    </Dialog>
  );
};

export default SuccessModal;

import { compose } from 'modules/helpers';
import withKeys from 'components/withKeys';
import withAuthToken from 'components/withAuthToken';
import withViewer from 'components/withViewer';
import CheckoutSuccess from './CheckoutSuccess';
import KeySelectionComponent from './KeySelection';
import NewCheckout from './NewCheckout';

const KeySelection = compose(
  withAuthToken,
  withViewer,
  withKeys
)(KeySelectionComponent);

export { NewCheckout, CheckoutSuccess, KeySelection };

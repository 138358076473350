/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import GenericError from 'containers/GenericError';
import Loading from 'containers/Loading';

import { STATUS } from 'constants';

import { accountViewerGet } from 'actions';

const withViewer = (Component) => {
  const ViewerHOC = (props) => {
    const dispatch = useDispatch();
    
    const { children } = props;
    const { authToken } = useSelector((state) => state.app);
    const { viewer } = useSelector((state) => state.account);
    
    const [hasError, setHasError] = useState(false);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
      if (authToken) {
        if (viewer.status === STATUS.IDLE) {
          dispatch(accountViewerGet());
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, authToken]);

    useEffect(() => {
      if (viewer.status === STATUS.SUCCESS) {
        setLoading(false);
      }
      if (viewer.status === STATUS.ERROR) {
        setHasError(true);
      }
    }, [viewer]);

    return <> {hasError 
      ? <GenericError /> 
      : <>{isLoading 
        ? <Loading /> 
        : <Component {...props}>{children}</Component>}</>
      } </>;
  };
  return ViewerHOC;
};

export default withViewer;

import React from 'react';
import { LoadingScreen, LoadingSpinner } from 'containers/Layout';
import pixLogo from 'assets/icons/onboarding-1.svg';

const Loading = () => (
  <LoadingScreen data-testid="loading">
    <img src={pixLogo} alt="Logo do SumUp Pix" />
    <div>
      <LoadingSpinner />
    </div>
  </LoadingScreen>
);

export default Loading;

import { handleActions } from 'redux-actions';
import produce from 'immer';

import { AddressKeyConstants, STATUS } from 'constants';

export const addressKeyState = {
  viewer: {
    addressKey: {},
    message: '',
    status: STATUS.IDLE,
  },
  delete: {
    message: '',
    status: STATUS.IDLE,
  },
  create: {
    message: '',
    status: STATUS.IDLE,
  },
  addressKeys: {
    list: [],
    finalList: {},
    message: '',
    status: STATUS.IDLE,
    detailedKey: {},
  },
  addressKey: {
    keyType: null,
    keyValue: null,
  },
  validation: {
    message: '',
    status: STATUS.IDLE,
  },
};

export default {
  addressKey: handleActions({
    // Account create
    [AddressKeyConstants.CLEAR_ADDRESS_KEY_RESPONSE]: (state) =>
      produce(state, (draft) => {
        draft.create = {
          message: '',
          status: STATUS.IDLE,
        };

        draft.payload = '';
        draft.validation = {
          message: '',
          status: STATUS.IDLE,
        };
        draft.detailedKey = {};
      }),
    [AddressKeyConstants.CLEAR_ADDRESS_KEY_VALUES]: (state) =>
      produce(state, (draft) => {
        draft.keyType = null;
        draft.keyValue = null;
      }),
    [AddressKeyConstants.ADDRESS_KEY_CREATE_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.create = {
          message: '',
          status: STATUS.RUNNING,
        };
      }),
    [AddressKeyConstants.ADDRESS_KEY_CREATE_FAILURE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.create = {
          message: payload.message,
          status: STATUS.ERROR,
        };
        draft.data = {};
      }),
    [AddressKeyConstants.ADDRESS_KEY_CREATE_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.create = {
          message: '',
          status: STATUS.SUCCESS,
        };

        draft.payload = payload;
      }),
    [AddressKeyConstants.ADDRESS_KEY_DELETE_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.delete = {
          message: '',
          status: STATUS.RUNNING,
        };
      }),
    [AddressKeyConstants.ADDRESS_KEY_DELETE_FAILURE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.delete = {
          message: payload.message,
          status: STATUS.ERROR,
        };
        draft.data = {};
      }),
    [AddressKeyConstants.ADDRESS_KEY_DELETE_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.delete = {
          message: '',
          status: STATUS.SUCCESS,
        };

        draft.payload = payload;
      }),
    [AddressKeyConstants.ADDRESS_KEY_PIN_VALIDATION_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.validation = {
          message: '',
          status: STATUS.RUNNING,
          payload: {},
        };
      }),
    [AddressKeyConstants.ADDRESS_KEY_PIN_VALIDATION_FAILURE]: (state,
      { payload }) =>
      produce(state, (draft) => {
        draft.validation = {
          message: payload.message,
          status: STATUS.ERROR,
          payload: {},
        };
      }),
    [AddressKeyConstants.ADDRESS_KEY_PIN_VALIDATION_SUCCESS]: (state,
      { payload }) =>
      produce(state, (draft) => {
        draft.validation = {
          message: payload.message,
          status: STATUS.SUCCESS,
          payload,
        };
      }),
    [AddressKeyConstants.ADDRESS_KEY_DETAIL_SHOW]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.addressKeyDetail = {
          addressKey: payload.addressKey,
          message: '',
          status: STATUS.SUCCESS,
        };
      }),
    [AddressKeyConstants.ADDRESS_KEY_LIST_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.addressKeys = {
          ...draft.addressKeys,
          message: '',
          status: STATUS.RUNNING,
        };
      }),
    [AddressKeyConstants.ADDRESS_KEY_LIST_FAILURE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.addressKeys = {
          ...draft.addressKeys,
          message: payload.message,
          status: STATUS.ERROR,
        };
      }),
    [AddressKeyConstants.ADDRESS_KEY_LIST_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.addressKeys = {
          ...draft.addressKeys,
          message: '',
          status: STATUS.SUCCESS,
          list: !payload?.length ? [] : payload,
        };
      }),
    [AddressKeyConstants.SET_KEY_TYPE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.keyType = payload;
      }),
    [AddressKeyConstants.SET_KEY_VALUE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.keyValue = payload;
      }),
    [AddressKeyConstants.SET_DETAIL_KEY]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.addressKeys.detailedKey = payload;
      }),
    [AddressKeyConstants.SET_FINAL_KEY_LIST]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.addressKeys.finalList = payload;
      }),
  },
  addressKeyState),
};

import withViewer from 'components/withViewer';
import withAuthToken from 'components/withAuthToken';
import withRedirect from 'components/withRedirect';
import withKeys from 'components/withKeys';
import withOnboarding from 'components/withOnboarding';
import { compose } from 'modules/helpers';
import KeysManagement from './KeysManagement';

export default compose(
  withAuthToken, 
  withRedirect,
  withViewer, 
  withKeys,
  withOnboarding
)(KeysManagement);

import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modals from 'containers/Modals';
import CoverScreen from 'containers/CoverScreen';
import { useTreeChanges } from 'hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { formatValue } from 'modules/content';
import { BottomBar } from 'containers/Layout';
import { showAlert } from 'actions/app';
import { mountSelectedTypeSettings } from 'components/MaskedInput/helpers';

import {
  addressKeyPinValidate,
  clearAddressKeyResponse,
  clearAddressKeyValues,
  listAddressKey,
  associateKey,
  checkoutKeyManagementFlowPop,
  toggleConfirmModal,
  setModalError,
  addressKeyDelete,
} from 'actions';
import { useSelector, useDispatch } from 'react-redux';
import { Heading, LoadingButton } from '@sumup/circuit-ui';
import {
  ADDRESS_KEY_TYPE_EMAIL,
  ADDRESS_KEY_TYPE_PHONE,
  STATUS,
} from 'constants';
import { createKeyResponseHandling } from 'modules/content';
import withAuthToken from 'components/withAuthToken';
import {
  KeyValidationWrapper,
  StyledText,
  SideBySideButton,
  DeleteButtonStyled,
  LoadingButtonStyled,
  StyledInput,
} from './KeyValidation.styles';

const KeyValidation = ({ participationType, hideDeleteKeyButton = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { ConfirmModal } = Modals;
  const confirmationModalText =
    'Tem certeza que você quer encerrar o cadastro desta chave Pix pendente?';

  const [resendLoading, setResendLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [coverState, setCoverState] = useState(false);
  const [invalidInput, setInvalidInput] = useState({ invalid: false });
  const [responseContent, setResponseContent] = useState({});
  const {
    keyType: selectedKeyType,
    keyValue,
    validation,
    create,
    delete: deleteAction,
    addressKeys: { detailedKey },
  } = useSelector((state) => state.addressKey);
  const { settings = {} } = mountSelectedTypeSettings(selectedKeyType);

  if(state?.hideDeleteKeyButton) {
    hideDeleteKeyButton = state.hideDeleteKeyButton;
  }

  const { changedFrom: deleteStatusChangedFrom } = useTreeChanges(
    deleteAction.status
  );

  const { hijackManagementFlow } = useSelector((state) => state.checkout);

  const errorContent = {
    [ADDRESS_KEY_TYPE_PHONE.canonical]: ' SMS',
    [ADDRESS_KEY_TYPE_EMAIL.canonical]: ' e-mail',
  };

  const content = {
    [ADDRESS_KEY_TYPE_PHONE.canonical]: ' SMS para o número',
    [ADDRESS_KEY_TYPE_EMAIL.canonical]: ' e-mail para o endereço',
  };

  const { changedFrom: validationStatusChangedFrom } = useTreeChanges(
    validation.status
  );
  const { changedFrom: resendPinChangedFrom } = useTreeChanges(create.status);

  const handleExcludePendingKeyConfirmation = useCallback(() => {
    dispatch(toggleConfirmModal(false));
    dispatch(addressKeyDelete({ key: settings.unmask(keyValue?.value) }));
    setLoadingDelete(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, keyValue]);

  const openPendingKeyExclusionConfirmationModal = useCallback(() => {
    dispatch(toggleConfirmModal(true));
  }, [dispatch]);

  const validateValue = (value) => {
    return value.replace(/[\D]/g, '').length === 6;
  };

  const onInputChange = ({ target: { value } }) => {
    setInvalidInput({
      invalid: false,
      message: '',
    });
    setInputValue(value);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if(!validateValue(inputValue)) {
      setInvalidInput({
        invalid: true,
        message: 'Valor inserido é invalido',
      });
      return;
    }
    
    setLoading(true);
    dispatch(
      addressKeyPinValidate({
        token: inputValue,
        participationType,
        key: keyValue.value,
      })
    );
  };

  const onResend = () => {
    setResendLoading(true);
    dispatch(
      associateKey({
        key: settings.unmask(keyValue?.value),
        keyType: selectedKeyType.canonical,
      })
    );
  };

  useEffect(() => {
    if (resendPinChangedFrom('create.status', STATUS.RUNNING, STATUS.SUCCESS)) {
      setResendLoading(false);
      dispatch(
        showAlert('Código de segurança enviado com sucesso.', {
          status: STATUS.SUCCESS,
        })
      ); 
    }
  }, [dispatch, resendPinChangedFrom]);

  useEffect(() => {
    if (resendPinChangedFrom('create.status', STATUS.RUNNING, STATUS.ERROR)) {
      setResendLoading(false);
    }
  }, [resendPinChangedFrom]);

  useEffect(() => {
    if (create.status === 'success') {
      dispatch(listAddressKey());
    }
  }, [create.status, dispatch]);

  useEffect(() => {
    if (
      validationStatusChangedFrom(
        'validation.status',
        STATUS.RUNNING,
        STATUS.SUCCESS
      ) &&
      !participationType
    ) {
      setLoading(false);

      let validationResponse = createKeyResponseHandling('successful')(
        keyValue,
        selectedKeyType
      );
      if (hijackManagementFlow) {
        dispatch(checkoutKeyManagementFlowPop());
        validationResponse = createKeyResponseHandling('successful')(
          keyValue,
          selectedKeyType,
          '/checkout'
        );
      }

      setResponseContent(validationResponse);
      dispatch(listAddressKey());

      setCoverState(true);
      setInvalidInput({
        invalid: false,
        message: '',
      });
    }
  }, [
    hijackManagementFlow,
    validationStatusChangedFrom,
    dispatch,
    keyValue,
    selectedKeyType,
    participationType,
  ]);

  useEffect(() => {
    const requestError = validation?.requestError;

    if (
      validationStatusChangedFrom(
        'validation.status',
        STATUS.RUNNING,
        STATUS.ERROR
      ) &&
      !requestError
    ) {
      setLoading(false);
      setInvalidInput({
        invalid: true,
        message: 'Código de segurança inválido',
      });
    }
  }, [validationStatusChangedFrom, dispatch, validation]);

  useEffect(() => {
    const errorCode = validation?.message?.code;
    const addressKeyParticipantName = validation?.message?.options?.accountParticipantName;

    if (
      validationStatusChangedFrom(
        'validation.status',
        STATUS.RUNNING,
        STATUS.ERROR
      )
    ) {
      setLoading(false);

      if (errorCode === 'token_not_found') {
        setInvalidInput({
          invalid: true,
          message: 'Código de segurança inválido',
        });
        dispatch(setModalError({
          title: <>Código de segurança inválido.</>,
          subtitle: <>Verifique o mais recente código de segurança enviado por {errorContent[selectedKeyType?.canonical]} e tente novamente.</>
        })); 
      } else {
        const validationResponse = createKeyResponseHandling(errorCode)(
          keyValue,
          selectedKeyType,
          addressKeyParticipantName
        );
        setCoverState(true);
        setResponseContent(validationResponse);
  
        setInvalidInput({
          invalid: false,
          message: '',
        });
      }
    }
  }, [
    validationStatusChangedFrom,
    dispatch,
    validation,
    selectedKeyType,
    keyValue,
    errorContent,
  ]);

  useEffect(() => {
    if (
      deleteStatusChangedFrom('delete.status', STATUS.RUNNING, STATUS.SUCCESS)
    ) {
      dispatch(clearAddressKeyResponse());
      dispatch(clearAddressKeyValues());
      dispatch(listAddressKey());
      history.replace('../keys-management');
    }
  }, [deleteStatusChangedFrom, detailedKey, dispatch, history]);

  return (
    <div>
      <form onSubmit={onSubmit}>
        <CoverScreen content={responseContent} active={coverState} />
        <KeyValidationWrapper>
          <Heading size="kilo" noMargin>
            Código de segurança enviado
          </Heading>
          <StyledText>
            Digite o <strong>código de segurança</strong> que acabamos de enviar por
            {content[selectedKeyType?.canonical]}{' '}
            <strong>{formatValue(keyValue?.value || keyValue, selectedKeyType?.canonical)}</strong>
          </StyledText>
          <StyledInput
            type="tel"
            invalid={invalidInput.invalid}
            validationHint={invalidInput.message}
            label="Código de segurança"
            hideLabel={true}
            placeholder="000000"
            inputmode="numeric"
            max="6"
            onChange={onInputChange}
          />
          <BottomBar>
            <LoadingButtonStyled
              type="button"
              isLoading={resendLoading}
              size="mega"
              stretch={true}
              variant="secondary"
              onClick={onResend}>
              Reenviar código
            </LoadingButtonStyled>
            <SideBySideButton>
              {!hideDeleteKeyButton 
                ? <DeleteButtonStyled
                  type="button"
                  size="mega"
                  isLoading={loadingDelete}
                  variant="secondary"
                  onClick={openPendingKeyExclusionConfirmationModal}>
                  Excluir
                </DeleteButtonStyled> 
                : <></>}
              <LoadingButton
                type="submit"
                isLoading={loading}
                size="mega"
                variant="primary"
                onClick={onSubmit}
                onSubmit={onSubmit}
                disabled={invalidInput.invalid}
                style={{ width: !hideDeleteKeyButton ? '50%' : '100%' }}
              >
                Continuar
              </LoadingButton>
            </SideBySideButton>
          </BottomBar>
        </KeyValidationWrapper>
      </form>
      <ConfirmModal
        handleConfirm={handleExcludePendingKeyConfirmation}
        text={confirmationModalText}
      />
    </div>
  );
};

KeyValidation.propTypes = {
  participationType: PropTypes.string,
};

export default withAuthToken(KeyValidation);

import React from 'react';
import { Text } from '@sumup/circuit-ui';
import PropTypes from 'prop-types';
import { Wrapper, StyledSpinner } from './Processing.styles';

const Processing = ({ message }) => (
  <Wrapper data-testid="processing">
    <StyledSpinner />
    <Text bold>{message}</Text>
  </Wrapper>
);

Processing.propTypes = {
  message: PropTypes.string,
};

Processing.defaultProps = {
  message: '',
};

export default Processing;

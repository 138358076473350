import { createActions } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';
import { AppConstants } from 'constants';

export const {
  appSetStep,
  hideAlert,
  setAuthToken,
  showAlert,
  isProcessing,
  setNavigation,
  isPixEnabled,
  toggleSuccessModal,
  toggleConfirmModal,
  setModalError,
} = createActions({
  [AppConstants.APP_SET_STEP]: (step) => ({ ...step }),
  [AppConstants.HIDE_ALERT]: (id) => ({ id }),
  [AppConstants.SET_AUTH_TOKEN]: (authToken) => ({ authToken }),
  [AppConstants.SHOW_ALERT]: (message, options = {}) => ({
    icon: options.icon,
    id: options.id || uuidv4(),
    message,
    position: options.position || 'BOTTOM_RIGHT',
    status: options.status,
    timeout: options.timeout,
  }),
  [AppConstants.IS_PROCESSING]: (processing) => ({ processing }),
  [AppConstants.IS_PIX_ENABLED]: (isEnabled) => isEnabled,
  [AppConstants.SET_NAVIGATION]: (redirectTo) => ({ redirectTo }),
  [AppConstants.TOGGLE_SUCCESS_MODAL]: (status) => ({ status }),
  [AppConstants.TOGGLE_CONFIRM_MODAL]: (status) => ({ status }),
  [AppConstants.SET_MODAL_ERROR]: (content) => ({ content }),
});

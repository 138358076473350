import React from 'react';
import PropTypes from 'prop-types';
import { useBottomBar } from 'hooks';
import { Heading, Text, Button } from '@sumup/circuit-ui';
import { BottomBar } from 'containers/Layout';
import {
  FullWidthWrapper,
  CenteredContainer,
  StyledCrossIcon,
  StyledErrorIcon,
} from 'containers/Checkout';

const CheckoutError = ({ onRetry, onClose, onCancel }) => {
  const [bottombarRef] = useBottomBar();

  return (
    <FullWidthWrapper data-testid="checkout-error" backgroundColor="white" fontColor="black">
      <StyledCrossIcon onClick={onClose} />
      <CenteredContainer>
        <StyledErrorIcon className="icon" />
        <Heading className="heading" size="giga" as="strong">
          Erro de Processamento
        </Heading>
        <Text className="description">
          Ocorreu um erro ao processar seu Pix. Por favor, tente novamente.
        </Text>
      </CenteredContainer>
      <BottomBar ref={bottombarRef}>
        <Button
          onClick={onRetry}
          stretch={true}
          variant="primary"
          css={{ marginBottom: '14px' }}
        >
          Tentar novamente
        </Button>
        <Button onClick={onCancel} stretch={true} variant="secondary">
          Cancelar
        </Button>
      </BottomBar>
    </FullWidthWrapper>
  );
};

CheckoutError.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
};

export default CheckoutError;

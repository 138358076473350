import React from 'react';
import { useDispatch } from 'react-redux';
import NewAddressKeyScreen from 'containers/NewAddressKey';
import withAuthToken from 'components/withAuthToken';
import { setKeyType } from 'actions';
import {
  ADDRESS_KEY_TYPE_CNPJ,
  ADDRESS_KEY_TYPE_CPF,
  ADDRESS_KEY_TYPE_EMAIL,
  ADDRESS_KEY_TYPE_EVP,
  ADDRESS_KEY_TYPE_PHONE,
} from 'constants';
import { useParams } from 'react-router';
import Header from '../../containers/Header/Header';

const addressKeyTypesMap = {
  EMAIL: ADDRESS_KEY_TYPE_EMAIL,
  PHONE: ADDRESS_KEY_TYPE_PHONE,
  CPF: ADDRESS_KEY_TYPE_CPF,
  CNPJ: ADDRESS_KEY_TYPE_CNPJ,
  EVP: ADDRESS_KEY_TYPE_EVP,
};

const NewAddressKeyWithType = () => {
  const dispatch = useDispatch();
  const { keyType: keyTypeParam } = useParams();
  let keyType;

  if (keyTypeParam) {
    keyType = addressKeyTypesMap[`${keyTypeParam}`.toLocaleUpperCase()];
    dispatch(setKeyType(keyType));
  }

  return (
    <>
      <Header />
      <NewAddressKeyScreen />
    </>
  );
};

export default withAuthToken(NewAddressKeyWithType);

import React from 'react';
import { useDispatch } from 'react-redux';
import { setDetailKey, setKeyValue, setKeyType } from 'actions';
import { useHistory } from 'react-router-dom';

import {
  CREATION_STATUSES,
  ADDRESS_KEY_TYPE_EMAIL,
  ADDRESS_KEY_TYPE_PHONE,
  ADDRESS_KEY_TYPE_CNPJ,
  ADDRESS_KEY_TYPE_CPF,
  ADDRESS_KEY_TYPE_EVP,
} from 'constants/';

import KeysList from 'containers/KeysList';

const keyTypes = [
  ADDRESS_KEY_TYPE_EMAIL,
  ADDRESS_KEY_TYPE_PHONE,
  ADDRESS_KEY_TYPE_CNPJ,
  ADDRESS_KEY_TYPE_CPF,
  ADDRESS_KEY_TYPE_EVP,
];

const KeysManagement = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleRowClick = (
    addressKey, type, value, status, currentStatus
  ) => {
    dispatch(setDetailKey({ ...addressKey, status }));
  
    const keyType = keyTypes.find(({ canonical }) => canonical === type);
    dispatch(setKeyType(keyType));
    dispatch(setKeyValue({
      value,
    }));

    if (currentStatus === CREATION_STATUSES.pendingValidation) {
      history.push('/new-key/validation');
    }
    else {
      history.push('/keys-management/detail');
    }
  };

  return (
    <>
      <KeysList onClick={handleRowClick} />
    </>
  );
};

export default KeysManagement;

import React from 'react';
import PropTypes from 'prop-types';
import TableList from 'components/TableList';
import { formatValues, addressKeyTypes } from 'modules/content';

const { TableTitle, TableRow } = TableList;

const KeysSelectionList = ({ keysToShow, onClick }) => {
  const mountAddressKeyRow = (addressKeysList) =>
    addressKeysList?.map(({ key, keyType }, index) => (
      <TableRow
        onClick={() => onClick({key, keyType})}
        detail={true}
        key={key}
        value={formatValues(
          addressKeysList[index], keyType, 'key'
        )}
        label={addressKeyTypes[keyType]?.name}
      />
    ));

  return (
    <>
      <TableTitle size="kilo">Chaves</TableTitle>
      {mountAddressKeyRow(keysToShow)}
    </>
  );
};

KeysSelectionList.propTypes = {
  keysToShow: PropTypes.array,
  onClick: PropTypes.func,
};

KeysSelectionList.defaultProps = {
  keysToShow: [],
};

export default KeysSelectionList;

import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const Wrapper = styled('div')(() => css`
    display: flex;
    min-height: 80vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 8px;
  `);

export const StyledSpinner = styled('div')(({ theme }) => css`
    -webkit-animation: spin 1s ease-in-out infinite;
    animation: spin 500ms ease-in-out infinite;
    border-radius: 50%;
    border: ${theme.spacings.bit} solid ${theme.colors.p300};
    border-top-color: ${theme.colors.p500};
    display: inline-block;
    height: 64px;
    width: 64px;
    margin: 0 0 ${theme.spacings.giga};

    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
  `);

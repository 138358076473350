import { CheckoutConstants, STATUS } from 'constants';
import { handleActions } from 'redux-actions';
import produce from 'immer';

export const checkoutState = {
  create: {
    message: '',
    status: STATUS.IDLE,
  },
  payment: {
    status: STATUS.IDLE,
  },
  hijackManagementFlow: false,
  data: {},
};

export default {
  checkout: handleActions({
    [CheckoutConstants.CHECKOUT_GENERATE_QR_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.create = {
          message: '',
          status: STATUS.RUNNING,
        };
      }),
    [CheckoutConstants.CHECKOUT_GENERATE_QR_FAILURE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.create = {
          message: payload.message,
          status: STATUS.ERROR,
        };
        draft.data = payload;
      }),
    [CheckoutConstants.CLEAR_CHECKOUT_GENERATE_QR_RESPONSE]: (state) =>
      produce(state, (draft) => {
        draft.create = {
          message: null,
          status: STATUS.IDLE,
        };
      }),
    [CheckoutConstants.CHECKOUT_GENERATE_QR_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.create = {
          message: '',
          status: STATUS.SUCCESS,
        };

        draft.qrcode = payload?.qrCode;
        draft.qrcodeId = payload?.qrCodeId;
        draft.price = payload?.amount / 100;
        draft.description = payload?.additionalData;
        draft.paymentKey = payload?.key;
      }),
    [CheckoutConstants.CHECKOUT_KEY_MANAGEMENT_FLOW_PUSH]: (state) =>
      produce(state, (draft) => {
        draft.hijackManagementFlow = true;
      }),
    [CheckoutConstants.CHECKOUT_KEY_MANAGEMENT_FLOW_POP]: (state) =>
      produce(state, (draft) => {
        draft.hijackManagementFlow = false;
      }),
  },
  checkoutState),
};

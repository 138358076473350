import React from 'react';
import { useSelector } from 'react-redux';
import { CheckoutScreen } from 'containers/Checkout';
import NoKeysCreated from 'containers/NoKeysCreated';

const NoCreatedKeys = () => {
  const { addressKeys } = useSelector((state) => state.addressKey);
  const createdKeys = (addressKeys.list || []).filter(
    (a) => a.currentStatus === 'CREATED'
  );
  const hasCreatedKeys = createdKeys.length > 0;

  return hasCreatedKeys ? <CheckoutScreen /> : <NoKeysCreated />;
};

export default NoCreatedKeys;

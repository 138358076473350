import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Card, Text, Button } from '@sumup/circuit-ui';

export const ContainerWrapperCentered = styled('div')(({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `);

export const StyledCard = styled(Card)(() => css`
    padding: 0;
  `);

export const CardSpacing = styled('div')(({ theme }) => css`
    padding: ${theme.spacings.mega} ${theme.spacings.mega} 0;
  `);

export const StyledText = styled(Text)(({ theme }) => css`
    margin: ${theme.spacings.mega} 0;
  `);

export const StyledButton = styled(Button)(({ theme, spacing }) => css`
    margin: ${theme.spacings[spacing]} 0;
  `);

import { keyMirror } from 'modules/helpers';

export const ClaimConstants = keyMirror({
  SET_CLAIM_KEY: undefined,
  CLEAR_RESPONSE: undefined,
  CLEAR_VALUES: undefined,
  CREATE_CLAIM_REQUEST: undefined,
  CREATE_CLAIM_SUCCESS: undefined,
  CREATE_CLAIM_FAILURE: undefined,
  CLAIM_FAILURE: undefined,
  LIST_CLAIM_REQUEST: undefined,
  LIST_CLAIM_FAILURE: undefined,
  LIST_CLAIM_SUCCESS: undefined,
  ACCEPT_CLAIM_REQUEST: undefined,
  ACCEPT_CLAIM_FAILURE: undefined,
  ACCEPT_CLAIM_SUCCESS: undefined,
  COMPLETE_CLAIM_REQUEST: undefined,
  COMPLETE_CLAIM_FAILURE: undefined,
  COMPLETE_CLAIM_SUCCESS: undefined,
  REJECT_CLAIM_REQUEST: undefined,
  REJECT_CLAIM_FAILURE: undefined,
  REJECT_CLAIM_SUCCESS: undefined,
  CLEAR_CLAIM_RESPONSE: undefined,
  CLEAR_CLAIM_VALUES: undefined,
});

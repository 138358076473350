import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@sumup/circuit-ui';
import { executeCopy } from 'modules/dom';
import { showAlert } from 'actions/app';
import { useDispatch } from 'react-redux';
import { STATUS } from 'constants';
import {
  Wrapper,
  Value,
  Label,
  DetailIcon,
  CopyButton,
  InnerRow,
  CopyPasteIconStyled
} from './TableList.styles';

const TableRow = ({ value, label, detail, status, onClick, copyEnabled = false, originalValue }) => {
  const dispatch = useDispatch();

  const onCopyPasteClickHandler = () => {
    const valueToCopy = (originalValue || value || '').replace("+55", "");
    executeCopy(valueToCopy);
    dispatch(showAlert('O valor da chave foi copiado com sucesso', { status: STATUS.SUCCESS }));
  };
  
  return (<Wrapper data-testid="tableRow" onClick={onClick}>
    {detail && <DetailIcon />}
    {copyEnabled && <CopyButton onClick={onCopyPasteClickHandler}>
      <CopyPasteIconStyled />
      </CopyButton>}
    <InnerRow>
      <Value>{value}</Value>
    </InnerRow>
    <InnerRow spacingTop="bit">
      <Label>{label}</Label>
      {status?.type ? (
        <Badge variant={status.type}>{status.label}</Badge>
      ) : (
        <></>
      )}
    </InnerRow>
  </Wrapper>)
};

TableRow.propTypes = {
  detail: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  status: PropTypes.object,
  value: PropTypes.string,
  textSelectEnabled: PropTypes.bool
};

export default TableRow;

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Button, Card, CardHeader, Anchor } from '@sumup/circuit-ui';

export const NewKeyAnchor = styled(Anchor)(({ theme }) => css`
    padding: ${theme.spacings.mega};
    text-decoration: none;
    color: ${theme.colors.b500};
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    transition: none;
    text-align: center;
    width: 100%;
  `);

export const CardHeaderStyled = styled(CardHeader)(({ theme }) => css`
    font-weight: 700;
    align-items: center;
    justify-content: center;
    margin-bottom: ${theme.spacings.mega};
  `);

export const CardStyled = styled(Card)(({ theme }) => css`
    margin: ${theme.spacings.mega};
    text-align: center;
  `);


export const NewKeyScreen = styled('div')(({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: ${theme.spacings.mega};
    position: relative;
    width: 100vw;
    align-items: center;
  `);

export const KeyManagementContainer = styled('div')(({ theme }) => css`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    margin-bottom: 65px;
    position: relative;
  `);

export const KeyListContainer = styled('div')(({ theme }) => css`
    width: 100%;
    overflow-y: auto;
  `);

export const Wrapper = styled('div')(({ theme }) => css`
    padding: 0 16px;
  `);

export const KeyIconContainer = styled('div')(({ theme }) => css`
    border: 2px solid ${theme.colors.p500};
    border-radius: 40px;
    background-color: ${theme.colors.p500};
    height: 80px;
    width: 80px;
    padding: ${theme.spacings.mega};
    display: inline-block;
  `);

export const InfoCardButtonStyle = styled(Button)(() => css`
    margin-top: '16px';
  `);

export const ContainerWrapperCentered = styled('div')(({ theme }) => css`
    width: 100%;

    p {
      margin: ${theme.spacings.mega};
    }
  `);

export const Heading = styled('h2')(({ theme }) => css`
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    padding: ${theme.spacings.mega};
    margin: ${theme.spacings.mega} 0;
  `);

export const TitleContainer = styled('div')(({ theme }) => css`
    text-align: left;
    width: 100vw;
    height: 40px;
  `);

export const KeyListTitle = styled('p')(({ theme }) => css`
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    color: ${theme.colors.n900};
    margin: ${theme.spacings.mega};
  `);

export const Text = styled('p')(({ theme }) => css`
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    margin: ${theme.spacings.giga} 0;

    & strong {
      font-weight: bold;
    }

    & a {
      color: ${theme.colors.p500};
      text-decoration: none;
    }
  `);

export const InfoCard = styled(Card)(({ theme }) => css`
    align-items: center;
    justify-content: center;

    svg {
      margin-top: ${theme.spacings.giga};
    }

    p {
      margin: ${theme.spacings.mega} 0;
    }

    a {
      color: ${theme.colors.p500};
      text-decoration: none;
      font-weight: bold;
      margin: ${theme.spacings.mega} 0;
    }
  `);

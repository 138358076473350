import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { appSteps } from 'modules/appSteps';
import { appSetStep, clearAddressKeyValues } from 'actions';
import { useHistory } from 'react-router-dom';
import { Button } from '@sumup/circuit-ui';
import { ContainerWrapperCentered, BottomBar } from 'containers/Layout';
import IllustrationSource from 'assets/images/hand-mobile-data.svg';
import {
  SharedInfoImageWrapper,
  Text,
  Container,
  BorderContainer,
} from './SharedDataInfo.styles';
import { useBottomBar } from 'hooks';

const SharedDataInfo = () => {
  const [bottombarRef] = useBottomBar();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      appSetStep({
        currentStep: appSteps.sharedDataInfo.step,
        beforeGoBack: () => {
          dispatch(clearAddressKeyValues());
        },
      })
    );
  }, [dispatch]);

  return (
    <>
      <ContainerWrapperCentered>
        <Container>
          <SharedInfoImageWrapper>
            <img src={IllustrationSource} alt="" />
          </SharedInfoImageWrapper>
          <Text>
            Ao utilizar a sua chave Pix, qualquer pessoa poderá ver os seus
            dados, como:
          </Text>

          <BorderContainer>
            <ul>
              <li>Nome Completo;</li>
              <li>
                CPF escondendo os primeiros três e os últimos dois dígitos;
              </li>
              <li>
                O nome do prestador de serviços de pagamento onde a sua chave
                está cadastrada, o SumUp Bank;
              </li>
            </ul>
          </BorderContainer>

          <BottomBar ref={bottombarRef}>
            <Button
              variant="primary"
              stretch={true}
              onClick={() => history.push('/new-key/value')}
            >
              Continuar
            </Button>
          </BottomBar>
        </Container>
      </ContainerWrapperCentered>
    </>
  );
};

export default SharedDataInfo;

import styled from '@emotion/styled';

export const Container = styled.div`
  .Toastify__toast {
    border-radius: 5px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1), 0 2px 15px 0 rgba(0, 0, 0, .05);
    box-sizing: border-box;
    cursor: pointer;
    direction: ltr;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    max-height: 800px;
    min-height: 64px;
    overflow: hidden;
    padding: 8px;
    position: relative;

    &-body{
      padding: 4px;
    }

    &-container {
      -webkit-transform: translate3d(0, 0, 9999);
      box-sizing: border-box;
      color: #fff;
      padding: 4px;
      position: fixed;
      width: 320;
      z-index: 9999;

      &--top-center {
        left: 50%;
        margin-left: -160px;
        top: 1em;
      }

      &--top-center {
        left: 50%;
        margin-left: -160px;
        top: 1em;
      }

      &--top-right {
        right: 1em;
        top: 5em;
      }

      &--bottom-left {
        left: 1em;
        bottom: 1em;
      }

      &--bottom-right {
        right: 1em;
        bottom: 1em;
      }

      &--bottom-center {
        bottom: 1em;
        left: 50%;
        margin-left: -160px;
      }
    }

    &--info {
      background: #3388FF;
    }

    &--success {
      align-items: center;
      border-radius: 0px;
      background: #000;
      font-size: 14px;
      min-height: 48px;
      margin-bottom: 0;
    }

    &--warning {
      background: #D8A413;
    }

    &--error {
      background: #DB4D4D;
    }
  }

  .Toastify__close-button {
    display: none;
  }
`;

export const AnimationBounce = styled.div`
  @keyframes Toastify__bounceInRight {
      from,
      60%,
      75%,
      90%,
      to {
          animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      }
      from {
          opacity: 0;
          transform: translate3d(3000px, 0, 0);
      }
      60% {
          opacity: 1;
          transform: translate3d(-25px, 0, 0);
      }
      75% {
          transform: translate3d(10px, 0, 0);
      }
      90% {
          transform: translate3d(-5px, 0, 0);
      }
      to {
          transform: none;
      }
    }

    @keyframes Toastify__bounceOutRight {
        20% {
            opacity: 1;
            transform: translate3d(-20px, 0, 0);
        }
        to {
            opacity: 0;
            transform: translate3d(2000px, 0, 0);
        }
    }

    @keyframes Toastify__bounceInLeft {
        from,
        60%,
        75%,
        90%,
        to {
            animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        }
        0% {
            opacity: 0;
            transform: translate3d(-3000px, 0, 0);
        }
        60% {
            opacity: 1;
            transform: translate3d(25px, 0, 0);
        }
        75% {
            transform: translate3d(-10px, 0, 0);
        }
        90% {
            transform: translate3d(5px, 0, 0);
        }
        to {
            transform: none;
        }
    }

    @keyframes Toastify__bounceOutLeft {
        20% {
            opacity: 1;
            transform: translate3d(20px, 0, 0);
        }
        to {
            opacity: 0;
            transform: translate3d(-2000px, 0, 0);
        }
    }

    @keyframes Toastify__bounceInUp {
        from,
        60%,
        75%,
        90%,
        to {
            animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        }
        from {
            opacity: 0;
            transform: translate3d(0, 3000px, 0);
        }
        60% {
            opacity: 1;
            transform: translate3d(0, -20px, 0);
        }
        75% {
            transform: translate3d(0, 10px, 0);
        }
        90% {
            transform: translate3d(0, -5px, 0);
        }
        to {
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes Toastify__bounceOutUp {
        20% {
            transform: translate3d(0, -10px, 0);
        }
        40%,
        45% {
            opacity: 1;
            transform: translate3d(0, 20px, 0);
        }
        to {
            opacity: 0;
            transform: translate3d(0, -2000px, 0);
        }
    }

    @keyframes Toastify__bounceInDown {
        from,
        60%,
        75%,
        90%,
        to {
            animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        }
        0% {
            opacity: 0;
            transform: translate3d(0, -3000px, 0);
        }
        60% {
            opacity: 1;
            transform: translate3d(0, 25px, 0);
        }
        75% {
            transform: translate3d(0, -10px, 0);
        }
        90% {
            transform: translate3d(0, 5px, 0);
        }
        to {
            transform: none;
        }
    }

    @keyframes Toastify__bounceOutDown {
        20% {
            transform: translate3d(0, 10px, 0);
        }
        40%,
        45% {
            opacity: 1;
            transform: translate3d(0, -20px, 0);
        }
        to {
            opacity: 0;
            transform: translate3d(0, 2000px, 0);
        }
    }

    .Toastify__bounce-enter {
        &--top-left,
        &--bottom-left {
            animation-name: Toastify__bounceInLeft;
        }
        &--top-right,
        &--bottom-right {
            animation-name: Toastify__bounceInRight;
        }
        &--top-center {
            animation-name: Toastify__bounceInDown;
        }
        &--bottom-center {
            animation-name: Toastify__bounceInUp;
        }
    }

    .Toastify__bounce-exit {
        &--top-left,
        &--bottom-left {
            animation-name: Toastify__bounceOutLeft;
        }
        &--top-right,
        &--bottom-right {
            animation-name: Toastify__bounceOutRight;
        }
        &--top-center {
            animation-name: Toastify__bounceOutUp;
        }
        &--bottom-center {
            animation-name: Toastify__bounceOutDown;
        }
    }
`;

export const Progress = styled.div`
  @keyframes Toastify__trackProgress {
    0% { transform: scaleX(1); }
    100% { transform: scaleX(0); }
  }

  .Toastify__progress-bar {
    background-color: rgba(255,255,255,.7);
    bottom: 0;
    height: 5px;
    left: 0;
    opacity: 0.7;
    position: absolute;
    transform-origin: left;
    width: 100%;
    z-index: 9999;

    &--animated {
      animation: Toastify__trackProgress linear 1 forwards;

      &--controlled {
        transition: transform .2s;
      }

      &--rtl {
        right: 0;
        left: initial;
        transform-origin: right;
      }

      &--default{
        background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
      }
    }
  }
`;

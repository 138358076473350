import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Heading } from '@sumup/circuit-ui';
import ListSelector from 'components/ListSelector';
import { setKeyType, setKeyValue } from 'actions';

import {
  ADDRESS_KEY_TYPE_EMAIL,
  ADDRESS_KEY_TYPE_PHONE,
  ADDRESS_KEY_TYPE_CNPJ,
  ADDRESS_KEY_TYPE_CPF,
  ADDRESS_KEY_TYPE_EVP,
  PERSON_TYPE_PJ,
  PERSON_TYPE_PF,
} from 'constants/';

import {
  ContainerWrapperCentered,
  CardSpacing,
  StyledText,
} from './SelectKeyType.styles';

const addressKeyTypes = [
  ADDRESS_KEY_TYPE_EMAIL,
  ADDRESS_KEY_TYPE_PHONE,
  ADDRESS_KEY_TYPE_CNPJ,
  ADDRESS_KEY_TYPE_CPF,
  ADDRESS_KEY_TYPE_EVP,
];


const setPersonType =[PERSON_TYPE_PJ, PERSON_TYPE_PF];

const filterAddressKeyTypes = (keyTypes, { personType }) =>
  keyTypes.filter((keyType) => {
    if (personType === PERSON_TYPE_PF.canonical) {
      return keyType.canonical !== ADDRESS_KEY_TYPE_CNPJ.canonical;
    }

    if (personType === PERSON_TYPE_PJ.canonical) {
      return keyType.canonical !== ADDRESS_KEY_TYPE_CPF.canonical;
    }

    return true;
  });

const KeysRegistration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { flags, profile } = useSelector((state) => state.account);

  const { keyType } = useSelector((state) => state.addressKey);

  const onItemClick = (type) => {
    history.push('/shared-data-info');
    dispatch(setKeyValue({
      value: '',
      masked: '',
    }));
    dispatch(setKeyType(type));
  };

  const filteredAddressKeyTypes = filterAddressKeyTypes(addressKeyTypes,
    profile, setPersonType);

  return (
    <ContainerWrapperCentered data-testid="select-key-type">
      {flags.userHasAccount && (
        <>
          <CardSpacing>
            <Heading size="kilo" noMargin>
              Tipo da chave Pix
            </Heading>
            <StyledText>
              Escolha o tipo de chave que deseja vincular à sua conta SumUp Bank
            </StyledText>
          </CardSpacing>
          {!keyType && (
            <ListSelector
              items={filteredAddressKeyTypes}
              onItemClick={onItemClick}
            />
          )}
        </>
      )}
    </ContainerWrapperCentered>
  );
};

export default KeysRegistration;

import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const FieldWrapperPixKey = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 70px ${theme.spacings.giga};
    flex-direction: column;
  `
);

export const TitlePixKey = styled('div')(
  ({ theme }) => css`
    ${theme.typography.headings.tera};
    font-weight: ${theme.fontWeight.bold};
  `
);

export const SubTitlePixKey = styled('div')(
  ({ theme }) => css`
    ${theme.typography.subHeadings};
    margin: ${theme.spacings.giga};
  `
);

export const ImageNewPix = styled('div')(
  ({ theme }) => css`
    display: block;
  `
);

export const Container = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  `
);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Swipeable } from 'react-swipeable';
import { BottomBar } from 'containers/Layout';
import {
  Container,
  SkipButton,
  StyledButton,
  StepsControl,
  Step,
} from './Caroussel.styles';
import { useBottomBar } from 'hooks';

const Caroussel = ({ slides: slidesList, buttonData, skipAction }) => {
  const [bottombarRef] = useBottomBar();
  const slides = slidesList();
  const [step, setStep] = useState(0);
  const total = slides.length;

  const goForward = () => setStep(step <= total ? step + 1 : step);
  const goBack = () => setStep(step === 0 ? total - 1 : step - 1);

  const onButtonClick = () => (step < total - 1 ? goForward() : skipAction());

  const onSwipe = ({ dir }) => {
    if (dir === 'Left' && step < total - 1) {
      goForward();
    }

    if (dir === 'Right' && step > 0) {
      goBack();
    }
  };

  const buttonInfo = step < total - 1 ? buttonData[0] : buttonData[1];

  const getSteps = () =>
    slides.map((slide, index) => <Step active={step === index} key={index} />);

  return (
    <Container data-testid="caroussel">
      <SkipButton variant="tertiary" onClick={skipAction}>
        Pular
      </SkipButton>
      {slides.map((slide, index) => (
        <Swipeable
          onSwiped={onSwipe}
          key={index}
          index={index}
          step={step}
          style={{
            display: step === index ? 'block' : 'none',
            opacity: step === index ? 1 : 0,
            transition: 'opacity .3s ease-in',
          }}
        >
          {slide}
        </Swipeable>
      ))}
      <BottomBar ref={bottombarRef}>
        <StepsControl>{getSteps()}</StepsControl>
        <StyledButton onClick={onButtonClick} variant="primary">
          {buttonInfo.label}
        </StyledButton>
      </BottomBar>
    </Container>
  );
};

Caroussel.propTypes = {
  buttonData: PropTypes.object,
  skipAction: PropTypes.func,
  slides: PropTypes.func,
};

export default Caroussel;

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Notification, Text } from '@sumup/circuit-ui';
import { CircleWarningFilled } from '@sumup/icons';
import { FiX } from 'react-icons/fi';

export const ModalStyled = {
  CloseButton: styled(FiX)(({ theme }) => css`
    color: #212933;
    font-size: 50px;
    margin-right: 12px;
  `),
  ModalWrapper: styled('div')(({ theme }) => css`
      background-color: #FFE3E1;
      box-shadow: 0px -1px 0px rgba(12, 15, 20, 0.02), 
                  0px 1px 1px rgba(12, 15, 20, 0.06), 
                  0px 2px 2px rgba(12, 15, 20, 0.06);
      backface-visibility: hidden;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      height: auto;
      left: 50%;
      overflow-x: hidden;
      overflow-y: auto;
      position: fixed;
      top: 12%;
      transform: translate(-50%, -50%);
      width: calc(100% - (${theme.spacings.mega} * 2));
      z-index: ${theme.zIndex.modal};
    `),
  StyledNotification: styled(Notification)(({ theme }) => css`
    padding: ${theme.spacings.mega}
  `),
  Title: styled(Text)(({ theme }) => css`
    font-weight: bold;
    fond-size: 14px;
    margin: 0;
  `),
  Subtitle: styled(Text)(({ theme }) => css`
    fond-size: 14px;
    margin: 0;
  `),
  StyledErrorIcon: styled(CircleWarningFilled)(({ theme }) => css`
    color: #F00032;
  `),
};

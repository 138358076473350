import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';

export const ButtonStyled = {
  PrimaryButton: styled('button')(({ theme, disabled }) => css`
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    background-color: ${disabled ? theme.colors.n300 : theme.colors.p500};
    border-radius: ${theme.borderRadius.bit};
    border-radius: ${theme.borderRadius.giga};
    border: 1px solid ${disabled ? theme.colors.n500 : theme.colors.p700};
    color: ${disabled ? theme.colors.n700 : 'white'};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    display: flex;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    justify-content: center;
    line-height: 0;
    outline: none;
    overflow: hidden;
    padding: ${theme.spacings.byte} ${theme.spacings.mega};
    position: relative;
    width: 100%;
  `),
  SecondaryButton: styled('button')(({ theme, disabled }) => css`
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    background-color: ${disabled ? theme.colors.n300 : theme.colors.n100};
    border-radius: ${theme.borderRadius.bit};
    border-radius: ${theme.borderRadius.giga};
    border: 1px solid ${theme.colors.n500};
    color: ${disabled ? theme.colors.n500 : theme.colors.n900};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    display: flex;
    font-size: 16px;
    font-weight: bold;
    height: 48px;
    justify-content: center;
    line-height: 0;
    outline: none;
    overflow: hidden;
    padding: ${theme.spacings.byte} ${theme.spacings.mega};
    position: relative;
    width: 100%;
  `),
  PrimaryErrorButton: styled('button')(({ theme }) => css`
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    background-color: white;
    border-radius: ${theme.borderRadius.bit};
    border-radius: ${theme.borderRadius.giga};
    border: 1px solid white;
    color: ${theme.colors.p500};
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    height: 48px;
    justify-content: center;
    line-height: 0;
    outline: none;
    overflow: hidden;
    padding: ${theme.spacings.byte} ${theme.spacings.mega};
    position: relative;
    width: 100%;
  `),
  PrimaryLink: styled(Link)(({ theme, disabled }) => css`
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    background-color: ${disabled ? theme.colors.n300 : theme.colors.p500};
    border-radius: ${theme.borderRadius.bit};
    border-radius: ${theme.borderRadius.giga};
    border: 1px solid ${disabled ? theme.colors.n500 : theme.colors.p700};
    color: ${disabled ? theme.colors.n700 : 'white'};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    display: flex;
    font-size: 16px;
    font-weight: bold;
    height: 48px;
    justify-content: center;
    line-height: 0;
    outline: none;
    overflow: hidden;
    padding: ${theme.spacings.byte} ${theme.spacings.mega};
    position: relative;
    text-decoration: none;
    width: 100%;
  `),
  SecondaryLink: styled(Link)(({ theme, disabled }) => css`
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    background-color: ${disabled ? theme.colors.n300 : theme.colors.n100};
    border-radius: ${theme.borderRadius.bit};
    border-radius: ${theme.borderRadius.giga};
    border: 1px solid ${theme.colors.n500};
    color: ${disabled ? theme.colors.n700 : theme.colors.n900};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    display: flex;
    font-size: 16px;
    font-weight: bold;
    height: 48px;
    justify-content: center;
    line-height: 0;
    outline: none;
    overflow: hidden;
    padding: ${theme.spacings.byte} ${theme.spacings.mega};
    position: relative;
    text-decoration: none;
    width: 100%;
  `),
};

import { useState, useRef, useEffect } from 'react';

const useBottomBar = (initialHeight = 0) => {
  const [bottombarHeight, setBottombarHeight] = useState(initialHeight);
  const bottombarRef = useRef(null);

  useEffect(() => {
    const currentHeight = bottombarRef?.current?.offsetHeight || 0;

    if (currentHeight > 0) {
      setBottombarHeight(currentHeight + 16);
    }
  }, [bottombarHeight]);

  return [bottombarRef, bottombarHeight];
};

export default useBottomBar;

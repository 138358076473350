import { all, call, put, takeLatest } from 'redux-saga/effects';
import { client, getRequestOptions } from 'modules/resources';
import { graphql } from 'modules/graphql';
import { parseResponseError } from 'modules/client';
import { ClaimConstants } from 'constants';
import { reportBug } from 'modules/sentry';

export function* createClaim({ payload: { input } }) {
  try {
    const { data } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield graphql.mutations.createClaim({ input })),
      method: 'POST',
    });

    const { successful, messages } = data?.createClaim || {};

    if (successful) {
      yield put({
        type: ClaimConstants.CREATE_CLAIM_SUCCESS,
        payload: { successful },
      });

      return;
    }

    yield put({
      type: ClaimConstants.CREATE_CLAIM_FAILURE,
      payload: { message: messages[0] },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: ClaimConstants.CREATE_CLAIM_FAILURE,
      payload: { message: error, status },
    });

    reportBug(ClaimConstants.CREATE_CLAIM_FAILURE, {
      level: 'error',
      message: error,
    });
  }
}
export function* listClaim() {
  try {
    const {
      data: { claims },
    } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield graphql.queries.listClaim()),
      method: 'POST',
    });

    if (!claims?.length) {
      yield put({
        type: ClaimConstants.LIST_CLAIM_SUCCESS,
        payload: [],
      });
    }

    if (claims) {
      yield put({
        type: ClaimConstants.LIST_CLAIM_SUCCESS,
        payload: claims.filter(claim => claim.addressKey.active),
      });
    }
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: ClaimConstants.LIST_CLAIM_FAILURE,
      payload: { message: error, status },
    });
  }
}

export function* acceptClaim({ payload: { input } }) {
  try {
    const {
      data: {
        confirmClaim: { successful },
      },
    } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield graphql.mutations.acceptClaim({ input })),
      method: 'POST',
    });

    if (!successful) {
      yield put({
        type: ClaimConstants.ACCEPT_CLAIM_FAILURE,
      });
    }

    if (successful) {
      yield put({
        type: ClaimConstants.ACCEPT_CLAIM_SUCCESS,
      });
    }
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: ClaimConstants.ACCEPT_CLAIM_FAILURE,
      payload: { message: error, status },
    });
  }
}

export function* rejectClaim({ payload: { input } }) {
  try {
    const {
      data: {
        cancelClaim: { successful, messages },
      },
    } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield graphql.mutations.rejectClaim({ input })),
      method: 'POST',
    });

    if (!successful) {
      yield put({
        type: ClaimConstants.REJECT_CLAIM_FAILURE,
        payload: messages[0],
      });
    }

    if (successful) {
      yield put({
        type: ClaimConstants.REJECT_CLAIM_SUCCESS,
      });
    }
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: ClaimConstants.REJECT_CLAIM_FAILURE,
      payload: { message: error, status },
    });
  }
}

export function* completeClaim({ payload: { input } }) {
  try {
    const {
      data: {
        completeClaim: { successful, messages },
      },
    } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield graphql.mutations.completeClaim({ input })),
      method: 'POST',
    });

    if (!successful) {
      yield put({
        type: ClaimConstants.COMPLETE_CLAIM_FAILURE,
        payload: messages[0],
      });
    }

    if (successful) {
      yield put({
        type: ClaimConstants.COMPLETE_CLAIM_SUCCESS,
      });
    }
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: ClaimConstants.COMPLETE_CLAIM_FAILURE,
      payload: { message: error, status },
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ClaimConstants.CREATE_CLAIM_REQUEST, createClaim),
    takeLatest(ClaimConstants.LIST_CLAIM_REQUEST, listClaim),
    takeLatest(ClaimConstants.ACCEPT_CLAIM_REQUEST, acceptClaim),
    takeLatest(ClaimConstants.REJECT_CLAIM_REQUEST, rejectClaim),
    takeLatest(ClaimConstants.COMPLETE_CLAIM_REQUEST, completeClaim),
  ]);
}

import React from 'react';
import PropTypes from 'prop-types';
import TableList from 'components/TableList';
import { getAccountInfoContent } from 'modules/content';
import { contentHandler } from 'content/handlers';
import Notification from './Notification';
import { Wrapper } from './KeyDetail.styles';

const { TableRow } = TableList;

const mountAddressKeyRow = (addressKeys) =>
  Object.keys(addressKeys).map((key) => {
    const { value, label } = getAccountInfoContent(addressKeys, key);

    return <TableRow 
      originalValue={addressKeys[key]}
      key={key} 
      value={value} 
      label={label} 
      copyEnabled={key==='key'}/>;
  });

const KeyDetail = ({ detailedKey }) => {
  const { key, keyType } = detailedKey;

  const enabledKeys = {
    key,
    keyType,
  };

  const statusMessage = contentHandler(detailedKey);
  const showMessage = statusMessage.text;

  return (
    <>
      {showMessage ? <Notification content={statusMessage} /> : <></>}
      <Wrapper data-testid="key-detail">{mountAddressKeyRow(enabledKeys)}</Wrapper>
    </>
  );
};

KeyDetail.propTypes = {
  detailedKey: PropTypes.object,
};

export default KeyDetail;

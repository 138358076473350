import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { appSetStep } from 'actions';

const useAppStep = (stepName) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(appSetStep({ currentStep: stepName }));
  }, [dispatch, stepName]);
};

export default useAppStep;

import React, { useEffect } from 'react';
import NewAddressKeyScreen from 'containers/NewAddressKey';
import { useDispatch, useSelector } from 'react-redux';
import { useTreeChanges } from 'hooks';
import { appSetStep, listAddressKey } from 'actions';
import { appSteps } from 'modules/appSteps';
import withAuthToken from 'components/withAuthToken';

import { STATUS } from 'constants';

const NewAddressKey = () => {
  const dispatch = useDispatch();
  const {
    addressKeys: { detailedKey },
    create,
  } = useSelector((state) => state.addressKey);

  const { changedFrom: createStatusChangedFrom } = useTreeChanges(
    create.status
  );

  useEffect(() => {
    if (
      createStatusChangedFrom('create.status', STATUS.RUNNING, STATUS.SUCCESS)
    ) {
      dispatch(listAddressKey());
    }
  }, [createStatusChangedFrom, detailedKey, dispatch]);

  useEffect(() => {
    dispatch(
      appSetStep({
        currentStep: appSteps.newKey.step,
      })
    );
  }, [dispatch]);

  return <NewAddressKeyScreen />;
};

export default withAuthToken(NewAddressKey);

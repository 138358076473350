/**
 * Constants
 * @module constants
 */

import { AppConstants } from './app';
import { ClaimConstants } from './claim';
import { AccountConstants } from './account';
import { AddressKeyConstants } from './addressKey';
import { CheckoutConstants } from './checkout';

export {
  AccountConstants,
  AppConstants,
  AddressKeyConstants,
  ClaimConstants,
  CheckoutConstants
};

export default {
  ...AccountConstants,
  ...AppConstants,
  ...AddressKeyConstants,
  ...ClaimConstants,
  ...CheckoutConstants,
};

export const FORM_TYPE = {
  SUGGESTED: 'suggested',
  CUSTOM: 'custom',
};

export const STATUS = {
  IDLE: 'idle',
  RUNNING: 'running',
  SUCCESS: 'success',
  READY: 'ready',
  ERROR: 'error',
};

export const ADDRESS_KEY_TYPE_EMAIL = {
  name: 'E-mail',
  canonical: 'EMAIL',
  mask: false,
};

export const ADDRESS_KEY_TYPE_PHONE = {
  name: 'Celular',
  canonical: 'PHONE',
  mask: true,
};

export const ADDRESS_KEY_TYPE_CPF = {
  name: 'CPF',
  canonical: 'CPF',
  mask: true,
};

export const ADDRESS_KEY_TYPE_CNPJ = {
  name: 'CNPJ',
  canonical: 'CNPJ',
  mask: true,
};

export const ADDRESS_KEY_TYPE_EVP = {
  name: 'Chave aleatória',
  canonical: 'EVP',
};

export const CLAIM_STATUS = {
  opened: 'OPENED',
  waiting: 'WAITING',
  confirmed: 'CONFIRMED',
  cancelled: 'CANCELLED',
  completed: 'COMPLETED',
};

export const CREATION_STATUSES = {
  opened: 'OPENED',
  created: 'CREATED',
  initiated: 'INITIATED',
  pendingClaim: 'PENDING_CLAIM',
  pendingValidation: 'PENDING_VALIDATION',
};

export const CLAIM_TYPE = {
  portability: 'PORTABILITY',
  claimOwnership: 'CLAIM_OWNERSHIP',
};

export const PARTICIPATION_TYPE = {
  donor: 'DONOR',
  claimant: 'CLAIMANT',
};

export const CLAIM_ACTIONS = {
  claim: 'claim',
  cancel: 'cancel',
  reject: 'reject',
  accept: 'accept',
  confirm: 'confirm',
  complete: 'complete',
  confirmed: 'confirmed',
};

export const PERSON_TYPE_PJ = {
  name: 'Pessoa Jurídica',
  canonical: 'PJ',
};

export const PERSON_TYPE_PF = {
  name: 'Pessoa Física',
  canonical: 'PF',
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';

import { LoadingButton } from '@sumup/circuit-ui';

import { BottomBar, PixScreen } from 'containers/Layout';
import withAuthToken from 'components/withAuthToken';

import { bankAccountContent } from 'content/bank-account';
import { STATUS } from 'constants';

import InfoCard, {
  SecondaryText,
  Text,
  TextContainer,
} from 'components/InfoCard';

import { appSteps } from 'modules/appSteps';

import {
  accountAddressSuggestionGet,
  accountCreate,
  appSetStep,
} from 'actions';

import { useBottomBar, useTreeChanges } from 'hooks';

const BankAccount = () => {
  const { origin } = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [bottombarRef, bottombarHeight] = useBottomBar();

  const {
    profile,
    flags: { userHasAccount },
    viewerAddress,
  } = useSelector((selectorState) => selectorState.account);

  const { changedFrom } = useTreeChanges(viewerAddress);

  useEffect(() => {
    dispatch(appSetStep({ currentStep: appSteps.bankAccount.step }));
  }, [dispatch]);

  useEffect(() => {
    if (changedFrom(
      'status', STATUS.RUNNING, STATUS.SUCCESS
    )) {
      const { addressSuggestion: address } = profile;
      const shipCard = false;
      const changePayout = false;
      const source = 'pix_webview_opt_in';

      dispatch(accountCreate({ shipCard, address, changePayout, source }));
    }
  }, [changedFrom, dispatch, profile]);

  const { icon, text } = bankAccountContent(origin);

  const cardTitle = 'Pix é no SumUp Bank!';
  const cardTextComponent = (
    <TextContainer>
      <Text>{text}</Text>
      <SecondaryText>
        Ao selecionar <strong>Abrir uma conta no SumUp Bank</strong>, você
        concorda com os{' '}
        <a
          href="https://sumup.com.br/termos/"
          rel="noopener noreferrer"
          target="_blank"
        >
          termos e condições
        </a>{' '}
        para abrir uma conta digital na SumUp.
      </SecondaryText>
    </TextContainer>
  );

  if (userHasAccount) {
    return <Redirect to={{ pathname: '/bank-account-success', state }} />;
  }

  const openAccountHandler = () => {
    setLoading(true);
    dispatch(accountAddressSuggestionGet());
  };

  return (
    <PixScreen style={{ paddingBottom: `${bottombarHeight}px` }}>
      <InfoCard
        iconComponent={icon}
        title={cardTitle}
        textComponent={cardTextComponent}
        showCardBox={true}
      />
      <BottomBar ref={bottombarRef}>
        <LoadingButton
          stretch={true}
          variant="primary"
          isLoading={loading}
          onClick={openAccountHandler}
        >
          Abrir uma conta no SumUp Bank
        </LoadingButton>
      </BottomBar>
    </PixScreen>
  );
};

export default withAuthToken(BankAccount);

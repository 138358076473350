import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTreeChanges } from 'hooks';
import CoverScreen from 'containers/CoverScreen';
import { createClaim, rejectClaim, acceptClaim } from 'actions';
import { STATUS, ADDRESS_KEY_TYPE_PHONE } from 'constants';
import { claimResponseHandling } from 'content/claim';
import Processing from 'containers/Processing';

const Portability = () => {
  const { action } = useParams();
  const dispatch = useDispatch();
  const [coverState, setCoverState] = useState(false);
  const [responseContent, setResponseContent] = useState({});
  const { claimCreation, claimAcceptance, claimRejection } = useSelector(
    (state) => state.claim
  );

  const {
    keyType,
    keyValue,
    addressKeys: {
      detailedKey: { key: detailedKey },
    },
    create
  } = useSelector((state) => state.addressKey);

  const { changedFrom: createClaimChangedFrom } = useTreeChanges(
    claimCreation.status
  );
  const { changedFrom: acceptClaimChangedFrom } = useTreeChanges(
    claimAcceptance.status
  );
  const { changedFrom: rejectClaimChangedFrom } = useTreeChanges(
    claimRejection.status
  );

  useEffect(() => {
    if (
      acceptClaimChangedFrom(
        'claimAcceptance.status',
        STATUS.RUNNING,
        STATUS.SUCCESS
      )
    ) {
      const content = claimResponseHandling('acceptPortabilitySuccess')();
      setResponseContent(content);
      setCoverState(true);
    }
  }, [acceptClaimChangedFrom, action]);

  useEffect(() => {
    if (
      acceptClaimChangedFrom(
        'claimAcceptance.status',
        STATUS.RUNNING,
        STATUS.ERROR
      )
    ) {
      const content = claimResponseHandling('acceptPortabilityError')();
      setResponseContent(content);
      setCoverState(true);
    }
  }, [acceptClaimChangedFrom, action]);

  useEffect(() => {
    const addressKeyParticipantName = create?.message?.options?.accountParticipantName;

    if (
      createClaimChangedFrom(
        'claimCreation.status',
        STATUS.RUNNING,
        STATUS.SUCCESS
      )
    ) {
      const content = claimResponseHandling('claimPortabilitySuccess')(addressKeyParticipantName);
      setResponseContent(content);
      setCoverState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimCreation.status, create]);

  useEffect(() => {
    if (
      createClaimChangedFrom(
        'claimCreation.status',
        STATUS.RUNNING,
        STATUS.ERROR
      )
    ) {
      const content = claimResponseHandling('claimPortabilityError')();
      setResponseContent(content);
      setCoverState(true);
    }
  }, [createClaimChangedFrom, setResponseContent, setCoverState, action]);

  useEffect(() => {
    if (
      rejectClaimChangedFrom(
        'claimRejection.status',
        STATUS.RUNNING,
        STATUS.ERROR
      ) &&
      action === 'reject'
    ) {
      const content = claimResponseHandling('rejectPortabilitySuccess')();
      setResponseContent(content);
      setCoverState(true);
    }
  }, [rejectClaimChangedFrom, setResponseContent, setCoverState, action]);

  useEffect(() => {
    if (
      rejectClaimChangedFrom(
        'claimRejection.status',
        STATUS.RUNNING,
        STATUS.ERROR
      ) &&
      action === 'reject'
    ) {
      const content = claimResponseHandling('rejectPortabilityError')();
      setResponseContent(content);
      setCoverState(true);
    }
  }, [rejectClaimChangedFrom, setResponseContent, setCoverState, action]);

  useEffect(() => {
    if (claimAcceptance.status === STATUS.IDLE && action === 'accept') {
      dispatch(
        acceptClaim({
          key: detailedKey,
          confirmation_reason: 'OWNER_REQUEST',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (claimRejection.status === STATUS.IDLE && action === 'reject') {
      dispatch(
        rejectClaim({
          key: detailedKey,
          cancelation_reason: 'OWNER_REQUEST',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const isPhone = keyType.canonical === ADDRESS_KEY_TYPE_PHONE.canonical;
    const hasCountryCode = keyValue?.value?.includes('+55');
    const value =
      isPhone && !hasCountryCode ? `+55${keyValue?.value}` : keyValue?.value;
    if (claimCreation.status === STATUS.IDLE && action === 'claim') {
      dispatch(
        createClaim({
          key: value,
          keyType: keyType.canonical,
          claimType: 'PORTABILITY',
          participationType: 'CLAIMANT',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      {coverState ? (
        <CoverScreen
          data-testid="portability"
          content={responseContent}
          active={coverState}
        />
      ) : (
        <Processing />
      )}
    </>
  );
};

export default Portability;

import * as Sentry from '@sentry/browser';
import createSagaMiddleware from 'redux-saga';
import createSentryMiddleware from 'redux-sentry-middleware';
import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import config from 'config';
import reducers from 'reducers';
import rootSagas from 'sagas';

import { history } from 'modules/history';

Sentry.init(config.sentry);

const { NODE_ENV } = process.env;

const sagaMiddleware = createSagaMiddleware();
const routersMiddleware = routerMiddleware(history);
const sentryMiddleware = createSentryMiddleware(Sentry, {});

const middlewares = [
  routersMiddleware,
  sagaMiddleware,
];

/* istanbul ignore next */
if (NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger');

  middlewares.push(createLogger({
    collapsed: true,
    predicate: () => !window.HIDE_ACTIONS,
  }));
}

const rootReducers = combineReducers({
  ...reducers,
  router: connectRouter(history),
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

middlewares.push(sentryMiddleware);

const configStore = (preloadedState = {}) => {
  const store = createStore(
    rootReducers,
    preloadedState,
    composeEnhancer(applyMiddleware(...middlewares)),
  );

  sagaMiddleware.run(rootSagas);

  return store;
};

const store = configStore();

global.store = store;

export { store };

import { all, call, put, takeLatest } from 'redux-saga/effects';

import { AccountConstants } from 'constants';

import { client, getRequestOptions } from 'modules/resources';
import { graphql } from 'modules/graphql';
import { parseResponseError } from 'modules/client';
import { reportBug } from 'modules/sentry';
import { parseErrorObject } from 'modules/helpers';

import config from 'config';

export function* createAccount({ payload: { input } }) {
  try {
    const { data, errors } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield graphql.mutations.createAccount({ input })),
      method: 'POST',
    });

    const { successful, result, messages } =
      data?.createMerchantBankAccount || {};
    const viewer = result?.viewer || {};

    if (successful && viewer) {
      yield put({
        type: AccountConstants.ACCOUNT_CREATE_SUCCESS,
        payload: { ...viewer },
      });

      // eslint-disable-next-line no-unused-expressions
      window?.dataLayer?.push({
        event: 'create account',
        category: 'conversion',
        action: 'create account',
        label: 'success',
      });
    }
    else {
      yield put({
        type: AccountConstants.ACCOUNT_CREATE_FAILURE,
        payload: { message: messages },
      });

      reportBug(AccountConstants.ACCOUNT_CREATE_FAILURE, {
        level: 'error',
        message: messages,
        errorsResponse: JSON.stringify(errors),
      });

      // eslint-disable-next-line no-unused-expressions
      window?.dataLayer?.push({
        event: 'create account',
        category: 'conversion',
        action: 'create account',
        label: 'failure',
      });
    }
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AccountConstants.ACCOUNT_CREATE_FAILURE,
      payload: { message: error, status },
    });

    reportBug(AccountConstants.ACCOUNT_CREATE_FAILURE, {
      level: 'error',
      message: error,
    });
  }
}

export function* getViewer() {
  try {
    let getViewerFunc = graphql.queries.getViewer
    if(config.isSumUpIo) {
      getViewerFunc = graphql.queries.getIOViewer
    }
    const { data, errors } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield getViewerFunc()),
      method: 'POST',
    });

    const viewer = data?.viewer || {};
    const { merchantData } = viewer;

    if (merchantData || config.isSumUpIo) {
      yield put({
        type: AccountConstants.ACCOUNT_VIEWER_SUCCESS,
        payload: { ...viewer },
      });
    } else {
      const errorMessage = errors || "Cannot find merchant data";
      const parsedError = parseErrorObject(errorMessage);
      const error = new Error(parsedError);
      error.response = errors;
      error.status = 400;
      throw error;
    }
  }
  catch (err) {
    const { message, status } = err;
    const errorResponse = err.response || message;
    const error = parseResponseError(errorResponse, status);

    yield put({
      type: AccountConstants.ACCOUNT_VIEWER_FAILURE,
      payload: { message: error, status },
    });

    reportBug(AccountConstants.ACCOUNT_VIEWER_FAILURE, {
      level: 'error',
      message: error,
    });
  }
}

export function* getAddressSuggestion() {
  try {
    const { data } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield graphql.queries.getAddressSuggestionViewer()),
      method: 'POST',
    });

    const viewer = data?.viewer || {};

    yield put({
      type: AccountConstants.ACCOUNT_ADDRESS_SUGGESTION_SUCCESS,
      payload: { ...viewer },
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AccountConstants.ACCOUNT_ADDRESS_SUGGESTION_FAILURE,
      payload: { message: error, status },
    });

    reportBug(AccountConstants.ACCOUNT_ADDRESS_SUGGESTION_FAILURE, {
      level: 'error',
      message: error,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(AccountConstants.ACCOUNT_ADDRESS_SUGGESTION_REQUEST, getAddressSuggestion),
    takeLatest(AccountConstants.ACCOUNT_VIEWER_REQUEST, getViewer),
    takeLatest(AccountConstants.ACCOUNT_CREATE_REQUEST, createAccount),
  ]);
}

import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getQueryParam } from 'modules/helpers';
import { useDispatch } from 'react-redux';
import { isPixEnabled } from 'actions';

const withRedirect = (Component) => {
  const RedirectHOC = (props) => {

    const dispatch = useDispatch();
    const { search: pixEnabled } = useLocation();
    const pixEnabledParam = getQueryParam(pixEnabled, 'pixEnabled');
    useEffect(() => {
      dispatch(isPixEnabled(pixEnabledParam));
    }, [dispatch, pixEnabledParam]);

    const { children } = props;
    const history = useHistory();
    const params = new URL(document.location).searchParams;
    const redirectUrl = params.get('redirectTo');

    if(redirectUrl) {
      history.push(redirectUrl);
    }

    return <Component {...props}>{children}</Component>;
  
  };
  return RedirectHOC;
};


export default withRedirect;

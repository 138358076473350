import is from 'is-lite';

/**
 * Check if the input is empty
 * @param {*} input
 * @returns {boolean}
 */
export const isEmpty = (input) => {
  if (is.plainObject(input)) {
    return !Object.keys(input).length;
  }

  if (is.array(input) || is.string(input)) {
    return !input.length;
  }

  return true;
};

/**
 * Creates an object with an mirrored key / values
 * @param {object} obj
 * @returns {object} {}
 */
export const keyMirror = (obj) => {
  const mirrored = {};
  let key;

  if (!(obj instanceof Object && !Array.isArray(obj))) {
    throw new Error('keyMirror(...): Argument must be an object.');
  }

  for (key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      mirrored[key] = key;
    }
  }

  return mirrored;
};

/**
 * Get current environment
 * @returns {string}
 */
export const getEnv = () => {
  const locationURL = window.location.host.split('.')[1];

  if (locationURL !== undefined) {
    return locationURL;
  }

  return 'dev';
};

/**
 * Parses the error object to provide friendly messeges to the user
 * @param {object} data
 * @returns {string}
 */
export function parseErrorObject(data) {
  let output = '';

  if (!is.plainObject(data)) {
    return output;
  }

  try {
    if (Array.isArray(data.errors)) {
      output = data.errors[0].message;
    }
    else if (is.plainObject(data.error)) {
      output = data.error.message;
    }
    else if (data.error && data.error_description) {
      output = data.error_description;
    }
  }
  catch (error) {
    console.error(error); //eslint-disable-line no-console
  }

  return output;
}

/**
 * Remove format from a given number.
 * @param {string} value
 * @returns {string}
 */
function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

/**
 * Format a string in a zip code shape.
 * @param {string} value
 * @returns {string}
 */
export function formatZip(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 6) {
    return `${clearValue.slice(0, 5)}-${clearValue.slice(5, 8)}`;
  }

  return clearValue;
}

/**
 * Get Address State from Address.
 * @param {string} addressState
 * @returns {string}
 */
export const getAddressState = (addressState) => {
  if (!addressState) {
    return '';
  }

  return addressState.split(' - ')[1];
};

/**
 * Clean null attributes on a object
 * @param {object} obj
 * @returns {object}
 */

export function cleanObj({ ...obj }) {
  const propNames = Object.getOwnPropertyNames(obj);
  for (let i = 0; i < propNames.length; i += 1) {
    const propName = propNames[i];
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }

  return obj;
}

/**
 * Trim all string attributes on a object
 * @param {object} obj
 * @returns {object}
 */
export function trimAttributes({ ...obj }) {
  const propNames = Object.getOwnPropertyNames(obj);
  for (let i = 0; i < propNames.length; i += 1) {
    const propName = propNames[i];
    if (typeof obj[propName] === 'string') {
      obj[propName] = obj[propName].trim();
    }
  }

  return obj;
}

export function priceFormatter(value) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
}

export function addressFormatter(address) {
  const formattedAddress = trimAttributes(cleanObj(address));
  formattedAddress.zipcode = clearNumber(address.zipcode);

  return formattedAddress;
}

export function trimText(text, length = 20) {
  return `${text.slice(0, length)}...`;
}

export const getQueryParam = (params, param) => {
  const paramsObj = new URLSearchParams(params);
  return paramsObj.get(param);
};

export const compose = (...fns) => (x) => fns.reduceRight((v, f) => f(v), x);

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import OnboardingKeys from 'containers/OnboardingKeys';
import { appSteps } from 'modules/appSteps';

import { appSetStep } from 'actions';

const OnboardingKeysScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appSetStep({ currentStep: appSteps.loading.step }));
  }, [dispatch]);

  return <OnboardingKeys />;
};

export default OnboardingKeysScreen;

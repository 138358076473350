import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Cross } from '@sumup/icons';
import { BottomBar } from 'containers/Layout';

export const IconWrapper = styled('div')(() => css`
    width: 30px;
    height: 30px;
  `);

export const StyledBottomBar = styled(BottomBar)(({ theme }) => css`

  button {
    margin-bottom: ${theme.spacings.mega};

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`);

export const StyledCross = styled(Cross)(({ theme }) => css`
    color: ${theme.colors.p700};
  `);

export const CoverScreenWrapper = styled('div')(({ theme }) => css`
    width: 100%;
    height: 100vh;
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    padding: ${theme.spacings.giga} ${theme.spacings.mega};
    z-index: 2;
  `);

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { CheckoutConstants } from 'constants';
import { client, getRequestOptions } from 'modules/resources';
import { graphql } from 'modules/graphql';
import { parseResponseError } from 'modules/client';
import { reportBug } from 'modules/sentry';

export function* checkQRPayment(paymentData) {
  try {
    const { payload: { input } } = paymentData;

    const { data } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield graphql.mutations.instantPaymentVerification(input)),
      method: 'POST',
    });

    const { successful, result } = data?.instantPaymentVerification || {};

    if (successful && result) {
      const { status } = result;
      if (status === 'COMPLETED') {
        yield put({
          type: CheckoutConstants.CHECKOUT_QRPAYMENT_VERIFICATION_SUCCESS,
          payload: { ...result },
        });
      } else {
        yield checkQRPayment(paymentData);
      }
    }
  } catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: CheckoutConstants.CHECKOUT_QRPAYMENT_VERIFICATION_FAILURE,
      payload: { message: error, status },
    });

    reportBug(CheckoutConstants.CHECKOUT_QRPAYMENT_VERIFICATION_FAILURE, {
      level: 'error',
      message: error,
    });
  }
}

export function* generateQR({ payload: { input } }) {
  try {
    const { data, errors } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield graphql.mutations.generateQR({ input })),
      method: 'POST',
    });

    const { successful, result, messages } = data?.InitiateInstantPayment || {};


    if (successful && result) {
      yield put({
        type: CheckoutConstants.CHECKOUT_GENERATE_QR_SUCCESS,
        payload: { ...result },
      });

      // eslint-disable-next-line no-unused-expressions
      window?.dataLayer?.push({
        event: 'checkout',
        category: 'payment',
        action: 'generate qrcode',
        label: 'success',
      });
    }
    else {
      yield put({
        type: CheckoutConstants.CHECKOUT_GENERATE_QR_FAILURE,
        payload: { message: messages },
      });

      reportBug(CheckoutConstants.CHECKOUT_GENERATE_QR_FAILURE, {
        level: 'error',
        message: messages,
        errorsResponse: JSON.stringify(errors),
      });

      // eslint-disable-next-line no-unused-expressions
      window?.dataLayer?.push({
        event: 'checkout',
        category: 'payment',
        action: 'generate qrcode',
        label: 'failure',
      });
    }
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: CheckoutConstants.CHECKOUT_GENERATE_QR_FAILURE,
      payload: { message: error, status },
    });

    reportBug(CheckoutConstants.CHECKOUT_GENERATE_QR_FAILURE, {
      level: 'error',
      message: error,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(CheckoutConstants.CHECKOUT_GENERATE_QR_REQUEST, generateQR),
    takeLatest(CheckoutConstants.CHECKOUT_QRPAYMENT_VERIFICATION_REQUEST, checkQRPayment),
  ]);
}

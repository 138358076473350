import React from 'react';
import PropTypes from 'prop-types';
import { useBottomBar } from 'hooks';
import { useHistory } from 'react-router-dom';
import { Heading, Text, Button } from '@sumup/circuit-ui';
import { BottomBar } from 'containers/Layout';
import {
  FullWidthWrapper,
  CenteredContainer,
  StyledCrossIcon,
  StyledCheckIcon,
} from 'containers/Checkout';

const bottomBarStyle = {
  backgroundColor: '#39B54D',
};

const CheckoutSuccess = ({ redirectTo, onClose }) => {
  const history = useHistory();
  const [bottombarRef] = useBottomBar();

  const onClickHandler = () => history.replace(redirectTo);
  return (
    <FullWidthWrapper>
      <StyledCrossIcon onClick={onClose} />
      <CenteredContainer>
        <Text className="amount">Um item</Text>
        <Text className="price" bold>
          R$ 240,00
        </Text>
        <StyledCheckIcon className="icon" />
        <Heading className="heading" size="giga" as="strong">
          Pagamento recebido com sucesso
        </Heading>
        <Text className="description">
          O comprovante desta transação está disponível no seu histórico de
          transações no aplicativo SumUp Bank
        </Text>
      </CenteredContainer>
      <BottomBar ref={bottombarRef} css={bottomBarStyle}>
        <Button onClick={onClickHandler} stretch={true} color="white">
          Finalizar
        </Button>
      </BottomBar>
    </FullWidthWrapper>
  );
};

CheckoutSuccess.propTypes = {
  onClose: PropTypes.func.isRequired,
  redirectTo: PropTypes.string.isRequired,
};

export default CheckoutSuccess;

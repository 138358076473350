import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTreeChanges, useAppStep } from 'hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { CheckoutScreen, Container } from 'containers/Checkout';
import { STATUS } from 'constants';
import { executeNativeBack } from 'modules/dom';
import Processing from 'containers/Processing';
import { appSteps } from 'modules/appSteps';
import CheckoutError from '../../containers/Checkout/CheckoutError';
import { clearCheckoutGenerateQrResponse } from 'actions';

const NewCheckout = () => {
  const dispatch = useDispatch();
  useAppStep(appSteps.newCheckout.step);

  const history = useHistory();
  const {
    state: { checkoutKey },
  } = useLocation();
  const { key, keyType } = checkoutKey;

  const [hasError, setHasError] = useState(false);
  const [isProcessing, setIsProcessing] = useState();
  const { create } = useSelector((state) => state.checkout);

  const { changedFrom: createStatusChangedFrom } = useTreeChanges(create.status);

  useEffect(() => {
    if (
      createStatusChangedFrom(
        'create.status', STATUS.IDLE, STATUS.RUNNING
      )
    ) {
      setIsProcessing(true);
      setHasError(false);
    }
  }, [createStatusChangedFrom, create.status]);

  useEffect(() => {
    if (
      createStatusChangedFrom(
        'create.status', STATUS.RUNNING, STATUS.ERROR
      )
    ) {
      setIsProcessing(false);
      setHasError(true);
    }
  }, [createStatusChangedFrom, create.status]);

  useEffect(() => {
    if (
      createStatusChangedFrom(
        'create.status', STATUS.RUNNING, STATUS.SUCCESS
      )
    ) {
      setIsProcessing(false);
      history.replace('/qrcode-payment', { checkoutKey });
    }
  }, [createStatusChangedFrom, create.status, history, checkoutKey]);

  const onRetryHandler = () => {
    dispatch(clearCheckoutGenerateQrResponse());
    setHasError(false);
  }
  const onCancelHandler = () => executeNativeBack();

  return isProcessing ? (
    <Processing message="Criando seu QR Code, aguarde alguns instantes..." />
  ) : (
    <>
      <Container>
        {hasError ? (
          <CheckoutError
            onRetry={onRetryHandler}
            onClose={onCancelHandler}
            onCancel={onCancelHandler}
          />
        ) : (
          <CheckoutScreen
            addressKey={key}
            addressKeyType={keyType}
          />
        )}
      </Container>
    </>
  );
};

export default NewCheckout;

import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const StyledContainer = styled('div')(({ theme }) => css`
    height: 100%;
    width: 100%;
    min-height: 100vh;
    padding: ${theme.spacings.peta} ${theme.spacings.mega} ${theme.spacings.exa};
    display: flex;
    align-items: stretch;
  `);

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Processing from 'containers/Processing';
import { BottomBar } from 'containers/Layout';
import { mountSelectedTypeSettings } from 'components/MaskedInput/helpers';
import InputMask from 'react-input-mask';
import { setKeyValue, associateKey, isProcessing } from 'actions';
import CoverScreen from 'containers/CoverScreen';

import {
  ADDRESS_KEY_TYPE_PHONE,
  ADDRESS_KEY_TYPE_EMAIL,
  ADDRESS_KEY_TYPE_EVP,
  ADDRESS_KEY_TYPE_CPF,
  ADDRESS_KEY_TYPE_CNPJ,
} from 'constants';
import { mountFormContent, createKeyResponseHandling } from 'modules/content';

import {
  CardSpacing,
  StyledButton,
  StyledInput,
  Title,
  SubTitle,
} from './NewAddressKey.styles';

const NewAddressKey = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [coverState, setCoverState] = useState(false);
  const [responseContent, setResponseContent] = useState({});
  const [requestProcessing, setRequestProcessing] = useState(false);
  const { flags, profile } = useSelector((state) => state.account);

  const { keyType, keyValue, create, payload } = useSelector(
    (state) => state.addressKey
  );

  const isEvp = keyType?.canonical === ADDRESS_KEY_TYPE_EVP.canonical;
  const isNationalId =
    keyType?.canonical === ADDRESS_KEY_TYPE_CPF.canonical ||
    keyType?.canonical === ADDRESS_KEY_TYPE_CNPJ.canonical;
  const { settings = {} } = mountSelectedTypeSettings(keyType);
  const [value, setValue] = useState('');
  const [invalid, setInvalid] = useState(false);
  const [invalidHint, setInvalidHint] = useState('');

  const hasErrorMessage = Object.keys(create.message || {}).length;
  const hasToken = payload?.result?.creationDate === null;
  const hasResult = Object.keys(payload || {}).length;

  const { primary, secondary } = mountFormContent(keyType);

  const onInputChange = ({ target: { value: inputValue } }) => {
    setInvalid(false);
    setInvalidHint('');
    setValue(inputValue);
  };

  const validate = () => {
    const unmaskedValue = settings.unmask(value);

    const nationalIdMatches = unmaskedValue === profile.nationalId;
    const valueMatchesValidation = settings.validator(unmaskedValue);

    return (isNationalId && nationalIdMatches) || valueMatchesValidation;
  };

  const onAssociateKeyClick = () => {
    if (!isEvp && !validate()) {
      setInvalid(true);
      setInvalidHint('Valor inválido');
      return;
    }

    setRequestProcessing(true);
    dispatch(isProcessing(true));

    dispatch(
      setKeyValue({
        value: settings.unmask(value),
        maskedValue: value,
      })
    );

    dispatch(
      associateKey({
        key: isEvp ? '' : settings.unmask(value).toLowerCase(),
        keyType: keyType.canonical,
      })
    );
  };

  useEffect(() => {
    if (hasErrorMessage && !isEvp) {
      setRequestProcessing(false);
      dispatch(isProcessing(false));
      const code = create?.message.code;
      const addressKeyParticipantName =
        create?.message?.options?.accountParticipantName;
      const content = createKeyResponseHandling(code)(
        keyValue,
        keyType,
        addressKeyParticipantName
      );
      setResponseContent(content);
      setCoverState(true);
    }

    if (hasErrorMessage && isEvp) {
      setRequestProcessing(false);
      dispatch(isProcessing(false));
      const content = createKeyResponseHandling('evp_error')(keyValue, keyType);
      setResponseContent(content);
      setCoverState(true);
    }
  }, [create, keyType, keyValue, hasErrorMessage, dispatch, isEvp]);

  useEffect(() => {
    if (hasResult && !hasToken && !isEvp) {
      setRequestProcessing(false);
      dispatch(isProcessing(false));
      const content = createKeyResponseHandling('successful')(
        keyValue,
        keyType
      );
      setResponseContent(content);
      setCoverState(true);
    }

    if (hasResult && isEvp) {
      setRequestProcessing(false);
      dispatch(isProcessing(false));
      const content = createKeyResponseHandling('evp_success')(
        payload.result.key
      );
      setResponseContent(content);
      setCoverState(true);
    }
  }, [keyType, keyValue, hasResult, hasToken, dispatch, isEvp, payload]);

  useEffect(() => {
    if (hasResult && hasToken) {
      dispatch(isProcessing(false));
      history.push({
        pathname: '/new-key/validation',
        state: { hideDeleteKeyButton: true },
      });
    }
  }, [dispatch, history, hasResult, hasToken]);

  const showKeySelector = !requestProcessing && flags.userHasAccount;

  return (
    <>
      <CoverScreen content={responseContent} active={coverState} />
      {requestProcessing &&
        keyType.canonical === ADDRESS_KEY_TYPE_EMAIL.canonical && (
          <Processing message="Enviando código de segurança para seu e-mail." />
        )}
      {requestProcessing &&
        keyType.canonical === ADDRESS_KEY_TYPE_PHONE.canonical && (
          <Processing message="Enviando código de segurança para seu telefone celular." />
        )}
      {requestProcessing &&
        ![
          ADDRESS_KEY_TYPE_EMAIL.canonical,
          ADDRESS_KEY_TYPE_PHONE.canonical,
        ].includes(keyType.canonical) && (
          <Processing
            message={`Cadastrando ${keyType?.name}, aguarde alguns instantes.`}
          />
        )}
      {showKeySelector && (
        <>
          <CardSpacing data-testid="card-spacing">
            <Title>{primary}</Title>
            <SubTitle>{secondary}</SubTitle>
            {keyType && !isEvp && (
              <InputMask
                mask={settings.mask}
                value={value}
                type={settings.type}
                onChange={onInputChange}
              >
                <StyledInput
                  value={value}
                  type={settings.type}
                  onChange={onInputChange}
                  invalid={invalid}
                  validationHint={invalidHint}
                  hideLabel={true}
                  placeholder={settings.placeholder}
                />
              </InputMask>
            )}
          </CardSpacing>
          {keyType && (
            <BottomBar>
              <StyledButton
                spacing="mega"
                onClick={onAssociateKeyClick}
                variant="primary"
              >
                {keyType.canonical === ADDRESS_KEY_TYPE_EVP.canonical ? (
                  <span>Confirmar</span>
                ) : (
                  <span>Continuar</span>
                )}
              </StyledButton>
            </BottomBar>
          )}
        </>
      )}
    </>
  );
};

export default NewAddressKey;

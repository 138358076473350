import { keyMirror } from 'modules/helpers';

export const CheckoutConstants = keyMirror({
  CHECKOUT_QRPAYMENT_VERIFICATION_REQUEST: undefined,
  CHECKOUT_QRPAYMENT_VERIFICATION_SUCCESS: undefined,
  CHECKOUT_QRPAYMENT_VERIFICATION_FAILURE: undefined,
  CHECKOUT_GENERATE_QR_REQUEST: undefined,
  CHECKOUT_GENERATE_QR_SUCCESS: undefined,
  CHECKOUT_GENERATE_QR_FAILURE: undefined,
  CLEAR_CHECKOUT_GENERATE_QR_RESPONSE: undefined,
  CHECKOUT_KEY_MANAGEMENT_FLOW_PUSH: undefined,
  CHECKOUT_KEY_MANAGEMENT_FLOW_POP: undefined,
});

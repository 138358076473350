import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const Container = styled('div')(({ theme }) => css`
    background-color: #fff;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    padding: 0 ${theme.spacings.giga};
  `);

export const SharedInfoImageWrapper = styled('div')(({ theme }) => css`
    padding: ${theme.spacings.peta} 0 ${theme.spacings.giga};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `);

export const Text = styled('div')(({ theme }) => css`
    text-align: left;
    margin-bottom: ${theme.spacings.mega};
    font-size: 16px;
  `);

export const BorderContainer = styled('div')(({ theme }) => css`
    padding: ${theme.spacings.mega};
    text-align: left;
    background: #ffffff;
    border: 1px solid #d8dde1;
    box-sizing: border-box;
    border-radius: 8px;
    ul{
        list-style-position: outside;
        margin-left: ${theme.spacings.mega};
    }
    li {
      margin-bottom: 5px;
    }
  `
);

export const STATUSES_CONTENT = {
  created: {},
  blockedByClaim: {
    type: 'danger',
    label: 'Chave bloqueada',
    title: 'Sua chave foi bloqueada por motivo de segurança',
    text:
      'Você tem uma reivindicação de chave Pix pendente há mais de 7 dias. Por isso, a sua chave foi bloqueada. Para recuperar sua chave, você só precisa cancelar a reivindicação e fazer o cadastro novamente. Mas atenção, após 14 dias a chave será migrada automaticamente.',
    actions: [
      {
        label: 'Cancelar reivindicação',
        type: 'primary',
        to: '/ownership/confirm',
      },
    ],
  },
  portabilityClaimed: {
    type: 'neutral',
    label: 'Aguardando portabilidade',
    text:
      'Caso a portabilidade não seja aceita, ela será automaticamente rejeitada 7 dias após a data da solicitação.',
    actions: [
      {
        label: 'Fechar e aguardar',
        type: 'primary',
        to: '/keys-management',
      },
    ],
  },
  ownershipClaimed: {
    type: 'neutral',
    label: 'Aguardando reivindicação',
    text:
      'Caso a reivindicação não seja aceita, ela será automaticamente rejeitada 7 dias após a data da solicitação.',
    actions: [
      {
        label: 'Cancelar reivindicação de posse',
        type: 'primary',
        to: '/ownership/cancel',
      },
    ],
  },
  ownershipReceived: {
    type: 'warning',
    label: 'Reivindicação de posse solicitada',
    actions: [
      {
        label: 'Rejeitar reivindicação de posse',
        type: 'primary',
        to: '/ownership/reject',
      },
      {
        label: 'Aceitar reivindicação de posse',
        type: 'secondary',
        to: '/ownership/accept',
      },
    ],
  },
  portabilityReceived: {
    type: 'warning',
    label: 'Portabilidade recebida',
    text: 'Caso a portabilidade não seja aceita, ela será automaticamente rejeitada 7 dias após a data da solicitação.',
    actions: [
      {
        label: 'Aceitar portabilidade',
        type: 'primary',
        to: '/portability/accept',
      },
      {
        label: 'Rejeitar portabilidade',
        type: 'secondary',
        to: '/portability/reject',
      },
    ],
  },
  portabilityRejected: {
    type: 'danger',
    label: 'Portabilidade rejeitada',
    actions: [],
  },
  ownershipRejected: {
    type: 'danger',
    label: 'Reivindicação rejeitada',
    actions: [],
  },
  portabilityCancelled: {
    type: 'neutral',
    label: 'Portabilidade cancelada',
    actions: [],
  },
  pendingValidation: {
    type: 'neutral',
    label: 'Chave pendente',
    text: 'Esta chave ainda não foi confirmada.',
    actions: [],
  },
  ownershipConfirmed: {
    type: 'success',
    label: 'Concluir reivindicação',
    title: 'Reivindicação de chave Pix aceita com sucesso',
    text: 'Para trazer a chave Pix para a SumUp, é só concluir a reivindicação. Mas atenção, você tem até 30 dias desde o início do processo para decidir, ou a chave ficará disponível para novos cadastros',
    actions: [
      {
        label: 'Concluir reivindicação',
        type: 'primary',
        to: '/ownership/complete',
      },
      {
        label: 'Cancelar reivindicação',
        type: 'secondary',
        to: '/ownership/cancel',
      },
    ],
  },
};

import React from 'react';
import PropTypes from 'prop-types';
import { StyledContainer } from './Container.styles';

const Container = ({ children }) => <StyledContainer data-testid="container">{children}</StyledContainer>;

Container.propTypes = {
  children: PropTypes.object,
};

export default Container;

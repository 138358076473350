import { all, call, put, takeLatest } from 'redux-saga/effects';
import { client, getRequestOptions } from 'modules/resources';
import { graphql } from 'modules/graphql';
import { parseResponseError } from 'modules/client';
import { AddressKeyConstants } from 'constants';
import { reportBug } from 'modules/sentry';

export function* getAddressKeys() {
  try {
    const { data } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield graphql.queries.getPixAddressKeys()),
      method: 'POST',
    });

    const addressKeysList = data?.addressKeys || {};

    yield put({
      type: AddressKeyConstants.ADDRESS_KEY_LIST_SUCCESS,
      payload: addressKeysList,
    });
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AddressKeyConstants.ADDRESS_KEY_LIST_FAILURE,
      payload: { message: error, status },
    });

    reportBug(AddressKeyConstants.ADDRESS_KEY_LIST_FAILURE, {
      level: 'error',
      message: error,
    });
  }
}

export function* validatePin({ payload: { input } }) {
  try {
    const { data } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield graphql.mutations.validateToken({ input })),
      method: 'POST',
    });

    const { successful, result, messages } = data?.validateToken || {};

    if (!successful) {
      yield put({
        type: AddressKeyConstants.ADDRESS_KEY_PIN_VALIDATION_FAILURE,
        payload: { message: messages[0] },
      });
    }

    if (successful && result) {
      yield put({
        type: AddressKeyConstants.ADDRESS_KEY_PIN_VALIDATION_SUCCESS,
        payload: { result, successful },
      });
    }
  }
  catch (error) {
    yield put({
      type: AddressKeyConstants.ADDRESS_KEY_PIN_VALIDATION_FAILURE,
      payload: { error, requestError: true },
    });
  }
}

export function* createAddressKey({ payload: { input } }) {
  try {
    const { data } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield graphql.mutations.createAddressKey({ input })),
      method: 'POST',
    });

    const { successful, result, messages } = data?.createAddressKey || {};

    if (!successful) {
      yield put({
        type: AddressKeyConstants.ADDRESS_KEY_CREATE_FAILURE,
        payload: { message: messages[0] },
      });
    }

    if (successful && result) {
      yield put({
        type: AddressKeyConstants.ADDRESS_KEY_CREATE_SUCCESS,
        payload: { result, successful },
      });
    }
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AddressKeyConstants.ACCOUNT_VIEWER_FAILURE,
      payload: { message: error, status },
    });

    reportBug(AddressKeyConstants.ACCOUNT_VIEWER_FAILURE, {
      level: 'error',
      message: error,
    });
  }
}

export function* deleteAddressKey({ payload: { input } }) {
  try {
    const { data } = yield call(client, {
      ...(yield getRequestOptions()),
      ...(yield graphql.mutations.deleteAddressKey(input)),
      method: 'POST',
    });
    const { successful, result, messages } = data?.deleteAddressKey || {};

    if (!successful) {
      yield put({
        type: AddressKeyConstants.ADDRESS_KEY_DELETE_FAILURE,
        payload: { message: messages[0] },
      });
    }

    if (successful && result) {
      yield put({
        type: AddressKeyConstants.ADDRESS_KEY_DELETE_SUCCESS,
        payload: { result, successful },
      });
    }
  }
  catch ({ message, status }) {
    const error = parseResponseError(message, status);

    yield put({
      type: AddressKeyConstants.ADDRESS_KEY_DELETE_FAILURE,
      payload: { message: error, status },
    });

    reportBug(AddressKeyConstants.ADDRESS_KEY_DELETE_FAILURE, {
      level: 'error',
      message: error,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(AddressKeyConstants.ADDRESS_KEY_LIST_REQUEST, getAddressKeys),
    takeLatest(AddressKeyConstants.ADDRESS_KEY_PIN_VALIDATION_REQUEST,
      validatePin),
    takeLatest(AddressKeyConstants.ADDRESS_KEY_CREATE_REQUEST,
      createAddressKey),
    takeLatest(AddressKeyConstants.ADDRESS_KEY_DELETE_REQUEST,
      deleteAddressKey),
  ]);
}

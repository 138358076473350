import produce from 'immer';
import { handleActions } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';
import { AppConstants, STATUS } from 'constants';
import { getScreenSize } from 'modules/dom';
import { getEnv } from 'modules/helpers';
import packageJson from '../../package.json';

export const appState = {
    alerts: [],
    authToken: '',
    navigateTo: '',
    environment: getEnv(),
    hasAuthToken: true,
    successModal: {
        isOpen: false,
    },
    confirmModal: {
        isOpen: false,
    },
    screenSize: getScreenSize(),
    sessionToken: uuidv4(),
    status: STATUS.IDLE,
    step: '',
    version: packageJson.version,
    isPixEnabled: false,
};

export default {
    app: handleActions({
            [AppConstants.IS_PROCESSING]: (state, { payload }) =>
                produce(state, (draft) => {
                    draft.isProcessing = payload.isProcessing;
                }),
            [AppConstants.HIDE_ALERT]: (state, { payload }) =>
                produce(state, (draft) => {
                    draft.alerts = draft.alerts.filter(
                        (alert) => payload.id !== alert.id
                    );
                }),
            [AppConstants.SET_AUTH_TOKEN]: (state, { payload }) =>
                produce(state, (draft) => {
                    draft.authToken = payload.authToken;
                    draft.hasAuthToken = !!payload.authToken;
                }),
            [AppConstants.SET_NAVIGATION]: (state, { payload }) =>
                produce(state, (draft) => {
                    draft.redirectTo = payload.redirectTo;
                }),
            [AppConstants.SET_SCREEN_SIZE]: (state, { payload }) =>
                produce(state, (draft) => {
                    draft.screenSize = payload.screenSize;
                }),
            [AppConstants.SHOW_ALERT]: (state, { payload }) =>
                produce(state, (draft) => {
                    draft.alerts.push({...payload });
                }),
            [AppConstants.TOGGLE_SUCCESS_MODAL]: (state, { payload: { status } }) =>
                produce(state, (draft) => {
                    draft.successModal.isOpen = status;
                }),
            [AppConstants.APP_SET_STEP]: (state, { payload }) =>
                produce(state, (draft) => {
                    draft.step = payload;
                }),
            [AppConstants.IS_PIX_ENABLED]: (state, { payload }) =>
                produce(state, (draft) => {
                    draft.isPixEnabled = payload;
                }),
            [AppConstants.TOGGLE_CONFIRM_MODAL]: (state, { payload: { status } }) =>
                produce(state, (draft) => {
                    draft.confirmModal.isOpen = status;
                }),
            [AppConstants.SET_MODAL_ERROR]: (state, { payload: { content } }) =>
                produce(state, (draft) => {
                    draft.modalError = content;
                }),
        },
        appState),
};

import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'containers/Layout';
import { InfoCardWrapper, Text, Heading, IconWrapper } from './InfoCard.styles';

const InfoCard = ({
  icon,
  title,
  text,
  textComponent,
  iconComponent,
  showCardBox,
  children,
  ...props
}) => {
  const IconComponent = () => iconComponent;
  const withCardBox = () => (
    <InfoCardWrapper {...props}>
      <Card>
        <InfoCardContent iconHeight="88px" />
        {children}
      </Card>
    </InfoCardWrapper>
  );

  // eslint-disable-next-line react/prop-types
  const InfoCardContent = ({ iconHeight }) => (
    <>
      {iconComponent ? (
        <IconComponent />
      ) : (
        <IconWrapper style={{ height: iconHeight }}>{icon}</IconWrapper>
      )}
      <Heading as="h2" size="peta">
        {title}
      </Heading>
      <Text>{textComponent || text}</Text>
    </>
  );

  return showCardBox ? (
    withCardBox()
  ) : (
    <InfoCardWrapper {...props} data-testid="infoCard">
      <InfoCardContent />
    </InfoCardWrapper>
  );
};

InfoCard.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.object,
  iconComponent: PropTypes.node,
  showCardBox: PropTypes.bool,
  text: PropTypes.element,
  textComponent: PropTypes.element,
  title: PropTypes.string,
};

InfoCard.defaultProps = {
  showCardBox: false,
  textComponent: undefined,
};

export default InfoCard;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InfoCard from 'components/InfoCard';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@sumup/circuit-ui';

import { clearAddressKeyResponse, clearAddressKeyValues, clearClaimResponse } from 'actions';
import {
  CoverScreenWrapper,
  IconWrapper,
  StyledBottomBar,
  StyledCross,
} from './CoverScreen.styles';

const CoverScreen = ({ active, content = {}, onClose, modalBehavior }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [coverState, setCoverState] = useState(true);
  const isActive = !modalBehavior ? active && coverState : active;

  const { icon, title, text, actions, back } = content;

  const clearResponses = () => {
    dispatch(clearClaimResponse({}));
    dispatch(clearAddressKeyResponse({}));
  };

  const clearState = () => {
    dispatch(clearAddressKeyResponse({}));
    dispatch(clearAddressKeyValues({}));
  };

  const onCoverScreenClose = () => {
    if (back) {
      history.push(back.to);
    }

    if (back.clearState) {
      clearState();
    }

    setCoverState(false);
    if (onClose) onClose();
  };

  const onButtonClick = (destination, mustClearState, mustClearResponses) => {
    if (mustClearState) {
      clearState();
    }

    if(mustClearResponses) { 
      clearResponses();
    }

    setCoverState(false);
    if(destination) {
      history.push(destination);
    }
    if (onClose) onClose();
  };

  return (
    <>
      {isActive && (
        <CoverScreenWrapper data-testid="cover-screen">
          <IconWrapper onClick={onCoverScreenClose}>
            <StyledCross />
          </IconWrapper>
          <InfoCard textComponent={text} title={title} icon={icon} />
          <StyledBottomBar>
            {actions.map((action, index) => (
              <Button
                stretch={true}
                key={index + 1}
                variant={action.type}
                onClick={() => onButtonClick(action.to, action.clearState, action.clearResponses)}
              >
                {action.label}
              </Button>
            ))}
          </StyledBottomBar>
        </CoverScreenWrapper>
      )}
    </>
  );
};

CoverScreen.propTypes = {
  active: PropTypes.bool,
  content: PropTypes.objectOf({
    backTo: PropTypes.func,
    icon: PropTypes.object,
    title: PropTypes.string,
    text: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.string),
  }),
  modalBehavior: PropTypes.bool,
  onClose: PropTypes.func,
};

CoverScreen.defaultProps = {
  modalBehavior: false,
};

export default CoverScreen;

import { getEnv } from 'modules/helpers';
import { Integrations } from '@sentry/tracing';
import packageJson from '../../package.json';

let baseURL = process.env.BANK_BFC_GRAPHQL_API_URL;
let graphql  = '/graphql';
if(baseURL === undefined) {
  baseURL = graphql;
}

const search_string = window.location.search.substr(1);

if (search_string === 'io') {
  baseURL = baseURL.replace(graphql,'/io' + graphql);
}

// This is a env var, check config-overrides.js for more info
const graphql_url = baseURL;

const xHoneycombEndpoint = 'https://api.honeycomb.io:443/v1/traces';

const xCombDataSetMap = {
  development: 'local',
  stage: 'stage',
  production: 'live'
};

const xHoneycombTeamKey = '3817eb54470a1abeca706137d7b0db3a';

const config = {
  apiBFC: graphql_url,
  apiPlaces: '/.netlify/functions/places',
  siteName: 'SumUp - PiX',
  timeWindow: {
    isEnabled: true,
    start: '09:00:00',
    end: '18:00:00',
  },
  isPixEnabled: false,
  sentry: {
    debug: false,
    dsn:
      'https://4bd82de4098b4918a01592399aba415b@o153781.ingest.sentry.io/5449878',
    environment: `${getEnv()}`,
    release: packageJson.version,
    whitelistUrls: [/pix-webview.prod.sumup-bank\.info/],
    ignoreErrors: [
      "Cannot read property 'getHostNode' of null",
      'SecurityError',
      'Unexpected identifier',
      'Failed to update a ServiceWorker',
      'Failed to fetch',
      /Blocked a frame with origin.*/,
      /.*An unknown error occurred when fetching the script.*/,
      /(cancelado|cancelled|timeout|conexão.*inativa|hostname could not be found|connection.*offline|Esgotou-se o tempo limite da solicitação.*)/,
    ],
    integrations: [new Integrations.BrowserTracing()],
  },
  isSumUpIo: search_string === 'io',
  otelExporterOtlpEndpoint: xHoneycombEndpoint,
  otelExporterOtlpHeaders: {
    'x-honeycomb-dataset': xCombDataSetMap[process.env.NODE_ENV] || 'live',
    'x-honeycomb-team': xHoneycombTeamKey,
  },
};

export default config;

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TableList from 'components/TableList';
import { formatValues, addressKeyTypes } from 'modules/content';
import { contentHandler } from 'content/handlers';

const { TableTitle, TableRow } = TableList;

const KeysList = ({ keysToShow, onClick }) => {
  const {
    addressKeys: { finalList },
  } = useSelector((state) => state.addressKey);

  const keys = keysToShow || finalList;

  const mountAddressKeyRow = (addressKeysList) =>
    addressKeysList?.map(({ key, keyType, status, currentStatus }, index) => (
      <TableRow
        onClick={() =>
          onClick(
            addressKeysList[index], keyType, key, status, currentStatus
          )}
        detail={true}
        key={key}
        value={formatValues(
          addressKeysList[index], keyType, 'key'
        )}
        label={addressKeyTypes[keyType]?.name}
        status={contentHandler(addressKeysList[index])}
      />
    ));

  return (
    <>
      {Object.keys(keys).map((key) => {
        if (keys[key]?.list?.length) {
          return (
            <>
              <TableTitle size="kilo">{keys[key].title}</TableTitle>
              {mountAddressKeyRow(keys[key].list)}
            </>
          );
        }

        return <></>;
      })}
    </>
  );
};

KeysList.propTypes = {
  keysToShow: PropTypes.object,
  onClick: PropTypes.func,
};

export default KeysList;

/**
 * @module Actions/Claim
 * @desc Actions for Claim
 */
import { createActions } from 'redux-actions';

import { ClaimConstants } from 'constants';

export const {
  clearClaimResponse,
  clearClaimValues,
  createClaimRequest: createClaim,
  listClaimRequest: listClaim,
  acceptClaimRequest: acceptClaim,
  rejectClaimRequest: rejectClaim,
  completeClaimRequest: completeClaim,
  setClaimKey,
} = createActions({
  [ClaimConstants.SET_CLAIM_KEY]: (key) => key,
  [ClaimConstants.CLEAR_CLAIM_RESPONSE]: () => ({}),
  [ClaimConstants.CLEAR_CLAIM_VALUES]: () => ({}),
  [ClaimConstants.CREATE_CLAIM_REQUEST]: (input) => ({
    input,
  }),
  [ClaimConstants.LIST_CLAIM_REQUEST]: (input) => ({
    input,
  }),
  [ClaimConstants.ACCEPT_CLAIM_REQUEST]: (input) => ({
    input,
  }),
  [ClaimConstants.REJECT_CLAIM_REQUEST]: (input) => ({
    input,
  }),
  [ClaimConstants.COMPLETE_CLAIM_REQUEST]: (input) => ({
    input,
  }),
});

import React from 'react';
import PropTypes from 'prop-types';
import TableList from 'components/TableList';
import { formatValues, addressKeyTypes } from 'modules/content';
import { contentHandler } from 'content/handlers';
import { KeyListContainer } from './KeysManagement.styles';

const { TableTitle, TableRow } = TableList;

const KeysList = ({ title, addressKeys, onSelectedKey }) => (
  <KeyListContainer>
    <TableTitle size="kilo">{title}</TableTitle>
    {addressKeys?.map(({ key, keyType, status }, index) => {
      const addressKey = addressKeys[index];
      const formatedValue = formatValues(
        addressKeys[index], keyType, 'key'
      );

      return (
        <TableRow
          onClick={() =>
            onSelectedKey({ addressKey, keyType, key, status, formatedValue })}
          detail={true}
          key={key}
          value={formatedValue}
          label={addressKeyTypes[keyType]?.name}
          status={contentHandler(addressKey)}
        />
      );
    })}
  </KeyListContainer>
);

KeysList.propTypes = {
  addressKeys: PropTypes.array,
  onSelectedKey: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

KeysList.defaultProps = {
  addressKeys: [],
};

export default KeysList;

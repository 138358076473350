import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const InfoCardWrapper = styled('div')(() => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  text-align: center;
`);

export const IconWrapper = styled('div')(({ theme }) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 ${theme.spacings.kilo};

  img {
    width: 100%;
  }
`);

export const ImageWrapperSmall = styled('div')(({ theme }) => css`
  align-items: center;
  display: flex;
  height: 150px;
  justify-content: center;
  margin: 0 auto ${theme.spacings.mega};
  width: 150px;

  & img,
  & svg {
    height: 100%;
  }
`);

export const Heading = styled('h2')(({ theme }) => css`
  font-size: 21px;
  font-weight: bold;
  margin: ${theme.spacings.mega} 0 ${theme.spacings.tera};
`);

export const TextContainer = styled('div')(() => css`
  text-align: left;
`);

export const Text = styled('p')(({ theme }) => css`
  font-size: 16px;
  font-weight: normal;
  line-height: 1.4em;
  margin: 0 0 ${theme.spacings.giga} 0;

  & strong {
    font-weight: bold;
  }

  & a {
    color: ${theme.colors.p500};
    text-decoration: none;
  }
`);

export const SecondaryText = styled('p')(({ theme }) => css`
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
  margin: 0 0 ${theme.spacings.giga} 0;

  & strong {
    font-weight: bold;
  }

  & a {
    color: ${theme.colors.p500};
    text-decoration: none;
  }
`);

import React from 'react';
import { Text, Card, Notification } from '@sumup/circuit-ui';
import { CircleWarningFilled } from '@sumup/icons';
import PropTypes from 'prop-types';
import { Wrapper } from './Notification.styles';

const StatusMessage = ({ content }) => (
  <Wrapper data-testid="notification">
    <Card>
      <Notification icon={CircleWarningFilled}>
        <Text size="kilo" bold={true} noMargin>
          {content.title || content.label}
        </Text>
        <Text size="kilo" noMargin>
          {content.text}
        </Text>
      </Notification>
    </Card>
  </Wrapper>
);

StatusMessage.propTypes = {
  content: PropTypes.objectOf({
    label: PropTypes.string,
  }),
};

export default StatusMessage;

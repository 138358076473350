import React from 'react';
import PropTypes from 'prop-types';
import { LoadingScreen, Heading, Text, BottomBar } from 'containers/Layout';
import { Button } from '@sumup/circuit-ui';
import pixLogo from 'assets/icons/onboarding-1.svg';
import { useBottomBar } from 'hooks';
import { isExecutingInBankApp, executeNativeBack } from 'modules/dom';

const StatusDescription = ({ hasError }) => (
  <div style={{ minHeight: hasError ? '50vh' : '60vh' }}>
    <Heading size="tera">Não foi possível acessar o Pix</Heading>
    <Text>Não foi possível acessar o Pix neste momento. Por favor, tente novamente dentro de alguns instantes.</Text>
  </div>
);

StatusDescription.propTypes = {
  hasError: PropTypes.bool,
};

StatusDescription.defaultProps = {
  hasError: true,
};

const GenericError = () => {
  const [bottombarRef] = useBottomBar();

  const buttonText = isExecutingInBankApp ? 'Voltar' : 'Tentar novamente';

  const onTryAgainClickHandler = () => {
    if (isExecutingInBankApp) {
      executeNativeBack();
    }
    else {
      window.location.reload();
    }
  };

  return (
    <>
      <LoadingScreen data-testid="generic-error">
        <img src={pixLogo} alt="Logo do SumUp Pix" />
        <div>
          <StatusDescription />
        </div>
      </LoadingScreen>
      <BottomBar ref={bottombarRef}>
        <Button onClick={onTryAgainClickHandler} stretch="true">
          {buttonText}
        </Button>
      </BottomBar>
    </>
  );
};



export default GenericError;

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const withOnboarding = (Component) => {
  const OnboardingHOC = (props) => {
    const { children } = props;
    const history = useHistory();
    const [cookies, setCookie] = useCookies(['onboarding-viewed']);

    useEffect(() => {
      if (!cookies['onboarding-viewed']) {
        history.push('/onboarding');
        setCookie('onboarding-viewed', true, { path: '/' })
      }
    }, [
      cookies, history, setCookie
    ]);

    return <Component {...props}>{children}</Component>;
  };
  return OnboardingHOC;
};


export default withOnboarding;

import React, { useCallback, useEffect } from 'react';

import { useModal } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setModalError } from 'actions';

import Portal from 'components/Portal';

import { ModalStyled } from './ErrorModal.styles';

const {
  ModalWrapper,
  StyledNotification,
  Title,
  Subtitle,
  CloseButton,
  StyledErrorIcon,
} = ModalStyled;

const ErrorModal = () => {
  const modalError = useSelector((state) => state.app.modalError);
  const [isModalActive, setModalActive] = useModal();
  const dispatch = useDispatch();

  useEffect(() => {
    if (modalError) {
      setModalActive(true);
    }
    else {
      setModalActive(false);
    }
  }, [modalError, setModalActive]);

  const handleCloseAlert = useCallback(() => {
    dispatch(setModalError(null));
    setModalActive(false);
  }, [dispatch, setModalActive]);

  return (
    <React.Fragment>
      {isModalActive && (
        <Portal id="modal">
          <ModalWrapper
            data-testid="error-modal"
            role="dialog"
          >
            <StyledNotification icon={StyledErrorIcon}>
              <Title>{modalError.title}</Title>
              <Subtitle>{modalError.subtitle}</Subtitle>
            </StyledNotification>
            <CloseButton onClick={handleCloseAlert} />
          </ModalWrapper>
        </Portal>
      )}
    </React.Fragment>
  );
};

ErrorModal.propTypes = {};

export default ErrorModal;

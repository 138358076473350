import { useEffect, useRef } from 'react';
import treeChanges from 'tree-changes';

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export const useTreeChanges = (props) => {
  const prevProps = usePrevious(props) || {};

  return treeChanges(prevProps, props);
};

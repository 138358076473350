import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const AppStyled = {
  AppWrapper: styled('div')(({ theme }) => css`
    background-color: #fff;
    height: 100%;
    min-height: 100vh;
    position: relative;
    width: 100%;

    * {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
    }

    input, textarea {
      appearance: textfield;
      -webkit-touch-callout: auto;
      -webkit-user-select: auto;
    }
  `),
};

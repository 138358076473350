import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const withKeysOnboarding = (Component) => {
  const KeysOnboardingHOC = (props) => {
    const { children } = props;
    const history = useHistory();
    const [cookies, setCookie] = useCookies(['keys-onboarding-viewed']);

    useEffect(() => {
      if (!cookies['keys-onboarding-viewed']) {
        history.push('/onboarding-keys');
        setCookie('keys-onboarding-viewed', true, { path: '/' })
      }
    }, [
      cookies, history, setCookie
    ]);

    return <Component {...props}>{children}</Component>;
  };
  return KeysOnboardingHOC;
};


export default withKeysOnboarding;

import React from 'react';
import { ReactComponent as FailureIcon } from 'assets/icons/feedback-failure.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/feedback-complete.svg';
import { ReactComponent as AlertIcon } from 'assets/icons/feedback-alert.svg';

export const claimResponseHandling = (response) => {
  const createClaimResponses = {
    acceptOwnershipSuccess: () => ({
      icon: <SuccessIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Pedido de reivindicação de posse aceito com sucesso',
      text: <></>,
      actions: [
        {
          label: 'Finalizar',
          type: 'primary',
          to: '/keys-management',
        },
      ],
    }),
    acceptPortabilitySuccess: () => ({
      icon: <SuccessIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Pedido de portabilidade aceito com sucesso!',
      text: <></>,
      actions: [
        {
          label: 'Finalizar',
          type: 'primary',
          to: '/keys-management',
        },
      ],
    }),
    acceptOwnershipError: () => ({
      icon: <FailureIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Ocorreu um erro ao aceitar reivindicação de posse',
      text: (
        <p>
          Um erro inesperado aconteceu. Tente novamente ou aguarde e tente mais
          tarde.
        </p>
      ),
      actions: [
        {
          label: 'Tentar novamente',
          type: 'primary',
          to: '/ownership/accept',
        },
        {
          label: 'Cancelar',
          type: 'secondary',
          to: '/keys-management',
        },
      ],
    }),
    acceptPortabilityError: () => ({
      icon: <FailureIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Ocorreu um erro ao aceitar portabilidade',
      text: <></>,
      actions: [
        {
          label: 'Fechar e aguardar',
          type: 'primary',
          to: '/keys-management',
        },
      ],
    }),
    claimOwnershipSuccess: () => ({
      icon: <SuccessIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Chave Pix reivindicada com sucesso!',
      text: (
        <p>
          A reivindicação de posse já foi processada. O prazo para conclusão do
          processo é de até 14 dias.
        </p>
      ),
      actions: [
        {
          label: 'Finalizar',
          type: 'primary',
          to: '/keys-management',
        },
      ],
    }),
    claimPortabilitySuccess: (addressKeyParticipantName) => ({
      icon: <SuccessIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Portabilidade iniciada com sucesso!',
      text: (
        <p>
	  Falta muito pouco! Agora é só acessar o aplicativo do <strong>{addressKeyParticipantName}</strong> e autorizar a mudança da sua chave Pix, que em instantes ficará disponível no
	  SumUp Bank. Lembre-se, você tem até 7 dias para autorizar a
	  portabilidade.
        </p>
      ),
      actions: [
        {
          label: 'Cadastrar outra chave Pix',
          type: 'primary',
          to: '/new-key/type',
        },
        {
          label: 'Fechar e aguardar',
          type: 'secondary',
          to: '/keys-management',
        }
      ],
    }),
    claimOwnershipError: () => ({
      icon: <FailureIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Ocorreu um erro ao reivindicar posse',
      text: (
        <p>
          Um erro inesperado aconteceu. Tente novamente ou aguarde e tente mais
          tarde.
        </p>
      ),
      actions: [
        {
          label: 'Tentar novamente',
          type: 'primary',
          clearResponses: true
        },
        {
          label: 'Cancelar',
          type: 'secondary',
          to: '/keys-management',
        },
      ],
    }),
    claimPortabilityError: () => ({
      icon: <FailureIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Ocorreu um erro ao solicitar portabilidade',
      text: (
        <p>
          Um erro inesperado aconteceu. Tente novamente ou aguarde e tente mais
          tarde.
        </p>
      ),
      actions: [
        {
          label: 'Tentar novamente',
          type: 'primary',
          to: '/portability/claim',
        },
        {
          label: 'Cancelar',
          type: 'secondary',
          to: '/keys-management',
        },
      ],
    }),
    rejectOwnershipSuccess: () => ({
      icon: <SuccessIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Pedido de reivindicação de posse rejeitado com sucesso',
      text: <></>,
      actions: [
        {
          label: 'Finalizar',
          type: 'primary',
          to: '/keys-management',
        },
      ],
    }),
    cancelBlockedOwnershipSuccess: () => ({
      icon: <SuccessIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Reivindicação cancelada com sucesso',
      text: (
        <>
          Sua chave Pix foi cancelada e está disponível para um novo cadastro.
        </>
      ),
      actions: [
        {
          label: 'Cadastrar chave Pix',
          type: 'primary',
          to: '/new-key/type',
        },
        {
          label: 'Finalizar',
          type: 'secondary',
          to: '/keys-management',
        },
      ],
    }),
    cancelOwnershipSuccess: () => ({
      icon: <SuccessIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Pedido de reivindicação de posse cancelado com sucesso',
      text: <></>,
      actions: [
        {
          label: 'Finalizar',
          type: 'primary',
          to: '/keys-management',
        },
      ],
    }),
    cancelOwnershipError: () => ({
      icon: <FailureIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Ocorreu um erro ao cancelar o pedido de reivindicação de posse.',
      text: <></>,
      actions: [
        {
          label: 'Tentar novamente',
          type: 'primary',
          to: '/keys-management',
        },
      ],
    }),
    completeOwnershipSuccess: () => ({
      icon: <SuccessIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Reivindicação de posse finalizada com sucesso!',
      text: <></>,
      actions: [],
    }),
    completeOwnershipError: () => ({
      icon: <FailureIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Ocorreu um erro ao finalizar o pedido de reivindicação de posse.',
      text: <></>,
      actions: [
        {
          label: 'Tentar novamente',
          type: 'primary',
          to: '/keys-management',
        },
      ],
    }),
    rejectPortabilitySuccess: () => ({
      icon: <SuccessIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Pedido de portabilidade rejeitado com sucesso',
      text: <></>,
      actions: [
        {
          label: 'Finalizar',
          type: 'primary',
          to: '/keys-management',
        },
      ],
    }),
    rejectOwnershipError: () => ({
      icon: <FailureIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Ocorreu um erro ao rejeitar o pedido de reivindicação de posse',
      text: (
        <p>
          Um erro inesperado aconteceu. Tente novamente ou aguarde e tente mais
          tarde.
        </p>
      ),
      actions: [
        {
          label: 'Tentar novamente',
          type: 'primary',
          to: '/ownership/reject',
        },
        {
          label: 'Cancelar',
          type: 'seconday',
          to: '/keys-management',
        },
      ],
    }),
    confirmOwnershipCancel: () => ({
      icon: <AlertIcon />,
      back: {
        to: '/keys-management',
      },
      text: (
        <>
          <p>
            Ao cancelar a reivindicação, você impede que a sua chave Pix vá para
            outra pessoa, mas ela fica indisponível até que você faça o cadastro
            novamente.
          </p>{' '}
          <p>
            Para continuar, confirme o código de segurança que será enviado para
            você.
          </p>
        </>
      ),
      actions: [
        {
          label: 'Continuar',
          type: 'primary',
          to: '/ownership/reject',
        },
        {
          label: 'Cancelar',
          type: 'secondary',
          to: '/ownership/cancel',
        },
      ],
    }),
    rejectPortabilityError: () => ({
      icon: <FailureIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Ocorreu um erro ao rejeitar o pedido de portabilidade',
      text: (
        <p>
          Um erro inesperado aconteceu. Tente novamente ou aguarde e tente mais
          tarde.
        </p>
      ),
      actions: [
        {
          label: 'Tentar novamente',
          type: 'primary',
          to: '/portability/reject',
        },
        {
          label: 'Cancelar',
          type: 'seconday',
          to: '/keys-management',
        },
      ],
    }),
  };

  const code = response in createClaimResponses ? response : 'default';
  return createClaimResponses[code];
};

/**
 * @module Actions/AddressKey
 * @desc Actions for AddressKey
 */
import { createActions } from 'redux-actions';

import { AddressKeyConstants } from 'constants';

export const {
  setKeyType,
  setKeyValue,
  setDetailKey,
  clearAddressKeyResponse,
  clearAddressKeyValues,
  setFinalKeyList,
  addressKeyCreateRequest: associateKey,
  addressKeyDeleteRequest: addressKeyDelete,
  addressKeyListRequest: listAddressKey,
  addressKeyDetailShow: addressKeyDetail,
  addressKeyPinValidationRequest: addressKeyPinValidate,
} = createActions({
  [AddressKeyConstants.SET_FINAL_KEY_LIST]: (finalList) => finalList,
  [AddressKeyConstants.CLEAR_ADDRESS_KEY_RESPONSE]: () => ({}),
  [AddressKeyConstants.CLEAR_ADDRESS_KEY_VALUES]: () => ({}),
  [AddressKeyConstants.ADDRESS_KEY_LIST_REQUEST]: () => ({}),
  [AddressKeyConstants.ADDRESS_KEY_DETAIL_SHOW]: (addressKey) => ({
    addressKey,
  }),
  [AddressKeyConstants.SET_KEY_TYPE]: (keyType) => keyType,
  [AddressKeyConstants.SET_DETAIL_KEY]: (addressKey) => addressKey,
  [AddressKeyConstants.SET_KEY_VALUE]: (keyValue) => keyValue,
  [AddressKeyConstants.ADDRESS_KEY_CREATE_REQUEST]: (input) => ({ input }),
  [AddressKeyConstants.ADDRESS_KEY_DELETE_REQUEST]: (input) => ({ input }),
  [AddressKeyConstants.ADDRESS_KEY_PIN_VALIDATION_REQUEST]: (input) => ({
    input,
  }),
});

import React from 'react';
import IllustrationSource from 'assets/images/ana-coin@2x.png';
import { ReactComponent as SellIcon } from 'assets/icons/Illustration-card-reader-pay.svg';
import { ImageWrapperSmall } from 'components/InfoCard';

export const bankAccountContent = (option) => {
  const content = {
    sell: {
      icon: <SellIcon />,
      text:
        'Para receber suas vendas com o Pix é necessário possuir uma conta no SumUp Bank. Sua conta está pré-aprovada, comece agora mesmo!',
    },
    manage: {
      icon: (
        <ImageWrapperSmall>
          <img src={IllustrationSource} alt="" />
        </ImageWrapperSmall>
      ),
      text:
        'Para se beneficiar dos pagamentos instantâneos com o Pix é necessário possuir uma conta no SumUp Bank. Sua conta está pré-aprovada, comece agora mesmo!',
    },
  };

  return content[option];
};

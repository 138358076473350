import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTreeChanges } from 'hooks';
import CoverScreen from 'containers/CoverScreen';
import { createClaim, rejectClaim, acceptClaim, completeClaim } from 'actions';
import {
  STATUS,
  ADDRESS_KEY_TYPE_PHONE,
  PARTICIPATION_TYPE,
  CLAIM_ACTIONS,
} from 'constants';
import { claimResponseHandling } from 'content/claim';
import Processing from 'containers/Processing';
import KeyValidation from 'containers/KeyValidation';

const Ownership = () => {
  const { action } = useParams();
  const dispatch = useDispatch();
  const [keyValidationState, setKeyValidationState] = useState(false);
  const [participationType, setParticipationType] = useState('');
  const [coverState, setCoverState] = useState(false);
  const [responseContent, setResponseContent] = useState({});
  const {
    claimCreation,
    claimAcceptance,
    claimRejection,
    claimCompletion,
  } = useSelector((state) => state.claim);
  const { validation } = useSelector((state) => state.addressKey);

  const {
    keyType,
    keyValue,
    addressKeys: {
      detailedKey: { key: detailedKey },
    },
  } = useSelector((state) => state.addressKey);

  const { changedFrom: createClaimChangedFrom } = useTreeChanges(claimCreation.status);
  const { changedFrom: completeClaimChangedFrom } = useTreeChanges(claimCompletion.status);
  const { changedFrom: acceptClaimChangedFrom } = useTreeChanges(claimAcceptance.status);
  const { changedFrom: rejectClaimChangedFrom } = useTreeChanges(claimRejection.status);
  const { changedFrom: validateTokenChangedFrom } = useTreeChanges(validation.status);

  useEffect(() => {
    if (
      validateTokenChangedFrom(
        'validation.status',
        STATUS.RUNNING,
        STATUS.SUCCESS
      ) &&
      action === CLAIM_ACTIONS.reject
    ) {
      const content = claimResponseHandling('rejectOwnershipSuccess')();
      setResponseContent(content);
      setCoverState(true);
    }
  }, [validateTokenChangedFrom, action]);

  useEffect(() => {
    if (
      validateTokenChangedFrom(
        'validation.status',
        STATUS.RUNNING,
        STATUS.SUCCESS
      ) &&
      action === CLAIM_ACTIONS.complete
    ) {
      const content = claimResponseHandling('completeOwnershipSuccess')();
      setResponseContent(content);
      setCoverState(true);
    }
  }, [validateTokenChangedFrom, action]);

  useEffect(() => {
    const requestError = validation?.requestError;

    if (
      validateTokenChangedFrom(
        'validation.status',
        STATUS.RUNNING,
        STATUS.ERROR
      ) &&
      action === CLAIM_ACTIONS.reject &&
      requestError
    ) {
      const content = claimResponseHandling('rejectOwnershipError')();
      setResponseContent(content);
      setCoverState(true);
    }
  }, [validateTokenChangedFrom, action, validation]);

  useEffect(() => {
    if (
      acceptClaimChangedFrom(
        'claimAcceptance.status',
        STATUS.RUNNING,
        STATUS.SUCCESS
      )
    ) {
      const content = claimResponseHandling('acceptOwnershipSuccess')();
      setResponseContent(content);
      setCoverState(true);
    }
  }, [acceptClaimChangedFrom, action]);

  useEffect(() => {
    if (
      completeClaimChangedFrom(
        'claimCompletion.status',
        STATUS.RUNNING,
        STATUS.SUCCESS
      )
    ) {
      setKeyValidationState(true);
      setParticipationType(PARTICIPATION_TYPE.claimant);
    }
  }, [completeClaimChangedFrom, action]);

  useEffect(() => {
    if (
      completeClaimChangedFrom(
        'claimCompletion.status',
        STATUS.RUNNING,
        STATUS.ERROR
      )
    ) {
      setKeyValidationState(true);
      setParticipationType(PARTICIPATION_TYPE.claimant);
    }
  }, [completeClaimChangedFrom, action]);

  useEffect(() => {
    if (
      acceptClaimChangedFrom(
        'claimAcceptance.status',
        STATUS.RUNNING,
        STATUS.ERROR
      )
    ) {
      const content = claimResponseHandling('acceptOwnershipError')();
      setResponseContent(content);
      setCoverState(true);
    }
  }, [acceptClaimChangedFrom, action]);

  useEffect(() => {
    if (
      createClaimChangedFrom(
        'claimCreation.status',
        STATUS.RUNNING,
        STATUS.SUCCESS
      )
    ) {
      const content = claimResponseHandling('claimOwnershipSuccess')();
      setResponseContent(content);
      setCoverState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimCreation.status]);

  useEffect(() => {
    if (
      createClaimChangedFrom(
        'claimCreation.status',
        STATUS.RUNNING,
        STATUS.ERROR
      )
    ) {
      const content = claimResponseHandling('claimOwnershipError')();
      setResponseContent(content);
      setCoverState(true);
    }
  }, [createClaimChangedFrom, setResponseContent, setCoverState, action]);

  useEffect(() => {
    if (
      createClaimChangedFrom(
        'claimCreation.status',
        STATUS.RUNNING,
        STATUS.ERROR
      )
    ) {
      const content = claimResponseHandling('claimOwnershipError')();
      setResponseContent(content);
      setCoverState(true);
    }
  }, [createClaimChangedFrom, setResponseContent, setCoverState, action]);

  useEffect(() => {
    if (
      rejectClaimChangedFrom(
        'claimRejection.status',
        STATUS.RUNNING,
        STATUS.SUCCESS
      ) &&
      action === CLAIM_ACTIONS.reject
    ) {
      setKeyValidationState(true);
      setParticipationType(PARTICIPATION_TYPE.donor);
    }
  }, [rejectClaimChangedFrom, setResponseContent, setCoverState, action]);

  useEffect(() => {
    if (
      rejectClaimChangedFrom(
        'claimRejection.status',
        STATUS.RUNNING,
        STATUS.ERROR
      ) &&
      action === CLAIM_ACTIONS.reject
    ) {
      const content = claimResponseHandling('rejectOwnershipError')();
      setResponseContent(content);
      setCoverState(true);
    }
  }, [rejectClaimChangedFrom, setResponseContent, setCoverState, action]);

  useEffect(() => {
    if (
      rejectClaimChangedFrom(
        'claimRejection.status',
        STATUS.RUNNING,
        STATUS.SUCCESS
      ) &&
      action === CLAIM_ACTIONS.cancel
    ) {
      const content = claimResponseHandling('cancelOwnershipSuccess')();
      setResponseContent(content);
      setCoverState(true);
    }
  }, [rejectClaimChangedFrom, setResponseContent, setCoverState, action]);

  useEffect(() => {
    if (
      rejectClaimChangedFrom(
        'claimRejection.status',
        STATUS.RUNNING,
        STATUS.SUCCESS
      ) &&
      action === CLAIM_ACTIONS.confirmed
    ) {
      const content = claimResponseHandling('cancelBlockedOwnershipSuccess')();
      setResponseContent(content);
      setCoverState(true);
    }
  }, [rejectClaimChangedFrom, setResponseContent, setCoverState, action]);

  useEffect(() => {
    if (
      rejectClaimChangedFrom(
        'claimRejection.status',
        STATUS.RUNNING,
        STATUS.ERROR
      ) &&
      action === CLAIM_ACTIONS.cancel
    ) {
      const content = claimResponseHandling('cancelOwnershipError')();
      setResponseContent(content);
      setCoverState(true);
    }
  }, [rejectClaimChangedFrom, setResponseContent, setCoverState, action]);

  useEffect(() => {
    if (action === CLAIM_ACTIONS.confirm) {
      const content = claimResponseHandling('confirmOwnershipCancel')();
      setResponseContent(content);
      setCoverState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, action]);

  useEffect(() => {
    if (
      claimAcceptance.status === STATUS.IDLE &&
      action === CLAIM_ACTIONS.accept
    ) {
      dispatch(acceptClaim({
        key: detailedKey,
        confirmation_reason: 'OWNER_REQUEST',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, action]);

  useEffect(() => {
    if (
      claimCompletion.status === STATUS.IDLE &&
      action === CLAIM_ACTIONS.complete
    ) {
      dispatch(completeClaim({
        key: detailedKey,
        confirmation_reason: 'OWNER_REQUEST',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, action]);

  useEffect(() => {
    if (
      claimRejection.status === STATUS.IDLE &&
      (action === CLAIM_ACTIONS.reject || action === CLAIM_ACTIONS.confirmed)
    ) {
      setCoverState(false);
      dispatch(rejectClaim({
        key: detailedKey,
        cancelationReason: 'OWNER_REQUEST',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, action]);

  useEffect(() => {
    if (
      claimRejection.status === STATUS.IDLE &&
      action === CLAIM_ACTIONS.cancel
    ) {
      dispatch(rejectClaim({
        key: detailedKey,
        cancelation_reason: 'DEFAULT',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, action]);

  useEffect(() => {
    if (
      claimCreation.status === STATUS.IDLE &&
      action === CLAIM_ACTIONS.claim
    ) {
      const isPhone = keyType.canonical === ADDRESS_KEY_TYPE_PHONE.canonical;
      const hasCountryCode = keyValue?.value?.includes('+55');
      const value =
        isPhone && !hasCountryCode ? `+55${keyValue?.value}` : keyValue?.value;
      setCoverState(false);
      dispatch(createClaim({
        key: value,
        keyType: keyType.canonical,
        claimType: 'CLAIM_OWNERSHIP',
        participationType: PARTICIPATION_TYPE.claimant,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, action, claimCreation.status]);

  return (
    <>
      {keyValidationState && (
        <KeyValidation
          data-testid="ownership"
          participationType={participationType}
          hideDeleteKeyButton={true}
        />
      )}
      {coverState ? (
        <CoverScreen content={responseContent} active={coverState} />
      ) : (
        <Processing />
      )}
    </>
  );
};

export default Ownership;

import account, { accountState } from './account';
import app, { appState } from './app';
import addressKey, { addressKeyState } from './addressKey';
import claim, { claimState } from './claim';
import checkout, { checkoutState } from './checkout';

export const initialState = {
  app: appState,
  account: accountState,
  addressKey: addressKeyState,
  claim: claimState,
  checkout: checkoutState,
};

export default {
  ...app,
  ...account,
  ...addressKey,
  ...claim,
  ...checkout,
};

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Button, Card, Text, Input } from '@sumup/circuit-ui';
import { ReactComponent as CheckIcon } from 'assets/icons/icon-check.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/icon-error.svg';
import { ReactComponent as CopyPasteIcon } from 'assets/icons/copy-paste.svg';
import { FiX, FiAlertCircle } from 'react-icons/fi';

export const RemarkContainer = styled('div')(({ theme }) => css`
    border-top: 1px solid ${theme.colors.n300};
    width: 100%;
    padding: 0 16px 10px 16px;
    display: flex;
    align-items: center;

    p {
      margin-bottom: 0;
    }
  `);

export const DescriptionHeader = styled('div')(({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.n300};
    width: 100%;
    height: 36px;
    padding: 0 16px;

    p {
      font-size: 14px;
      font-weight: bold;
    }
  `);

export const InfoContainer = styled('div')(({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    padding: 16px 0;

    .qr-info {
      color: ${theme.colors.n500};
      font-size: 14px;
      line-height: 20px;
      width: 80%;
      margin-bottom: 0;
    }
  `);

export const InfoIcon = styled(FiAlertCircle)(({ theme }) => css`
    color: ${theme.colors.n500};
    width: 16px;
    height: 16px;
  `);

export const QRCodeContainer = styled('div')(({ theme }) => css`
    margin-top: ${theme.spacings.giga};
    margin-bottom: ${theme.spacings.giga};
  `);

export const QRCodeCard = styled(Card)(({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    margin-top: 20px;
    padding: 0;

    .qr-amount-block {
      text-align: center;
    }

    .qr-amount {
      margin-bottom: 0;
      margin-top: ${theme.spacings.mega};
    }

    .qr-price {
      font-size: 32px;
      margin-bottom: 0;
    }
  `);

export const DescriptionInput = styled(Input)(({ theme }) => css`
  box-shadow: none;
  padding: 0;
  font-size: 24px;
  width: 100%;
  border: none;
  
  &:hover, &:focus {
    outline: 0;
    box-shadow: none;
  }
`);

export const FieldWrapper = styled('div')(({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 0 ${theme.spacings.mega};
    background-color: white;

    p.title {
      font-size: ${theme.spacings.mega};
      margin-bottom: 0;
      strong {
        font-weight: bold;
      }
    }

    p.sub-title {
      font-size: 14px;
      margin-bottom: ${theme.spacings.mega};
    }

    label {
      width: 100%;
    }
  `);

export const Container = styled('div')(({ theme }) => css`
    margin-top: ${theme.spacings.mega};
    height: 81%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.white};
  `);

export const QRCodePaymentContainer = styled('div')(({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.colors.white};
    overflow-y: auto;

    .button-container {
      margin: ${theme.spacings.mega} 0;
      height: 110px;
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  `);

export const ItemTitle = styled(Text)(({ theme }) => css`
    color: ${theme.colors.n700};
    font-size: 14px;
    line-height: 20px;
    margin: 0px;
  `);

export const ItemValue = styled(Text)(({ theme }) => css`
    color: ${theme.colors.n900};
    margin: 0px;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  `);

export const DescriptionItem = styled('div')(() => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    margin-top: 10px;
  `);

export const StyledButton = styled(Button)(({ theme }) => css`
    margin-bottom: ${theme.spacings.mega};
  `);

export const StyledCrossIcon = styled(FiX)(({ theme }) => css`
    color: white;
    width: 26px;
    height: 26px;
  `);

export const StyledCheckIcon = styled(CheckIcon)(() => css``);
export const StyledErrorIcon = styled(ErrorIcon)(() => css`
    margin-left: 28px;
  `);

export const StyledCopyPasteIcon = styled(CopyPasteIcon)(() => css`
    width: 24px;
    height: 24px;
  `);

export const StyledSpinner = styled('div')(({ theme }) => css`
    -webkit-animation: spin 1s ease-in-out infinite;
    animation: spin 500ms ease-in-out infinite;
    border-radius: 50%;
    border: ${theme.spacings.bit} solid ${theme.colors.p300};
    border-top-color: ${theme.colors.p500};
    display: inline-block;
    height: 16px;
    width: 16px;
    margin: 0 0 ${theme.spacings.giga};

    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
  `);

export const FullWidthWrapper = styled('div')(({ theme, backgroundColor, fontColor }) => css`
    width: 100%;
    height: 100vh;
    position: absolute;
    background-color: ${backgroundColor || '#39B54D'};
    top: 0;
    left: 0;
    padding: ${theme.spacings.giga} ${theme.spacings.mega};
    z-index: 2;

    .close {
      width: 30px;
      height: 30px;
    }

    p,
    strong {
      color: ${fontColor || 'white'};
    }
  `);

export const CenteredContainer = styled('div')(({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .amount {
      margin-bottom: 0;
    }

    .price {
      font-size: 19px;
      margin-bottom: ${theme.spacings.exa};
    }

    .icon {
      margin-bottom: ${theme.spacings.giga};
    }

    .heading {
      margin-bottom: ${theme.spacings.giga};
    }

    .description {
      margin-bottom: ${theme.spacings.giga};
    }
  `);

export const TitleStyled = styled(Text)(({ theme }) => css`
    margin-top: ${theme.spacings.mega};
    margin-bottom: ${theme.spacings.giga};
    padding-left: ${theme.spacings.mega};
    padding-right: ${theme.spacings.mega};
    strong {
      font-weight: bold;
    }
  `);

import React from 'react';
import { useParams } from 'react-router-dom';
import Accordion from 'components/Accordion';
import { content } from './content';
import { Container } from './Faq.styles';

const KeyDetail = () => {

  const { active } = useParams();

  return (
    <>
      <Container data-testid="faq">
        {content.map((item) => (
          <Accordion active={active === item.id.toString()} key={item.id} content={item} />
        ))}
      </Container>
    </>
  );
};

export default KeyDetail;

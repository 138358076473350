const activeStatuses = ['CREATED'];

const defaultList = {
  active: {
    title: 'Chaves cadastradas',
    list: [],
  },
  pending: {
    title: 'Chaves pendentes',
    list: [],
  },
};

export const splitAddressKeys = (addressKeys) =>
  addressKeys.reduce((acc, curr) => {
    const {
      currentStatus,
      claimType,
      participationType,
      claimCurrentStatus,
    } = curr;

    curr.status = [
      currentStatus,
      claimType,
      participationType,
      claimCurrentStatus,
    ];

    if (activeStatuses.includes(curr.currentStatus)) {
      return {
        ...acc,
        active: { ...acc.active, list: [...acc.active.list, curr] },
      };
    }

    return {
      ...acc,
      pending: { ...acc.pending, list: [...acc.pending.list, curr] },
    };
  }, defaultList);

import { ClaimConstants, STATUS } from 'constants';
import { handleActions } from 'redux-actions';
import produce from 'immer';

export const claimState = {
  claimAcceptance: {
    message: '',
    status: STATUS.IDLE,
    payload: '',
  },
  claimCompletion: {
    message: '',
    status: STATUS.IDLE,
    payload: '',
  },
  claimRejection: {
    message: '',
    status: STATUS.IDLE,
    payload: '',
  },
  claimCreation: {
    message: '',
    status: STATUS.IDLE,
    payload: '',
  },
  claimList: {
    message: '',
    status: STATUS.IDLE,
    payload: [],
  },
};

export default {
  claim: handleActions({
    [ClaimConstants.CLEAR_CLAIM_RESPONSE]: (state) =>
      produce(state, (draft) => {
        draft.claimAcceptance = {
          message: '',
          status: STATUS.IDLE,
        }
        draft.claimCompletion = {
          message: '',
          status: STATUS.IDLE,
        }
        draft.claimRejection = {
          message: '',
          status: STATUS.IDLE,
        }
        draft.claimCreation = {
          message: '',
          status: STATUS.IDLE,
        }
      }),
    [ClaimConstants.CLEAR_CLAIM_VALUES]: (state) =>
      produce(state, (draft) => {
        draft.claimKey = null;
    }),
    [ClaimConstants.SET_CLAIM_KEY]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.claimKey = payload;
      }),
    [ClaimConstants.CREATE_CLAIM_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.claimCreation = {
          ...draft.claimCreation,
          status: STATUS.RUNNING,
          message: '',
        };
      }),
    [ClaimConstants.CREATE_CLAIM_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.claimCreation = {
          ...draft.claimCreation,
          status: STATUS.SUCCESS,
          payload,
        };
      }),
    [ClaimConstants.CREATE_CLAIM_FAILURE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.claimCreation = {
          ...draft.claimCreation,
          status: STATUS.ERROR,
          message: payload,
        };
      }),
    [ClaimConstants.ACCEPT_CLAIM_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.claimAcceptance = {
          ...draft.claimAcceptance,
          status: STATUS.RUNNING,
          message: '',
        };
      }),
    [ClaimConstants.ACCEPT_CLAIM_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.claimAcceptance = {
          ...draft.claimAcceptance,
          status: STATUS.SUCCESS,
          payload,
        };
      }),
    [ClaimConstants.ACCEPT_CLAIM_FAILURE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.claimAcceptance = {
          ...draft.claimAcceptance,
          status: STATUS.ERROR,
          message: payload,
        };
      }),
    [ClaimConstants.COMPLETE_CLAIM_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.claimCompletion = {
          ...draft.claimCompletion,
          status: STATUS.RUNNING,
          message: '',
        };
      }),
    [ClaimConstants.COMPLETE_CLAIM_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.claimCompletion = {
          ...draft.claimCompletion,
          status: STATUS.SUCCESS,
          payload,
        };
      }),
    [ClaimConstants.COMPLETE_CLAIM_FAILURE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.claimCompletion = {
          ...draft.claimCompletion,
          status: STATUS.ERROR,
          message: payload,
        };
      }),
    [ClaimConstants.REJECT_CLAIM_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.claimRejection = {
          ...draft.claimRejection,
          status: STATUS.RUNNING,
          message: '',
        };
      }),
    [ClaimConstants.REJECT_CLAIM_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.claimRejection = {
          ...draft.claimRejection,
          status: STATUS.SUCCESS,
          payload,
        };
      }),
    [ClaimConstants.REJECT_CLAIM_FAILURE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.claimRejection = {
          ...draft.claimRejection,
          status: STATUS.ERROR,
          message: payload,
        };
      }),
    [ClaimConstants.LIST_CLAIM_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const data = payload.map((item) => ({
          claimCurrentStatus: item.currentStatus,
          ...item,
          ...item.addressKey,
        }));

        draft.claimList.payload = [...data];

        draft.claimList = {
          ...draft.claimList,
          status: STATUS.SUCCESS,
          payload: data,
        };
      }),
    [ClaimConstants.LIST_CLAIM_FAILURE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.claimList = {
          ...draft.claimList,
          status: STATUS.ERROR,
          message: payload,
        };
      }),
    [ClaimConstants.LIST_CLAIM_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.claimList = {
          ...draft.claimList,
          status: STATUS.RUNNING,
          message: '',
        };
      }),
  },
  claimState),
};

import { keyMirror } from 'modules/helpers';

export const AppConstants = keyMirror({
  APP_SET_STEP: undefined,
  HIDE_ALERT: undefined,
  SET_AUTH_TOKEN: undefined,
  SET_SCREEN_SIZE: undefined,
  SHOW_ALERT: undefined,
  TOGGLE_SUCCESS_MODAL: undefined,
  IS_PROCESSING: undefined,
  SET_NAVIGATION: undefined,
  IS_PIX_ENABLED: undefined,
  TOGGLE_CONFIRM_MODAL: undefined,
  SET_MODAL_ERROR: undefined,
});

import { STATUSES_CONTENT as content } from 'content/statusesContent';

const conclusionLimitDateRuleForOwnership = ({ conclusionLimitDate }) => {
  const conclusionDate = new Date(conclusionLimitDate).getTime();
  const now = Date.now();
  return conclusionDate < now
    ? content.ownershipConfirmed
    : content.ownershipClaimed;
};

export const contentHandler = (key) => {
  const contentMap = {
    CREATED: content.created,
    INITIATED: content.pendingValidation,
    PENDING_CLAIM: {
      CLAIM_OWNERSHIP: {
        CLAIMANT: {
          CONFIRMED: conclusionLimitDateRuleForOwnership(key),
          OPENED: content.ownershipClaimed,
          WAITING: content.ownershipClaimed,
        },
        DONOR: {
          OPENED: content.ownershipReceived,
          WAITING: content.ownershipReceived,
          CONFIRMED: content.ownershipReceived,
        },
      },
      PORTABILITY: {
        CLAIMANT: {
          OPENED: content.portabilityClaimed,
          WAITING: content.portabilityClaimed,
          CONFIRMED: content.portabilityClaimed,
        },
        DONOR: {
          OPENED: content.portabilityReceived,
          WAITING: content.portabilityReceived,
          CONFIRMED: content.portabilityReceived,
        },
      },
    },
    PENDING_VALIDATION: content.pendingValidation,
    PENDING_CLAIM_VALIDATION: {
      CLAIM_OWNERSHIP: {
        DONOR: {
          CONFIRMED: content.blockedByClaim,
        },
        CLAIMANT: {
          CONFIRMED: conclusionLimitDateRuleForOwnership(key),
        },
      },
    },
    BLOCKED_BY_CLAIM: {
      CLAIM_OWNERSHIP: {
        DONOR: {
          CONFIRMED: content.blockedByClaim,
        },
      },
    },
  };

  const statuses = [...key.status];
  let finalContent = contentMap;

  const getContent = () => {
    const status = statuses[0];
    if (status) {
      finalContent = finalContent[status];

      statuses.shift();
      getContent();
    }

    return finalContent;
  };

  return getContent();
};

import { keyMirror } from 'modules/helpers';

export const AccountConstants = keyMirror({
  ACCOUNT_CREATE_REQUEST: undefined,
  ACCOUNT_CREATE_SUCCESS: undefined,
  ACCOUNT_CREATE_FAILURE: undefined,
  ACCOUNT_VIEWER_REQUEST: undefined,
  ACCOUNT_VIEWER_SUCCESS: undefined,
  ACCOUNT_VIEWER_FAILURE: undefined,
  ACCOUNT_ADDRESS_SUGGESTION_REQUEST: undefined,
  ACCOUNT_ADDRESS_SUGGESTION_SUCCESS: undefined,
  ACCOUNT_ADDRESS_SUGGESTION_FAILURE: undefined,
});
import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const ModalStyled = {
  ModalContent: styled('div')(({ theme }) => css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding: ${theme.spacings.giga} ${theme.spacings.mega};
  `),
  ModalOverlay: styled('div')(({ theme }) => css`
    backface-visibility: hidden;
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: calc(${theme.zIndex.modal -1});
  `),
  ModalWrapper: styled('div')(({ theme }) => css`
      background-color: white;
      box-shadow: 0 11px 15px -7px rgba(0,0,0,0.2),
                  0px 24px 38px 3px rgba(0,0,0,0.14), 
                  0px 9px 46px 8px rgba(0,0,0,0.12);
      backface-visibility: hidden;
      border-radius: ${theme.borderRadius.giga};
      display: flex;
      flex-direction: column;
      height: auto;
      left: 50%;
      overflow-x: hidden;
      overflow-y: auto;
      position: fixed;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% - (${theme.spacings.mega} * 2));
      z-index: ${theme.zIndex.modal};
    `),
};

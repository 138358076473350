import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ChevronRight } from '@sumup/icons';

export const Selector = styled('ul')(({ theme }) => css`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-top: 1px solid ${theme.colors.n200};
  list-style: none;
`);

export const Item = styled('li')(({ theme }) => css`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacings.kilo} ${theme.spacings.mega} ${theme.spacings.kilo} ${theme.spacings.mega};
  border-bottom: 1px solid ${theme.colors.n200};
  
  &:last-child:active,
  &:last-child {
    border: none;
  }

  &:active {
    border-bottom: 1px solid ${theme.colors.n300};
    background-color: ${theme.colors.n100};
  }
`);

export const StyledChevron = styled(ChevronRight)(({ theme }) => css`
  color: ${theme.colors.n500};
`);

import React from 'react';

export const content = [
  {
    id: 1,
    title: 'Como funciona o Pix?',
    text: (
      <div>
        <p>
          Criado pelo Banco Central, o Pix chega para tornar o processo de
          transferir dinheiro muito mais rápido. Diferente de outras formas de
          transferência como TED, DOC ou até débito, com o Pix não importa se é
          final de semana, feriado ou tarde da noite:
          <strong> o dinheiro cai na conta em poucos segundos</strong>.
        </p>
        <p>
          Após o dia 16/11, você poderá fazer um Pix ou receber pagamentos via
          Pix usando o QR Code gerado na sua maquininha ou aplicativo. E o
          melhor: sem pagar qualquer tipo de taxa.
        </p>
      </div>
    ),
  },
  {
    id: 2,
    title: 'Posso vender usando o Pix?',
    text: (
      <p>
        Se você tem um negócio,
        <strong> poderá vender disponibilizando um QR Code</strong> para os seus
        clientes na maquininha ou aplicativo da SumUp. Também poderá vender via
        transferência, informando a sua conta ou Chave Pix aos seus clientes.
      </p>
    ),
  },
  {
    id: 3,
    title: 'Posso comprar usando o Pix?',
    text: (
      <p>
        Sim, você poderá fazer compras pagando via QR Code em
        <strong> estabelecimentos que aceitem esse tipo de transação</strong>.
        Você também pode realizar o pagamento via transferência usando inclusive
        a Chave Pix do vendedor, que pode ser o seu telefone ou e-mail. Simples,
        não é mesmo?
      </p>
    ),
  },
  {
    id: 4,
    title: 'O que é uma Chave Pix?',
    text: (
      <p>
        É uma forma simples de vincular a sua conta bancária a um dado pessoal
        que você pode compartilhar com as outras pessoas. Estes dados funcionam
        como apelidos para a conta bancária. Assim,
        <strong>
          {' '}
          sua Chave Pix pode ser o seu e-mail, telefone, CPF (para pessoa
          física) ou CNPJ (para pessoa jurídica)
        </strong>
        .
      </p>
    ),
  },
  {
    id: 5,
    title: 'Posso cadastrar a mesma Chave Pix em vários bancos?',
    text: (
      <div>
        <p>
          Não, você pode associar uma Chave Pix específica a uma única conta
          bancária, como o seu e-mail por exemplo. Se você quiser cadastrar o
          seu CPF no mesmo banco, tudo bem! Mas olha que legal:
          <strong>
            {' '}
            você pode escolher ter o seu CPF cadastrado em um banco e o e-mail
            em outro banco
          </strong>
          .
        </p>
        <p>
          Após o dia 5 de outubro, todos os bancos vão oferecer o serviço de
          gestão das suas Chaves Pix. Neste momento, você poderá editar as
          informações, como excluir ou trocar uma das chaves cadastradas em um
          banco.
        </p>
      </div>
    ),
  },
  {
    id: 6,
    title: 'O que falta para usar o Pix?',
    text: (
      <div>
        <p>
          <strong>Com o seu cadastro realizado, pode ficar tranquilo!</strong> A
          partir do dia 5 de outubro, faremos a confirmação de suas informações
          e enviaremos os dados para o cadastro oficial no Banco Central.
        </p>
        <p>
          No dia <strong>16 de novembro</strong>, o Pix estará disponível para
          realizar ou receber uma transação via Chave Pix ou QR Code. Fique de
          olho!
        </p>
      </div>
    ),
  },
];

import React from 'react';
import { ReactComponent as BankIcon } from 'assets/icons/pix-on-sumup-bank.svg';
import { ReactComponent as AlertIcon } from 'assets/icons/feedback-alert.svg';
import { ReactComponent as FailureIcon } from 'assets/icons/feedback-failure.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/feedback-complete.svg';
import { ReactComponent as TimeWindowIcon } from 'assets/icons/icon-time-window.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/feedback-delete.svg';

import {
  ADDRESS_KEY_TYPE_EMAIL,
  ADDRESS_KEY_TYPE_PHONE,
  ADDRESS_KEY_TYPE_CNPJ,
  ADDRESS_KEY_TYPE_CPF,
  ADDRESS_KEY_TYPE_EVP,
} from 'constants/';

export const addressKeyTypes = {
  [ADDRESS_KEY_TYPE_EMAIL.canonical]: ADDRESS_KEY_TYPE_EMAIL,
  [ADDRESS_KEY_TYPE_PHONE.canonical]: ADDRESS_KEY_TYPE_PHONE,
  [ADDRESS_KEY_TYPE_CNPJ.canonical]: ADDRESS_KEY_TYPE_CNPJ,
  [ADDRESS_KEY_TYPE_CPF.canonical]: ADDRESS_KEY_TYPE_CPF,
  [ADDRESS_KEY_TYPE_EVP.canonical]: ADDRESS_KEY_TYPE_EVP,
};

export const mountAccountHolderContent = (isEligible) => {
  const content = {
    icon: <BankIcon />,
    title: 'Pix é no SumUp Bank!',
  };

  const text = (
    <>
      Para se beneficiar dos pagamentos instantâneos com o Pix é necessário
      possuir uma conta no SumUp Bank.
    </>
  );

  const eligibleText = (
    <>
      <p>
        Para se beneficiar dos pagamentos instantâneos com o Pix é necessário
        possuir uma conta no SumUp Bank.
      </p>
      <p>
        <strong>Sua conta está pré-aprovada</strong>, comece agora mesmo!
      </p>
    </>
  );

  return isEligible ? { ...content, text: eligibleText } : { ...content, text };
};

export const mountFormContent = (keyType) => {
  const phoneContent = {
    primary: (
      <>
        Digite o número do <strong>telefone celular</strong> que deseja vincular
        à chave Pix
      </>
    ),
    secondary: <>Chave escolhida: Telefone celular</>,
  };

  const emailContent = {
    primary: (
      <>
        Digite o endereço de <strong>e-mail</strong> que deseja vincular à chave
        Pix
      </>
    ),
    secondary: <>Chave escolhida: E-mail</>,
  };

  const cpfContent = {
    primary: (
      <>
        Você precisa completar o número do seu <strong>CPF</strong> para para
        confirmar o cadastro da sua chave Pix.
      </>
    ),
    secondary: <>Chave escolhida: CPF</>,
  };

  const cnpjContent = {
    primary: (
      <>
        Você precisa completar o número do seu <strong>CNPJ</strong> para para
        confirmar o cadastro da sua chave Pix.
      </>
    ),
    secondary: <>Chave escolhida: CNPJ</>,
  };

  const evpContent = {
    primary: (
      <>
        A <strong>chave aleatória</strong> é uma chave gerada automaticamente
        para você. Basta clicar em Confirmar para cadastrá-la.
      </>
    ),
    secondary: <>Chave escolhida: Chave aleatória</>,
  };

  switch (keyType?.canonical) {
    case ADDRESS_KEY_TYPE_EVP.canonical:
      return evpContent;
    case ADDRESS_KEY_TYPE_PHONE.canonical:
      return phoneContent;
    case ADDRESS_KEY_TYPE_EMAIL.canonical:
      return emailContent;
    case ADDRESS_KEY_TYPE_CPF.canonical:
      return cpfContent;
    case ADDRESS_KEY_TYPE_CNPJ.canonical:
      return cnpjContent;
    default:
      return null;
  }
};

export const formatValues = (obj, type, prop) => {
  const value = obj[prop] || obj;

  return formatValue(value, type);
};

export const formatValue = (value, type) => {
  const cpfRegex = /(\d{3})(\d{3})(\d{3})(\d{2})/;
  const cnpjRegex = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g;
  const phoneRegex = /(\d{2})(\d{5})(\d{4})/;

  switch (type) {
    case ADDRESS_KEY_TYPE_CPF.canonical:
      return value
        .replace(cpfRegex, '$1.$2.$3-$4')
        .split('')
        .map((digit, index) => (index < 3 || index > 11 ? ' • ' : digit))
        .join('');

    case ADDRESS_KEY_TYPE_PHONE.canonical:
      return value.replace('+55', '').replace(phoneRegex, '($1) $2-$3');

    case ADDRESS_KEY_TYPE_CNPJ.canonical:
      return value.replace(cnpjRegex, '$1.$2.$3/$4-$5');

    default:
      return value;
  }
};

export const createKeyResponseHandling = (error) => {
  const createAddressKeyResponses = {
    address_key_owned_by_another_person: (key, keyType) => ({
      icon: <AlertIcon />,
      back: {
        to: '/keys-management',
        clearState: true,
      },
      title: 'Não foi possível vincular a chave à sua conta',
      text: (
        <>
          O {keyType.name} {key.maskedValue || key.value} já está vinculado à
          conta de outra pessoa. Para vincular à sua conta você precisa iniciar
          o processo de reivindicação de posse
        </>
      ),
      actions: [
        {
          label: 'Reivindicar posse',
          type: 'primary',
          to: '/ownership/claim',
        },
        {
          label: 'Cancelar',
          type: 'secondary',
          to: '/keys-management',
          clearState: true,
        },
      ],
    }),
    address_key_in_another_participant: (
      key,
      keyType,
      addressKeyParticipantName
    ) => ({
      icon: <AlertIcon />,
      back: {
        to: '/keys-management',
        clearState: true,
      },
      title: 'Não foi possível vincular a chave à sua conta',
      text: (
        <>
          Você já cadastrou o {keyType.name} {key.maskedValue || key.value} como
          chave Pix no <strong>{addressKeyParticipantName}</strong>. Para mudar
          sua chave para o SumUp Bank você precisa iniciar o processo de
          portabilidade.
        </>
      ),
      actions: [
        {
          label: 'Solicitar portabilidade',
          type: 'primary',
          to: '/portability/claim',
        },
        {
          label: 'Cancelar',
          type: 'secondary',
          to: '/keys-management',
          clearState: true,
        },
      ],
    }),
    evp_success: (value) => ({
      icon: <SuccessIcon />,
      back: {
        to: '/keys-management',
        clearState: true,
      },
      title: 'Sua chave aleatória foi cadastrada com sucesso!',
      text: (
        <>
          A chave aleatória {value} foi cadastrada como uma chave de
          endereçamento no SumUp Bank.
        </>
      ),
      actions: [
        {
          label: 'Finalizar',
          type: 'primary',
          to: '/keys-management',
          clearState: true,
        },
        {
          label: 'Cadastrar nova chave Pix',
          type: 'secondary',
          to: '/new-key/type',
          clearState: true,
        },
      ],
    }),
    evp_error: () => ({
      icon: <FailureIcon />,
      back: {
        to: '/keys-management',
        clearState: true,
      },
      title: 'Erro ao cadastrar chave',
      text:
        'Aconteceu um erro ao tentar cadastrar a chave aleatória. Tente novamente ou fale com o suporte.',
      actions: [
        {
          label: 'Tentar novamente',
          type: 'primary',
          to: '/new-key/type',
          clearState: true,
        },
        {
          label: 'Cancelar',
          type: 'secondary',
          to: '/keys-management',
          clearState: true,
        },
      ],
    }),
    successful: (key, keyType, nextStep = '/keys-management') => ({
      icon: <SuccessIcon />,
      back: {
        to: '/keys-management',
        clearState: true,
      },
      title: `${keyType.name} cadastrado com sucesso!`,
      text: (
        <>
          O {keyType.name} {key.maskedValue || key.value || key} foi cadastrado
          como uma chave de endereçamento no SumUp Bank.
        </>
      ),
      actions: [
        {
          label: 'Finalizar',
          type: 'primary',
          to: nextStep,
          clearState: true,
        },
        {
          label: 'Cadastrar nova chave Pix',
          type: 'secondary',
          to: '/new-key/type',
          clearState: true,
        },
      ],
    }),
    claim_portability_success: (key, keyType) => ({
      icon: <SuccessIcon />,
      title: 'Portabilidade solicitada com sucesso!',
      text: (
        <>
          A portabilidade do {keyType.name} {key.maskedValue || key.value} foi
          solicitada com sucesso. O prazo para conclusão do processo é de até 7
          dias.
        </>
      ),
      actions: [
        {
          label: 'Finalizar',
          type: 'primary',
          to: '/keys-management',
          clearStore: true,
        },
      ],
    }),
    claim_ownership_success: (key, keyType) => ({
      icon: <SuccessIcon />,
      title: 'Chave Pix reivindicada com sucesso!',
      text: (
        <>
          A reivindicação de posse do {keyType.name}
          {key.maskedValue || key.value} foi solicitada com sucesso. O prazo
          para conclusão do processo é de até 14 dias.
        </>
      ),
      actions: [
        {
          label: 'Finalizar',
          type: 'primary',
          to: '/keys-management',
          clearStore: true,
        },
      ],
    }),
    claim_portability_error: (key, keyType) => ({
      icon: <FailureIcon />,
      title: 'Erro ao solicitar portabilidade',
      text: (
        <>
          Aconteceu um erro ao tentar solicitar a portabilidade do $
          {keyType.name} {key.maskedValue || key.value}. Tente novamente ou fale
          com o suporte.
        </>
      ),
      actions: [
        {
          label: 'Tentar novamente',
          type: 'primary',
          to: '/portability/claim',
        },
        {
          label: 'Cancelar',
          type: 'secondary',
          to: '/keys-management',
          clearStore: true,
        },
      ],
    }),
    claim_ownership_error: (key, keyType) => ({
      icon: <FailureIcon />,
      title: 'Erro ao solicitar reivindicação de posse',
      text: (
        <>
          Aconteceu um erro ao tentar solicitar a reivindicação de posse do $
          {keyType.name} {key.maskedValue || key.value}. Tente novamente ou fale
          com o suporte.
        </>
      ),
      actions: [
        {
          label: 'Tentar novamente',
          type: 'primary',
          to: '/ownership/claim',
        },
        {
          label: 'Cancelar',
          type: 'secondary',
          to: '/keys-management',
          clearStore: true,
        },
      ],
    }),
    default: (key, keyType) => ({
      icon: <FailureIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Erro ao cadastrar chave',
      text: (
        <>
          Aconteceu um erro ao tentar cadastrar a chave \
          {keyType.name} {key.maskedValue || key.value || key}.
          Tente novamente ou fale com o suporte.
        </>
      ),
      actions: [
        {
          label: 'Tentar novamente',
          type: 'primary',
          to: '/new-key/type',
          clearState: true,
        },
        {
          label: 'Cancelar',
          type: 'secondary',
          to: '/keys-management',
          clearState: true,
        },
      ],
    }),
    timeWindow: () => ({
      icon: <TimeWindowIcon />,
      back: {
        to: '/keys-management',
      },
      title: 'Cadastro de chave indisponível',
      text:
        'De acordo com o horário de funcionamento definido pelo Banco Central, o cadastro de chaves está disponível apenas das 09h às 18h.',
      actions: [
        {
          label: 'Fechar e aguardar',
          type: 'primary',
          to: '/keys-management',
          clearState: true,
        },
      ],
    }),
    addressKeyDeleteSuccess: (addressKey) => {
      const keyType = addressKeyTypes[addressKey.keyType];
      const key = formatValues(addressKey, keyType.canonical, 'key');
      return {
        icon: <DeleteIcon />,
        back: {
          to: '/keys-management',
        },
        title: 'Chave excluída com sucesso',
        text: (
          <>
            {keyType.name} {key.maskedValue || key.value || key} não está mais
            cadastrado como uma de suas chaves na SumUp.
          </>
        ),
        actions: [
          {
            label: 'Finalizar',
            type: 'primary',
            to: '/keys-management',
            clearState: true,
          },
        ],
      };
    },
    addressKeyDeleteFailed: (addressKey) => {
      const keyType = addressKeyTypes[addressKey.keyType];
      const key = formatValues(addressKey, keyType.canonical, 'key');

      return {
        icon: <FailureIcon />,
        back: {
          to: '/keys-management',
        },
        title: 'Não foi possível excluir a chave',
        text: (
          <>
            Aconteceu um erro ao tentar excluir o seu {keyType.name}
            {key.maskedValue || key.value || key}. Tente novamente ou fale com o
            suporte.
          </>
        ),
        actions: [
          {
            label: 'Tentar novamente',
            type: 'primary',
            to: '/keys-management',
            clearState: true,
          },
          {
            label: 'Cancelar',
            type: 'secondary',
            to: '/keys-management',
            clearState: true,
          },
        ],
      };
    },
  };

  const code = error in createAddressKeyResponses ? error : 'default';
  return createAddressKeyResponses[code];
};

export const getAccountInfoContent = (addressKey, prop) => {
  const keyType = addressKeyTypes[addressKey.keyType];
  const value = addressKey[prop];

  const infos = {
    key: {
      label: 'Chave',
      value: () => formatValues(addressKey, keyType.canonical, prop),
    },
    keyType: {
      label: 'Tipo de chave',
      value: () => keyType.name,
    },
    creationDate: {
      label: 'Data de criação',
      value: () => {
        const date = new Date(value);
        const formattedDate = date.toLocaleDateString('pt-BR');
        const hour = `0${date.getHours()}`.slice(-2);
        const minutes = `0${date.getMinutes()}`.slice(-2);
        return `${formattedDate} às ${hour}:${minutes}`;
      },
    },
    accountParticipant: {
      label: 'Banco do solicitante',
      value: () => value,
    },
  };

  return {
    label: infos[prop]?.label,
    value: infos[prop]?.value(addressKey),
  };
};

import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { FiArrowLeft, FiHelpCircle, FiX } from 'react-icons/fi';

export const TopbarStyled = {
  XIcon: styled(FiX)(({ theme }) => css`
    color: ${theme.colors.p500};
    font-size: 24px;
    margin-right: 12px;
  `),
  BackIcon: styled(FiArrowLeft)(({ theme }) => css`
    color: ${theme.colors.p500};
    font-size: 24px;
    margin-right: 12px;
  `),
  HelpIcon: styled(FiHelpCircle)(({ theme }) => css`
    color: ${theme.colors.p500};
    font-size: 22px;
  `),
  Container: styled('div')(({ theme }) => css`
    align-items: center;
    display: flex;
    margin: 0 auto;
    padding: 0 ${theme.spacings.kilo};
    width: 100%;
  `),
  PreviousHeader: styled('span')(({ theme }) => css`
    color: ${theme.colors.bodyColor};
    font-weight: 700;
    width: 95%;
  `),
  Navbar: styled('header')(({ theme }) => css`
    backface-visibility: hidden;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01),
                0 2px 4px 0 rgba(0, 0, 0, 0.08);
    display: flex;
    height: 50px;
    justify-content: space-around;
    overflow: hidden;
    padding: ${theme.spacings.mega} 0;
    width: 100%;
  `),
};
